.my-account {
  background: var(--szary-100, #f2f2f2);
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 45px 20px;
  justify-content: center;
  height: 100%;
  position: relative;
  transition: 0.3s;
  @media (max-width: 1000px) {
    padding: 24px 0px 0;
  }
  &--scroll-to-container {
    @media (max-width: 1000px) {
      transform: translateX(-100%);
    }
  }
  &__aside-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 32px;
    @media (max-width: 1000px) {
      padding-bottom: 64px;
    }
  }

  &__aside-item {
    min-height: 46px;
    width: 100%;
    padding: 11px 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    position: relative;
    transition: 0.3s;

    ::first-letter {
      text-transform: uppercase;
    }
    @media (max-width: 1000px) {
      padding: 0;
    }
    svg {
      min-width: 20px;
    }
    .svg--credit-card {
      path {
        fill: #050505;
      }
    }

    &--logout {
      color: var(--Czerwony-500, #e20d0d);
    }
    &--active {
      cursor: default;
      background: var(--szary-100, #f2f2f2);
    }

    &--payment {
      overflow: hidden;
      transition: 0.3s;
      min-height: 0;
      padding: 0px 20px;
      margin-bottom: -10px;
      max-height: 0;
    }
    &--show {
      max-height: 48px;
      margin-bottom: 0;
      padding: 11px 20px;
      @media (max-width: 1000px) {
        padding-left: 0;
      }
    }
  }

  &__payment-method {
    display: flex;
    gap: 12px;
    height: 56px;
    margin-bottom: 12px;
    color: var(--czarny-500, #111);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    padding: 23px 30px;
    align-items: center;
    justify-content: space-between;
    & > div,
    & > span {
      display: flex;
      align-items: center;
    }

    .svg--credit-card {
      margin-right: 10px;
      path {
        fill: #111111;
      }
    }
    &--chosen {
      background: var(--Niebieski-500, #352fee);
      color: white;
      cursor: default;
      .svg--credit-card {
        margin-right: 10px;
        path {
          fill: white;
        }
      }
      .svg--accept {
        width: 24px;
        height: 24px;
      }
    }

    &--default {
      .my-account__payment-method--delete {
        path {
          stroke: #fff;
        }
      }
    }

    &--default__buttons {
      display: flex;
      gap: 10px;
      align-items: center;
      span {
        height: 24px;
      }
    }
  }

  &__payment-method-wrapper {
    width: 100%;
  }

  &__combined-cards {
    margin-top: 16px;
    max-width: 400px;
  }
  &__payment-method--delete {
    cursor: pointer;
  }

  &__payment-method-websites {
    overflow: hidden;
    max-height: 0;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &--expanded {
      margin-bottom: 32px;
    }

    li {
      display: flex;
      padding: 7px 24px 7px 30px;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;
      color: var(--czarny-400, #262626);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 200% */
      border-radius: 6px;
      background: var(--szary-100, #f2f2f2);
      .svg {
        min-width: 18px;
        min-height: 18px;
      }
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &__sidebar {
    border-radius: 12px;
    padding: 40px 20px;
    max-width: 296px;
    background: #fff;
    width: 296px;
    @media (max-width: 1000px) {
      position: absolute;
      left: 0;
      top: 0;
      max-width: 100%;
      height: 100%;
      width: 100%;
      background-color: #f2f2f2;
    }
  }

  &__aside-header {
    color: var(--czarny-500, #111);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px;
    padding-left: 20px;
    @media (max-width: 1000px) {
      font-size: 18px;
      line-height: 24px;
      font-weight: 800;
      letter-spacing: -0.045px;
    }
  }
  &__aside-arrow {
    @media (min-width: 1000px) {
      display: none;
    }
    position: absolute;
    right: 0;
    transform: rotate(180deg);
    height: 24px;
    path {
      stroke: #111111;
    }
  }

  &__container {
    border-radius: 12px;
    background: #fff;
    width: 100%;
    max-width: 920px;
    position: relative;
    @media (max-width: 1000px) {
      transform: translateX(100%);
      table {
        width: 100%;
      }
      .my-account__table tr {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .tooltip {
    box-shadow: 0px 3px 40px #00000029;
  }

  &__form {
    padding: 48px 104px;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: auto;
    max-height: 100%;
    height: 100%;
    @media (max-width: 1000px) {
      padding: 24px 16px;
      background-color: #f2f2f2;
    }
    input {
      pointer-events: none;
    }
    &--active {
      opacity: 1;
      pointer-events: all;
      z-index: 2;
      input {
        pointer-events: all;
      }
    }

    &--one-row-container {
      @media (min-width: 1000px) {
        max-width: 400px;
      }
    }

    &--one-row {
      @media (min-width: 1000px) {
        max-width: 608px;
      }
    }

    .my-account__table td {
      color: var(--czarny-500, #111);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      padding-top: 8px;
      @media (max-width: 1000px) {
        padding-left: 6px;
      }
    }
    .my-account__table td:nth-child(2) {
      vertical-align: bottom;
    }

    .my-account__table td:first-child {
      @media (max-width: 1000px) {
        padding-top: 24px;
        padding-left: 0;
      }
      width: 170px;
      font-weight: 600;
    }
  }

  &__form-header {
    color: var(--czarny-500, #111);

    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: -0.045px;
    margin-bottom: 50px;
    @media (max-width: 1000px) {
      margin-bottom: 40px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      &--personal-data {
        margin-bottom: 16px;
      }
    }

    &--invoice {
      margin-top: 36px;
      margin-bottom: 24px;
    }
  }
  &__back {
    @media (min-width: 1000px) {
      display: none;
    }
    cursor: pointer;
    path {
      stroke: #111111;
    }
  }

  &__form-phone {
    @media (min-width: 1000px) {
      td {
        padding-top: 40px !important;
      }
    }
    td:last-child {
      display: flex;
      align-items: center;
      gap: 44px;
      @media (max-width: 1000px) {
        align-items: flex-start;
        flex-direction: column;
        .btn--wrapper {
          width: 100%;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }

  &__form-lang {
    margin-top: -22px;
    width: 100%;
    position: relative;
    top: 10px;
    .react-select__control {
      background-color: #f2f2f2 !important;
      height: 48px !important;
      border-radius: 5px !important;
      @media (max-width: 1000px) {
        background-color: #fff !important;
      }
    }
    .react-select__clear-indicator {
      display: none;
    }
    .react-select__indicator-separator {
      display: none;
    }
    .form__field.form__field--select {
      margin-bottom: 40px;
    }
    .form__field .field-label {
      color: var(--czarny-500, #111);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
    }
  }

  &__switcher-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    .switcher .wrapper {
      height: 0px;
    }
  }
  &__switcher--bold {
    font-weight: 700;
  }
  &__communication-subtitle {
    color: var(--czarny-300, #333);
    font-size: 12px;
    line-height: 18px; /* 150% */
    margin-top: -4px;
    margin-bottom: 24px;
  }

  &__sms {
    margin-bottom: 15px;
    &--inactive {
      color: var(--szary-500, #808080);
      .switcher {
        pointer-events: none;
        opacity: 0.45;
      }
    }
  }
  &__email {
    margin-bottom: 50px;
  }
  &__invoice {
    margin-bottom: 40px;
  }

  &__last-password-change {
    color: var(--czarny-300, #333);
    font-size: 12px;
    line-height: 18px; /* 150% */
    margin-top: 16px;
  }
  &__form-change {
    &--personal {
      margin-top: 32px;
      @media screen and (max-width: 1000px) {
        margin-top: 56px;
      }
    }
    div &--card.btn.new-blue-stroke {
      margin-top: 32px;
      width: 100%;
      max-width: 192px;
      @media screen and (max-width: 1000px) {
        margin-top: 56px;
      }
    }
  }
  &__form-change.btn.new-blue-stroke {
    color: var(--niebieski-500, #352fee);
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 120px;
    height: 32px;
    padding: 0;
    min-height: 32px;
    border-radius: 4px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  &__company-button {
    margin-top: 50px;
    width: 140px;
    @media (max-width: 1000px) {
      margin-top: 40px;
    }
  }
  &__custom-email {
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    &--show {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__form-subtitle {
    margin-top: -11px;
    margin-bottom: 40px;
    color: var(--szary-600, #666);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    max-width: 455px;
  }

  &__label {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  &__theme {
    margin-top: 32px;
    .picker {
      width: 100%;
      border-radius: 5px;
      background: var(--szary-100, #f2f2f2);
      padding: 0 8px 0 20px;
      height: 48px;
      justify-content: flex-start;
      cursor: pointer;
      @media (max-width: 1000px) {
        background: #fff;
      }
    }
    .picker__dropDown {
      cursor: pointer;
      margin-left: auto;
      path {
        stroke: #808080;
      }
    }
    .picker__hex {
      margin-left: 10px;
      color: var(--czarny-500, #111);
      font-size: 14px;
      line-height: 24px; /* 171.429% */
    }
  }

  &__password-button {
    .btn--wrapper {
      width: 100%;
      margin-top: 40px;
    }
    .btn {
      width: 100%;
      justify-content: center;
      padding: 0;
      height: 40px;
      align-items: center;
      border-radius: 30px;
    }
  }

  &__password-wrapper {
    position: relative;
  }

  &__dont-remember-password {
    color: var(--niebieski-500, #352fee);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &__report-left {
    width: 400px;
    max-width: 400px;
    @media (max-width: 1000px) {
      width: 100%;
      max-width: 100%;
    }
  }
  &__password-warning {
    color: var(--czarny-300, #333);
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px; /* 150% */
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 16px;
    .svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
    p {
      width: 386px;
    }
  }

  &__modal {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #f2f2f2;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-top: 143px;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    z-index: -1;
    overflow: auto;

    @media (max-width: 1000px) {
      padding-top: 00px;
    }
    input {
      pointer-events: none;
    }
    &--open {
      z-index: 999;
      opacity: 1;
      pointer-events: all;
      div iframe {
        pointer-events: all;
      }
      input {
        pointer-events: all;
      }
    }
    & > .btn--wrapper {
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
    .my-account__table tr:first-child {
      td {
        padding-top: 0;
      }
    }

    td {
      color: var(--czarny-500, #111);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      padding-top: 8px;
    }

    td:first-child {
      width: 170px;
      font-weight: 600;
    }
  }

  &__modal-header {
    color: var(--czarny-500, #111);
    font-size: 24px;
    font-weight: 900;
    line-height: 30px; /* 125% */
    margin-bottom: 30px;
  }

  &__modal-subtitle {
    color: var(--szary-700, #404040);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  &__modal-phone {
    margin-top: 40px;
    @media (max-width: 1000px) {
      flex: 1;
      display: flex;
      flex-direction: column;
      .btn--wrapper {
        margin-top: auto;
      }
    }
  }
  &__phone-wrapper {
    margin-bottom: 56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 14px;
    .form__field:first-child {
      width: 116px;
      .react-select {
        height: 48px;
      }
      .react-select__control {
        border-radius: 5px;
        height: 48px;
        margin: 0;
      }
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__value-container {
        padding-left: 20px;
      }
    }
    .form__field:last-child {
      flex-grow: 1;
    }
  }
  &__modal-btn {
    margin-top: 50px;
    transition: 0.3s;
    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__modal-close {
    @media (max-width: 1000px) {
      top: 30px;
      right: 16px;
      position: absolute;
    }
  }
  &__modal-warpper {
    iframe {
      width: calc(100% + 10px) !important;
      margin: 0px !important;
      pointer-events: none;
    }
    @media (min-width: 1000px) {
      min-width: 400px;
      max-width: 400px;
    }
    @media (max-width: 1000px) {
      height: 100%;
      padding-top: 90px;
      padding-bottom: 31px;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-inline: 16px;
    }
    .my-account__modal-tooltip {
      top: -100px;
    }
    &--password {
      input,
      input[type='password'] {
        color: var(--szary-500, #808080);
        font-weight: 400;
        line-height: 24px; /* 240% */
        letter-spacing: 1px;
      }
    }
  }
  &__new-password-input.form__field {
    width: 100%;
    .eye {
      @media (max-width: 1000px) {
        top: 50px;
      }
    }
  }
  &__password-strenght {
    .add-website-form__tooltip-content {
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.1);
    }
    h4 {
      display: flex;
      gap: 4px;
    }
    .add-website-form__packet-tooltip {
      align-items: flex-start;
    }
    .tooltip__arrow {
      margin-top: 30px;
    }
  }
  &__terms {
    margin-top: -10px;
    @media (max-width: 1000px) {
      margin-top: 24px;
    }
    a {
      color: var(--niebieski-500, #352fee);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      text-decoration: none;
    }
    li {
      margin-bottom: 12px;
      @media (max-width: 1000px) {
        margin-bottom: 20px;
      }
    }
  }
  &__card-remove {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    &--open {
      opacity: 1;
      pointer-events: all;
      z-index: 999;
    }
  }

  &__card-wrapper {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 30px 24px;
    width: 360px;
    h3 {
      color: var(--czarny-500, #111);
      font-size: 18px;
      font-weight: 800;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.045px;
      text-align: center;
    }
    p {
      margin-top: 16px;
      color: var(--czarny-300, #333);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      span {
        font-weight: 700;
      }
    }
  }

  &__card-remove-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    gap: 18px;
    transition: 0.3s;
    .btn--wrapper {
      width: 100%;
    }
    .btn {
      width: 100%;
      height: 32px;
      min-height: 32px;
    }
  }
  &__cards-subtitle {
    margin-top: -8px;
    color: var(--szary-600, #666);
  }

  &__card-change {
    button {
      margin-top: 0;
    }
  }
  &__invoice-table {
    .tablePayment {
      grid-template-columns: 20px 0.7fr 0.7fr 0.5fr 100px 24px;
      gap: 10px;
      @media (max-width: 1000px) {
        grid-template-columns: unset;
        display: flex;
        justify-content: space-between;
      }
      &--firstLine {
        @media (max-width: 1000px) {
          display: none;
        }
      }
      &__site {
        max-width: 138px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0;
        white-space: nowrap;
        display: block !important;
      }
      &__item {
        border-radius: 6px;
        background: var(--Szary-100, #f2f2f2);
        height: 56px;
        padding: 0;
        align-items: center;
        padding: 0 20px;
        color: var(--Czarny-400, #262626);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 200% */
        @media (max-width: 1000px) {
          background-color: #fff;
        }
      }
      div {
        display: flex;
        align-items: center;
      }
      &__card {
        align-items: center;
        display: flex;
        gap: 8px;
        @media (max-width: 1000px) {
          display: none;
        }
      }
      &__id {
        @media (max-width: 1000px) {
          display: none;
        }
      }
      &__facture--nr,
      &__client {
        @media (max-width: 1000px) {
          display: none;
        }
      }
      &__facture--download {
        display: flex;
        align-items: center;
        padding: 0;
        a {
          height: 24px;
        }
      }
    }
  }
}
