.contact-form {
  display: flex;
  padding: 40px 56px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: #fff;
  @media (max-width: 1000px) {
    padding: 40px 24px;
  }
  h2 {
    color: var(--Czarny-400, #262626);
    font-size: 24px;
    font-weight: 900;
    line-height: 30px; /* 125% */
    margin-bottom: 4px;
    @media (max-width: 1000px) {
      font-size: 18px;
      line-height: 26px;
      font-weight: 800;
      letter-spacing: -0.045px;

    }
  }

  &__group {
    display: flex;
    gap: 16px;
    width: 100%;
    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .form__field {
    margin: 0;
    width: 100%;
    input {
      background-color: #f2f2f2;
      &:-webkit-autofill {
        -webkit-box-shadow: unset;
      }
    }
    textarea {
      background-color: #f2f2f2;
      height: 172px;
      border: unset;
      line-height: 24px;
      font-size: 14px;
    }
  }

  .checkbox {
    margin-top: 16px;
    margin-bottom: 40px;
    .checkbox__title-wrapper {
      max-width: unset;
    }
  }
  .btn--wrapper {
    width: 100%;
    button {
      width: 100%;
      height: 48px;
    }
  }
}
