.newsletter {
  max-width: 600px;
  width: 100%;
  margin-top: 60px;
  margin: 0 30px 0 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &__wrapper {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 80px 0;
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 97%);
    margin-bottom: -80px;
    @include sm {
      margin-bottom: 0;
      padding: 250px 0 300px;
      margin-bottom: -220px;
      clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 90%);
    }
    @include md {
      margin-bottom: -260px;
      clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
    }
    &--normal {
      display: block;
      b {
        font-weight: $weight__bold;
        color: #000;
      }
      a {
        color: #000;
        font-weight: $weight__bold;
      }
      i {
        font-style: italic;
      }
    }
  }
  p {
    color: #000;
    font-size: 20px;
    line-height: 28px;
    font-weight: $weight__max;
    text-align: center;
    padding-bottom: 20px;
  }
  &__mail {
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: 40px 0;
    button {
      height: 57px;
      padding-top: 16px;
      max-width: 245px;
      width: 100%;
      display: flex;
      justify-content: center;
      @media (min-width: 700px) {
        position: absolute;
        top: -57px;
        right: -3px;
      }
    }
    .btn--wrapper {
      padding-top: 30px;
      @media (max-width: 700px) {
        margin-bottom: 30px;
        justify-content: center;
        display: flex;
      }
    }
  }
  .form__field {
    input {
      height: 57px;
      border-radius: 25px;
      background-color: #f1f1f1;
      border: 0px;
      padding-left: 22px;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
    }
    &--label-error span {
      position: absolute;
      font-size: 12px;
    }
  }
  .invalid-feedback {
    color: #e20d0d;
    font-size: 12px;
  }
}

.checkbox__rule {
  position: relative;
  padding-left: 40px;
  max-width: 100%;
  @media screen and (min-width: 756px) and (max-height: 660px) {
    margin: 16px 0;
  }
  a {
    margin-left: 5px;
  }
  &--preorder {
    margin: 20px 0;
    .container input:checked ~ .checkmark {
      background-color: black !important;
    }
  }
  &--preorder {
    span {
      color: #242424 !important;
      opacity: 0.93 !important;
      font-weight: $weight__normal;
    }
  }
  span {
    cursor: pointer;
    font-size: 12px;
    color: #4b4b4b;
    opacity: 0.58;
    line-height: 19px;
    display: block;
    @media screen and (min-width: 756px) and (max-height: 660px) {
      font-size: 11px;
    }
  }
  .invalid-feedback {
    color: #e20d0d;
    font-size: 12px;
  }
  .container {
    input {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      height: 0;
      width: 0;
    }
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 10px;
    border: 2px solid #707070;
    border-radius: 8px;
    left: 0;
    height: 25px;
    width: 25px;
  }

  .container:hover input ~ .checkmark {
    background-color: #eee;
  }

  .container input:checked ~ .checkmark {
    background-color: #352fee;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
