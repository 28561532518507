.filter {
  display: flex;
  margin-top: 15px;
}

.filter {
  &__wrapper {
    align-items: center;
    display: flex;
    @media (max-width: 400px) {
      width: 100%;
      justify-content: space-between;
    }
  }
  &__item {
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000017;
    color: rgba(0, 0, 0, 1);
    display: flex;
    font-weight: $weight__bold;
    font-size: 13px;
    height: 33px;
    margin: 8px 8px;
    max-height: 36px;
    padding: 8px 10px 8px 10px;
    @media (max-width: 400px) {
      width: 100%;
    }
    &:first-child {
      margin-left: 0;
    }
    svg {
      cursor: pointer;
      margin-left: 10px;
      width: 10px;
      height: 10px;
    }
  }
}
