.footer {
  background-color: #111111;
  color: #fff;
  display: flex;
  z-index: 9;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @include lg {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__content {
    display: flex;
    flex-direction: column-reverse;
    @include lg {
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      margin-top: 32px;
      flex-wrap: wrap;
      max-width: 1136px;
      &--navi {
        display: flex;
        flex-shrink: 0;
      }
    }
    @media (max-width: 1290px) {
      justify-content: center;
    }
  }
  &__logo {
    // margin: 50px 0;
    text-align: center;
    @media (min-width: 1490px) {
      flex-direction: row;
      margin-right: 40px;
    }
    @media (min-width: 1500px) {
      margin-right: 100px;
    }
    margin-right: 90px;
    @media (max-width: 1000px) {
      margin-right: 0;
      position: absolute;
      top: 128px;
      left: 50%;
      transform: translateX(-50%);
      height: 51px;
      width: 101px;
      z-index: 2;
    }
    svg {
      width: 101px;
      height: auto;
    }
  }

  &__navi {
    display: flex;
    gap: 180px;
    @media (max-width: 1000px) {
      gap: 20px;
      padding: 0 20px;
      margin-top: 56px;
    }
  }
  .navi__link {
    outline: none;
    color: #fff;
    text-decoration: none;
    text-align: center;
    margin: 24px 0;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
    &--active {
      font-weight: $weight__bold;
    }

    @include lg {
      margin: 0 25px;
      font-size: 16px;
    }
    @include xl {
      font-size: 18px;
    }
  }

  &__contact,
  &__contact .btn--wrapper {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1000px) {
      justify-content: center;
    }
  }
  &__contact {
    position: relative;
    flex-direction: column;
    @include lg {
      align-items: center;
      flex-shrink: 0;
    }
    @media (max-width: 1000px) {
      margin-top: 22px;
    }

    svg.svg--corgi-premium {
      position: absolute;
      width: 51px;
      height: 72px;
      top: -57px;
      left: 37%;
      z-index: 9;
      @media (max-width: 1000px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .btn {
      position: relative;
      font-size: 20px;
      // overflow: auto;
      @include lg {
        font-size: 16px;
      }
      @include xl {
        font-size: 20px;
      }
    }
    p {
      margin-top: 43px;
      text-align: center;
      font-size: 18px;
      @include lg {
        margin-top: 0;
        margin-right: 39px;
        font-size: 16px;
      }
      @include xl {
        font-size: 18px;
      }
    }
  }

  &__text {
    font-size: 10px;
    text-align: center;
    margin: 0 35px;
    margin-top: 64px;
    line-height: 14px;
    margin-bottom: 32px;
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      display: flex;
      gap: 8px;
    }

    @include lg {
      // margin-bottom: 66px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      max-width: 1136px;
      padding-left: 201px;
      width: 100%;
    }
    p {
      color: var(--Szary-300, #bfbfbf);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
    b {
      font-weight: 600;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__list-item {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (max-width: 1000px) {
      width: 50%;
    }
  }

  &__socials {
    display: flex;
    gap: 16px;
    margin-top: 48px;
    justify-content: flex-end;
    width: 100%;
    @media (max-width: 1000px) {
      justify-content: center;
      margin-top: 117px;
    }
  }
}
