.AboutUs {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 500px;
    @media (max-width: 1500px) {
      padding-bottom: 350px;
    }
    @media (max-width: 700px) {
      padding-bottom: 150px;
    }
  }
  &-wrapper-elk {
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
    background-color: #474747 !important;
    margin-top: -600px !important;
    @media (max-width: 1500px) {
      clip-path: polygon(0 0, 100% 4%, 100% 100%, 0 100%);
      margin-top: -350px !important;
    }
    @media (max-width: 800px) {
      clip-path: polygon(0 0, 100% 2%, 100% 100%, 0 100%);
      margin-top: -250px !important;
    }
    h1 {
      margin-top: 140px;
      @media (max-width: 1500px) {
        margin-top: 0;
      }
      @media (max-width: 760px) {
        margin-top: 10px;
      }
    }
  }
  max-width: 1600px;
  &__heading-descript {
    font-size: 17px;
    color: #f1f1f1;
    opacity: 0.24;
    width: 823px;
    margin-bottom: 180px;
    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 50px;
    }
    @media (max-width: 1500px) {
      margin-bottom: 100px;
    }
  }
  &__person {
    display: flex;
    @media (max-width: 700px) {
      flex-wrap: wrap-reverse;
    }
    &--wrapper {
      display: flex;
      // margin-top: 80px;
      margin-bottom: 210px;
      @media (max-width: 1500px) {
        margin-bottom: 40px;
      }
      @media (max-width: 1279px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;
      }
    }
    &--name {
      color: #ffffff;
      opacity: 0.93;
      font-size: 35px;
      font-weight: $weight__max;
      max-width: 180px;
      line-height: 40px;
    }
    &--func {
      font-size: 21px;
      color: #ffffff;
      opacity: 0.69;
      font-weight: $weight__max;
      margin-top: 7px;
      margin-bottom: 15px;
    }
    &--descript {
      color: #f1f1f1;
      opacity: 0.53;
      font-size: 14px;
      width: 289px;
      margin: 15px 0;
      z-index: 99;
      position: relative;
      display: block;
      @media (max-width: 1380px) {
        width: 220px;
      }
      @media (max-width: 1000px) {
        width: 289px;
      }
    }
    &--container {
      max-width: 732px;
      width: 100%;
      height: 370px;
      position: relative;
      margin: 0 50px;
      @media (max-width: 1500px) {
        margin: 70px 10px;
      }
      @media (max-width: 1000px) {
        margin: 70px 0;
      }
      @media (max-width: 700px) {
        height: auto;
        margin: 40px 0;
      }
      &:nth-child(1) {
        margin-left: 0;
        @media (max-width: 1500px) {
          // margin-top: 20px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 180px;
        right: 400px;
        top: 0;
        z-index: 1;
        background: transparent linear-gradient(270deg, #2d2d2d00 0%, #2d2d2d 78%, #2d2d2d 100%) 0% 0% no-repeat padding-box;
        @media (max-width: 700px) {
          width: 500px;
          right: 300px;
        }
      }
      &-elk {
        &:before {
          background: transparent linear-gradient(270deg, #47474700 0%, #474747 78%, #474747 100%) 0% 0% no-repeat padding-box !important;
        }
      }
    }
    &--img {
      width: 555px;
      position: absolute;
      right: 0;
      height: 370px;
      border-top-right-radius: 100px;
      @media (max-width: 700px) {
        position: static;
        width: 100%;
        height: auto;
        max-height: 500px;
      }
      @media (max-width: 500px) {
        position: static;
        width: 100%;
        height: 100%;
        // max-height: 300px;
      }

      @media (max-width: 450) {
        max-height: 300px;
      }
    }
    &--team {
      position: absolute;
      color: #ffffff;
      opacity: 0.69;
      font-size: 21px;
      top: -35px;
      font-weight: $weight__max;
      display: flex;
      align-items: center;
      &-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ffffff;
        opacity: 0.69;
        display: block;
        margin-right: 5px;
      }
    }
    &--frame {
      width: 264px;
      height: auto;
      // background-color: #272727;
      position: absolute;
      right: -62px;
      bottom: 0px;
      padding: 12px;
      z-index: 2;
      @media (max-width: 700px) {
        display: none;
      }
    }
    &--desc {
      color: #ffffff;
      opacity: 0.69;
      font-size: 13px;
    }
    &--bold-desc {
      color: #ffffff;
      opacity: 0.69;
      font-weight: $weight__bold;
      font-size: 17px;
    }
  }
  &__left {
    z-index: 1;
    margin-top: -30px;
    @media (max-width: 700px) {
      max-width: 400px;
    }
  }
  &--background {
    background: transparent linear-gradient(270deg, #2d2d2d00 0%, #2d2d2d 78%, #2d2d2d 100%) 0% 0% no-repeat padding-box;
  }

  &__person--container-team {
    &:before {
      background: none !important;
    }
    .AboutUs__person--name {
      max-width: 240px;
    }
    &:nth-child(2) {
      .AboutUs__person--name {
        max-width: 370px;
        @media (max-width: 700px) {
          max-width: 260px;
        }
      }
      .AboutUs__person--name {
        position: absolute;
        top: 100px;
        @media (max-width: 700px) {
          position: relative;
          margin-top: -116px;
        }
      }
      .AboutUs__person--descript {
        position: absolute;
        top: 130px;
        color: #ffffff;
        opacity: 0.7;
        font-size: 20px;
        font-weight: $weight__bold;
        @media (max-width: 700px) {
          position: relative;
        }
      }
      button {
        position: absolute;
        top: 230px;
        left: 30px;
        width: 205px;
        justify-content: center;
        @media (max-width: 700px) {
          position: relative;
          margin-top: -86px;
        }
      }
    }
  }
}
.AboutUs-wrapper-elk {
  padding: 0;
}

.others {
  width: 100%;
  position: relative;
  overflow: hidden;
  &--corgiLine {
    position: absolute;
    bottom: -1200px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__4You {
    align-content: flex-start;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    position: relative;
    @media (min-width: 326px) {
      background-color: #f1f1f1;
    }
    & > div {
      max-width: 1920px;
      width: 100%;
    }
    &--slope {
      position: absolute;
      bottom: 0;
      overflow: hidden;
      clip-path: polygon(100% 55%, 0% 100%, 100% 100%);
      background: #fff;
      width: 100%;
      height: 1400px;
      margin-bottom: -2px;
      @media (max-width: 700px) {
        height: 400px;
        clip-path: polygon(100% 80%, 0% 100%, 100% 100%);
      }
      @media (max-width: 1550px) {
        height: 800px;
      }
    }
    &-heading {
      margin-bottom: 70px;
      position: relative;
      z-index: 9;
      margin-top: 60px;
      padding: 0 30px;
      @media (min-width: 1100px) {
        margin-top: 0;
      }
      h3 {
        font-weight: $weight__max;
        text-align: center;
        font-size: 36px;
        @media (min-width: 340px) {
          font-size: 41px;
        }
        @media (min-width: 1100px) {
          font-size: 51px;
        }
      }
      p {
        color: #858585;
        text-align: center;
        font-size: 20px;
        font-weight: $weight__normal;
        margin-top: 10px;
      }
    }
    &-ofert {
      z-index: 9;
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      height: 700px;
      justify-content: center;
      overflow: hidden;
      @media (min-width: 1000px) {
        height: 500px;
        justify-self: flex-start;
      }
      h4 {
        font-size: 35px;
        font-weight: $weight__max;
        margin-bottom: 20px;
      }
      .btn--wrapper {
        margin: 50px 0;
      }
      p {
        color: #858585;
        font-size: 20px;
      }
      > div {
        position: relative;
        max-width: 485px;
        padding: 0 30px;
        h4 {
          text-align: left;
          font-size: 36px;
          @media (min-width: 340px) {
            font-size: 35px;
          }
        }
        @media (min-width: 1100px) {
          left: 30px;
          left: 10%;
          position: absolute;
        }
        @media (min-width: 1400px) {
          left: 20%;
        }
      }
    }
    &-img {
      position: relative;
      padding: 0 40px;
      width: 100%;
      max-width: 600px;
      top: -30px;
      @media (min-width: 1100px) {
        width: 600px;
        padding: 0;
        left: 48%;
        position: absolute;
        top: 70px;
      }
      @media (min-width: 1400px) {
        width: 700px;
        padding: 0;
        left: 48%;
        position: absolute;
        top: 0px;
        max-width: 700px;
      }
      @media (min-width: 1600px) {
        left: 54%;
      }
    }
    &-background,
    &-background2 {
      background-color: #f1f1f1;
      overflow: hidden;
      position: absolute;
      width: 201%;
      z-index: 1;
    }
    &-background {
      transform: rotate(-8deg);
      left: -200px;
      margin-top: -450px;
      height: 630px;
    }
    &-background2 {
      transform: rotate(-13deg);
      left: -100px;
      height: 140px;
    }
    &--bg {
      height: 3000px;
      left: -100px;
      position: absolute;
      width: 150%;
      margin-top: -600px;
      img {
        object-fit: contain;
        height: 100%;
        width: 150%;
      }
    }
  }
  &--slope {
    position: absolute;
    width: 100%;
    height: 1000px;
    bottom: -1px;
    overflow: hidden;
    clip-path: polygon(100% 78%, 0% 100%, 100% 100%);
    background: #f1f1f1;
    left: 0;
  }

  &__contact {
    overflow: hidden;
    background-color: #fff;
    &--content {
      max-width: 453px;
      padding: 0 30px;
      margin-bottom: 200px;
    }
    &--header {
      font-size: 35px;
      font-weight: $weight__max;
      text-align: center;
      margin-bottom: 20px;
    }
    &--descript {
      color: #858585;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      &-btn {
        position: relative;
      }
    }
    .btn--wrapper {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      position: relative;
      z-index: 9;
    }
    img {
      position: absolute;
      width: 51px;
      height: 72px;
      z-index: 99;
      left: 40%;
      top: -60px;
      @media (min-width: 450px) {
        left: 170px;
      }
    }
    &--button {
      height: 50px;
      background-color: transparent;
      border: 1px solid #000;
      .btn--icon img {
        position: absolute;
        top: -60px;
        width: 50px;
        margin-left: -95px;
      }
    }
    p {
      text-align: center;
      margin-top: 30px;
      font-size: 18px;
    }
  }
}

.page-corgi .AboutUs {
  margin: 125px 60px 0 60px !important;
  @media (max-width: 700px) {
    margin: 125px 0 !important;
    padding-top: 50px !important;
  }
}
.page-corgi .secondContainer {
  @media (max-width: 1500px) {
    padding-top: 0 !important;
  }
}
