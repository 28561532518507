.section__security {
  flex-direction: column;
  position: relative;
  margin-top: 40px;
  padding: 100px 0;
  @include md {
    max-width: 1200px;
    margin: 0 60px;
  }
  @include lg {
    padding: 100px 0;
  }
  &-upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg,
    .img {
      margin-top: 30px;
      margin-bottom: -30px;
    }
    @include lg {
      margin-bottom: 100px;
    }
    h2 {
      text-align: center;
      max-width: 380px;
      color: #ffffff;
      @include md {
        max-width: 480px;
        margin-top: 100px;
      }
      @include lg {
        max-width: 680px;
      }
      @include xl {
        margin-top: 150px;
      }
    }
    p {
      color: #ffffff;
      opacity: 0.7;
      font-size: 14px;
      margin-top: 63px;
      text-align: center;
      max-width: 400px;
      @include lg {
        font-size: 16px;
        max-width: 600px;
      }
      @include xl {
        font-size: 20px;
      }
    }
  }
  .section__c {
    flex-direction: column;
    margin-top: 50px;
    @media (max-width: 400px) {
      margin-top: -20px;
    }
    @include lg {
      flex-direction: row;
      justify-content: space-between;
    }
    svg {
      height: 270px;
      object-fit: contain;
      max-width: 100%;
      margin-top: 40px;
      margin-left: -20px;

      @media (max-width: 320px) {
        margin-top: 100px;
      }
      @media (max-width: 290px) {
        margin-top: 140px;
      }
      @include sm {
        margin-top: 0;
        margin-left: 0;
      }
      @include lg {
        height: 600px;
        width: 500px;
      }
      @include xl {
        height: 700px;
        margin-top: -110px;
        width: 600px;
      }
    }
  }
  .section__l {
    flex-direction: column;
    margin-top: 200px;
    // @include md {
    //   // margin-top: 300px;
    // }
    @include lg {
      margin-top: 0;
    }
    .btn--wrapper {
      display: flex;
      justify-content: center;
      @include lg {
        justify-content: flex-start;
      }
      button {
        margin-bottom: 20px;
        padding: 12px 70px;
      }
    }
    span {
      color: #979797;
      font-weight: $weight__normal;
      text-align: center;
      // width: 100%;
      // display: block;

      position: absolute;
      right: 30px;
      @include lg {
        margin-left: 40px;
        text-align: left;
      }
    }
    h3 {
      font-size: 22px;
      font-weight: $weight__max;
      text-align: center;

      @include md {
        font-size: 24px;
      }
      @include lg {
        text-align: left;
        font-size: 35px;
      }
    }
    p {
      margin-top: 25px;
      font-size: 14px;
      color: #3e3e3e;
      opacity: 0.7;
      line-height: 17px;
      max-width: 540px;
      text-align: center;
      @include lg {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
      }
      @include xl {
        font-size: 20px;
        line-height: 30px;
      }
    }
    div:first-child {
      margin-bottom: 100px;
      @include lg {
        margin-bottom: 100px;
      }
    }
    div:nth-child(2) {
      margin-bottom: 100px;
    }
    &-bold {
      color: #ffffff;
      opacity: 0.7;
      // font-weight: $weight__bold;
    }
  }
  .section__r {
    position: absolute;
    @include lg {
      position: relative;
      top: 0px;
    }
  }
}
