$darkBlue: #222d5c;

$fontPoppins: 'Poppins', sans-serif;
$fontLato: 'Lato', sans-serif;

$weight__normal: 500;
$weight__bold: 700;
$weight__max: 900;
$weight__tiny: 400;


$pagination-color: #352FEE;