.userProduct {
  width: 100%;
  padding: 44px 12px 106px 12px;
  &--loader {
    min-height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // .loader div {
  //   width: 60px;
  //   height: 60px;
  // }
  &__info {
    display: grid;
    grid-template-columns: 141px 1fr;
    row-gap: 20px;
    column-gap: 15px;
    grid-template-rows: 150px 150px;
    padding-bottom: 20px;

    .product__tile {
      &:first-child {
        grid-row: 3;
        @include md {
          grid-column: 1;
          grid-row: 1;
        }
      }
      &:nth-child(2) {
        grid-row: 3;
        @include md {
          grid-column: 1;
          grid-row: 2;
        }
      }
    }
    .product__bannerSite {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
      @include md {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
      }
    }
  }
  &__iframe {
    width: 100%;
    height: 318px;
    &--wrapper {
      height: 318px;
      position: relative;
      @media (max-width: 755px) {
        grid-column: 1/3;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    html {
      overflow: hidden;
    }
    .skrollr-desktop {
      overflow: hidden !important;
    }
  }
  &__apex {
    margin-left: -20px;
    background-color: #fff;
    margin-top: 20px;
    margin-left: 3px;
    border-radius: 12px;
    min-height: 190px;
    @media (max-width: 1370px) {
      min-height: 135px;
    }
    tspan {
      text-transform: capitalize;
    }
    .loader {
      min-height: 145px;
      display: flex;
      align-items: center;
    }
  }
  &__noData {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: gray;
    width: 100%;
    height: 190px;
    border-radius: 20px;
    background-color: #fff;
  }
}
.apexcharts-text tspan {
  color: rgba(0, 0, 0, 0.37);
  font-weight: $weight__normal;
  width: 100%;
  font-size: 10px;
  position: absolute;
  left: -20px;
  color: #0000005e;
}

// .apexcharts-xaxis-texts-g {
//   // display: none;
// }

.status__tile-wrapper {
  display: flex;
  overflow-x: scroll;
  padding: 5px 0 20px 0;

  .product__tile {
    margin-right: 14px;
    flex-shrink: 0;
    z-index: 1;

    &--section-status {
      margin-top: 24px;
    }
  }
  .detailed_info_tile {
    background-color: #272727;
    font-size: 12px;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    line-height: 18px;
    box-shadow: 0px 3px 11px #0000000f;
    display: flex;
    height: 148px;
    width: 150px;
    padding: 18px 0;
    position: relative;
    left: -20px;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 20px;
    z-index: 0;
    cursor: pointer;

    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.userProduct__tile-wrapper {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 20px 0;

  &--disabled {
    .switcher__wrapper {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .product__tile {
    margin-right: 14px;
    flex-shrink: 0;
    z-index: 1;

    &--section-status {
      margin-top: 24px;
    }
    &-middle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .product__tile-actualizations {
        border-radius: 200px;
        background: var(--czerwony-500, #e20d0d);
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin-top: 10px;
      }
      .product__tile-actualizations-label {
        color: var(--czerwony-500, #e20d0d);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
      }
    }
  }
  .detailed_info_tile {
    background-color: #272727;
    font-size: 12px;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    line-height: 18px;
    box-shadow: 0px 3px 11px #0000000f;
    display: flex;
    height: 148px;
    width: 150px;
    padding: 18px 0;
    position: relative;
    left: -20px;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 20px;
    z-index: 0;
    cursor: pointer;
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .phone {
      display: flex;
      flex-direction: column;
      .react-select .react-select__control .react-select__indicators .react-select__clear-indicator,
      .react-select .react-select__control .react-select__indicators .react-select__indicator-separator {
        display: none;
      }
      .react-select .react-select__control .react-select__indicators .react-select__dropdown-indicator {
        padding: 0;
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .react-select .react-select__control {
        padding: 0 2px 0 5px;
        border-radius: 5px;
      }
      .form__field input {
        border-radius: 5px;
      }
      .userMenu__form-half .form__field:nth-child(2) input {
        padding: 0 6px;
      }
    }
  }
  .center {
    align-items: flex-start;
    width: 220px;
    cursor: auto;
  }
  .paragraph {
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 140%;
    color: #f2f2f2;

    &__btn {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 10px;
      font-weight: 500;
      cursor: pointer;

      span {
        text-decoration: none !important;
      }
    }

    &__link {
      font-weight: 500;
    }
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.product {
  &__tile {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-shadow: 0px 3px 11px #0000000f;
    display: flex;
    height: 148px;
    width: 141px;
    flex-wrap: wrap;
    padding: 18px 10px;
    position: relative;
    justify-content: center;
    align-content: flex-end;
    transition: 0.2s transform ease-in-out;

    &--svg {
      align-content: flex-start;
    }
    &--hover-effect:hover {
      cursor: pointer;
      transform: scale(1.05);
    }

    .svg--featherLock {
      width: 40px;
      height: 100%;
    }
    &-up,
    &-down {
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: flex-end;
      flex-wrap: wrap;
      &--center {
        align-content: center;
      }
    }

    &--standardFont {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
    }

    &-sectionDown {
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-php-version {
      color: var(--szary-500, #808080);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &--report-switch {
      font-size: 11px !important ;
    }
    &-up {
      align-content: center;
      p {
        text-align: center;
        width: 100%;
      }
      &--big {
        font-size: 30px;
        padding-top: 25px;
        font-weight: bold;
      }
      &--svg {
        align-content: flex-start;

        padding-top: 0;
        .svg--pro-cape {
          width: 71px;
          height: 49px;
          margin-bottom: 15px;
        }
      }
      &--red {
        color: red;
      }
      &--card svg {
        width: 55px;
        height: 55px;
      }
    }
    &-down {
      margin-top: 6px;
    }
    &--switch {
      font-size: 18px;
      font-weight: $weight__bold;
    }

    &--light,
    &--bold,
    &--green,
    &--red {
      width: 100%;
      font-size: 12px;
      text-align: center;
      margin: 0;
      font-weight: $weight__bold;
      max-width: 110px;
    }
    &--inProgress {
      width: 100%;
      text-align: center;
      margin: 0;
      font-weight: $weight__bold;
    }

    &--report {
      width: 100%;
      font-size: 12px;
      text-align: center;
      margin: 0;
      font-weight: $weight__bold;
      max-width: 110px;
      .info {
        text-align: left;
      }
    }
    &--green {
      color: rgb(56, 213, 139);
    }
    &--light {
      color: rgba(216, 216, 216, 0.8);
    }

    &--inProgress {
      color: #404040;
      font-size: 10px;
      font-weight: 600;
    }

    &--bigGreen {
      font-size: 49px;
      font-weight: $weight__bold;
      color: rgb(56, 213, 139);
      width: 100%;
      text-align: center;
      margin-bottom: -30px;
    }
    &--bigRed {
      font-size: 49px;
      font-weight: $weight__bold;
      color: rgba(255, 64, 24, 1);
      width: 100%;
      text-align: center;
      margin-bottom: -30px;
    }
    &--orange {
      color: rgba(241, 145, 21, 0.8);
      font-size: 12px;
      font-weight: $weight__bold;
    }
    &--red {
      color: rgba(255, 64, 24, 1);
      font-size: 12px;
      font-weight: $weight__bold;
    }
    &--orangeBig {
      color: rgba(244, 165, 63, 1);
      font-size: 23px;
      font-weight: $weight__bold;
    }
    &--orangeSmall {
      color: rgba(244, 165, 63, 1);
      font-weight: $weight__bold;
      font-size: 12px;
      margin-top: -8px;
    }
    &--black {
      font-size: 35px;
      color: rgba(55, 55, 55, 0.82);
      font-weight: $weight__bold;
    }
    &--white {
      font-weight: $weight__bold;
      color: rgba(255, 255, 255, 1);
      width: 100%;
      text-align: center;
      font-size: 12px;
    }
    &-addon {
      margin-left: -20px;
      padding: 18px 0;
      margin-right: 14px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      flex-shrink: 0;
      cursor: pointer;
      p {
        text-align: left;
        padding-left: 12px;
        margin-left: 10px;
        margin-right: -10px;
      }
    }
    &--orangeBox {
      background-color: rgba(244, 165, 63, 1);
    }
    &--wrap {
      word-wrap: anywhere;
    }
    &--time {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 10px;
      &--red {
        color: #ff0000;
      }
      &--green {
        color: rgb(56, 213, 139);
      }
      &--hours {
        font-size: 24px;
        font-weight: $weight__bold;
      }
      &--minutes {
        font-size: 12px;
        font-weight: $weight__bold;
        display: flex;
        justify-content: center;
      }
    }
    &--disabled {
      cursor: not-allowed;
      opacity: 0.75;
      background-color: rgba(216, 216, 216, 0.5);
      .checkbox-wrapper {
        small {
          cursor: not-allowed;
          background-color: rgba(#d9d9d9, 0.6) !important;

          &:before {
            border-color: rgba(#d9d9d9, 0.6) !important;
            background-color: rgba(#d9d9d9, 0.6) !important;
          }
        }
      }
    }
  }
  &__tileRight {
    height: 148px;
    width: 141px;
    margin-left: -17px;
    padding-left: 10px;
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    font-weight: $weight__bold;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    &--question {
      display: flex;
      padding-right: 10px;
      align-items: center;
    }
    &--questionIcon {
      width: 14px;
      height: 14px;
    }
    &--orange {
      background-color: #f4a53f;
      color: #ffffff;
      margin-right: 14px;
    }
  }
  &__smallWarning {
    background-color: rgba(244, 165, 63, 1);
    border-radius: 15px;
    color: #fff;
    display: flex;
    justify-content: center;
    height: 22px;
    position: absolute;
    left: -7px;
    top: -7px;
    width: 22px;
  }
  &__bannerSite {
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 11px #0000000f;
    background-color: rgba(255, 255, 255, 1);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__heading {
    border-bottom: 1px solid rgba(112, 112, 112, 0.27);
    color: #000000;
    font-size: 18px;
    font-weight: $weight__bold;
    line-height: 27px;
    margin-top: 26px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    gap: 14px;
    align-items: center;
    p {
      color: var(--szary-500, #808080);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-right {
      margin-left: auto;
      cursor: pointer;
    }
  }
  &__packet-img {
    height: 100%;
    margin-bottom: -8px;
  }
}

.dashboardSidebar {
  &__red {
    color: #ff0000 !important;
    background-color: rgba(255, 0, 0, 0.1) !important;
    text-align: center;
  }
  &__white {
    color: #fff !important;
    text-align: center;
  }
  &__head {
    margin-bottom: 30px;
    max-height: 190px;
    &-img {
      position: absolute;
      right: 0;
      top: 0;
    }
    .dashboardSidebar__descript {
      margin-top: -4px;
    }
    span {
      color: rgba(194, 194, 194, 0.82);
      font-size: 12px;
      margin-top: -4px;
      display: block;
    }
    section {
      display: flex;
      align-items: center;
    }
  }
  &__heading {
    font-size: 18px;
    font-weight: $weight__bold;
  }
  &__headingGray {
    font-size: 11px;
    color: #000;
    opacity: 0.18;
    font-weight: $weight__bold;
  }
  &__descript {
    font-size: 12px;
    color: rgba(194, 194, 194, 0.8);
    font-weight: $weight__bold;
  }
  &__item {
    border: 1px solid rgba(112, 112, 112, 0.07);
    border-radius: 5px;
    padding: 13px 16px 13px 40px;
    position: relative;
    margin: 5px 0;

    &-wrapper .dashboardSidebar__descript {
      margin: 10px 0 10px 4px;
    }

    &-heading {
      color: rgba(9, 9, 9, 1);
      font-size: 13px;
    }
    &-paragraph {
      color: rgba(9, 9, 9, 0.26);
      font-size: 12px;
      font-weight: $weight__normal;
    }
    &-time {
      color: rgba(9, 9, 9, 0.26);
      font-size: 13px;
      position: absolute;
      right: 15px;
      top: 13px;
    }
    &-icon {
      left: 0;
      top: 40%;
      left: 10px;
      position: absolute;
    }
    &-price {
      position: absolute;
      right: 15px;
      bottom: 13px;
      color: rgba(9, 9, 9, 1);
      font-size: 12px;
    }
  }
  &__payments {
    &--warning {
      display: flex;
      margin-top: 20px;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 15px;
        height: 15px;
      }
      p {
        color: #ff0000;
        width: 100%;
        opacity: 0.4;
        font-size: 10px;
        max-width: 280px;
      }
    }
  }
  &__payments-card {
    display: flex;
    margin-top: 22px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &-width {
      max-width: 300px !important;
    }

    &--addcard {
      background-color: black;
      border-radius: 5px;
      cursor: pointer;
      svg {
        width: 6px;
        height: 10px;
        margin-right: 5px;
      }
      p {
        color: #fff !important;
        padding: 9px 8px;
      }
    }
    &--card {
      min-width: 187px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(233, 233, 233, 0.68);
      p {
        background-color: #000;
        padding: 7px 3px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        width: 55%;
        font-weight: $weight__normal;
        cursor: pointer;
      }
      svg {
        margin-left: 10px;
        height: 12px;
        width: 15px;
      }
    }
    p {
      font-size: 12px;
      color: rgba(9, 9, 9, 0.75);
      line-height: 16px;
      max-width: 100px;
      display: block;
    }
    span {
      color: rgba(0, 0, 0, 1);
      font-size: 13px;
      text-align: center;
      font-weight: $weight__bold;
      width: 40%;
      height: auto;
      display: flex;
      align-items: center;
      svg {
        margin-right: 9px;
      }
    }
  }
  &__card {
    box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    width: 100%;
    padding: 22px 30px 22px 18px;
    margin-top: -15px;
    &__head {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      p {
        color: rgba(0, 0, 0, 0.8);
      }
      span {
        color: rgba(241, 145, 21, 0.8);
        display: block;
        margin-top: -4px;
      }
      p,
      span {
        font-size: 12px;
        font-weight: $weight__bold;
      }
    }
    ul li {
      color: rgba(9, 9, 9, 0.7);
      font-size: 12px;
      line-height: 25px;
    }
    button {
      font-size: 13px;
      padding: 7px 25px;
    }
  }
  &__about {
    p {
      font-size: 12px;
      margin: 27px 0 14px 0;
    }
    span {
      font-size: 12px;
      margin: 15px 0;
      color: rgba(9, 9, 9, 0.5);
      line-height: 18px;
      display: block;
    }
  }
}

.accConfig {
  &__top {
    p {
      color: #38d58b;
      font-size: 40px;
      font-weight: $weight__max;
    }
  }
  &__bottom {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .btn {
      padding: 10px 50px;
    }
  }
}

.options {
  position: relative;
  right: 20px;
  width: 150px;
  height: 148px;
  background-color: #272727;
  border-radius: 5px;
  margin-top: 24px;
  &__row:first-child {
    padding-top: 30px;
  }
  &__row:last-child {
    padding-bottom: 30px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 3px 25px 3px 25px;
    cursor: pointer;
    @media (max-width: 756px) {
      width: 140px;
      padding: 3px 20px 3px 20px;
    }
    &--month {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: #fff;
    }
  }
}

.sendOptions {
  position: relative;
  right: 20px;
  width: 150px;
  height: 148px;
  background-color: #272727;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 24px;
  &__row:first-child {
    padding-top: 30px;
  }
  &__row:last-child {
    padding-top: 15px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 3px 15px 3px 20px;
    cursor: pointer;
    @media (max-width: 756px) {
      width: 140px;
      padding: 4px 20px 3px 20px;
    }
    &--month {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: #fff;
    }

    &--disabled {
      width: 18px;
      height: 18px;
      border-radius: 30px;
      box-sizing: border-box;
      border: 2px solid #ffffff;
    }
  }
}

.typeOptions {
  position: relative;
  right: 20px;
  width: 150px;
  height: 148px;
  background-color: #272727;
  border-radius: 5px;
  margin-top: 24px;
  &__row:first-child {
    padding-top: 40px;
  }
  &__row:last-child {
    padding-top: 10px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 3px 25px 3px 25px;
    cursor: pointer;
    @media (max-width: 756px) {
      width: 140px;
      padding: 3px 20px 3px 20px;
    }
    &--month {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: #fff;
    }
  }
}

.reportTile {
  cursor: pointer;
}
// .accConfig {
//   height: calc(100vh - 120px);
//   @media (max-width: 1024px) {
//     height: calc(100vh - 92px);
//   }
//   display: flex;
//   align-items: space-between;
//   flex-wrap: wrap;
//   z-index: 99;
//   position: relative;
//   &__top,
//   &__bottom {
//     height: 50%;
//     width: 100%;
//     display: flex;
//     align-items: flex-end;
//   }
//   &__top {
//     p {
//       color: #38d58b;
//       font-size: 40px;
//       font-weight: $weight__max;
//     }
//   }
//   &__bottom {
//     background-color: #f9f9f9;
//     margin-left: -20px;
//     padding-left: 20px;
//     position: relative;
//     display: flex;
//     align-content: space-between;
//     flex-wrap: wrap;
//     &:after {
//       content: '';
//       width: 1000px;
//       right: -1000px;
//       height: 100%;
//       position: absolute;
//       background-color: #f9f9f9;
//     }
//     &-button {
//       width: 100%;
//       display: flex;
//       justify-content: flex-end;
//       margin-bottom: 110px;
//     }
//     .btn {
//       display: flex;
//       justify-content: center;
//       border-radius: 8px;
//       width: 150px;
//     }
//     &--upper {
//       display: flex;
//       width: 100%;
//       justify-content: space-between;
//       align-items: center;
//       font-weight: $weight__normal;
//       margin-top: 20px;
//       span {
//         background-color: rgba(0, 0, 0, 0.07);
//         padding: 4px 9px;
//         border-radius: 5px;
//         svg {
//           margin-right: 15px;
//         }
//       }
//     }
//   }
//   &--heading {
//     width: 100px;
//     &-orange {
//       color: #d57238 !important;
//     }
//   }
// }

.pending_report {
  position: relative;
  bottom: 5px;
  left: 3px;
  svg {
    path {
      stroke: #38d58b;
      fill: #38d58b;
    }
  }
}

.report {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &--tooltip {
      position: relative;
      right: 10px;
    }
  }
}

.switcher {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &--tooltip {
      position: relative;
      right: 12px;
    }
  }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
