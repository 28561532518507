.fullscreen-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #f2f2f2;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  padding-top: 143px;
  flex-direction: column;

  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  z-index: -1;
  overflow: auto;
  padding-bottom: 80px;

  @media (max-width: 1000px) {
    padding-top: 00px;
  }
  &--blur {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    padding-top: 0;
    justify-content: center;
    .my-websites-list__modal-wrapper {
      border-radius: 10px;
      background: #fff;
      width: 100%;
      max-width: 560px;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h2 {
      color: var(--czarny-500, #111);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.045px;
    }
    h3 {
      max-width: 398px;
      color: var(--czarny-300, #333);
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-top: 24px;
    }
    .svg--corgi-lost {
      margin-top: 34px;
      width: 258px;
      height: 106.235px;
    }
    .btn {
      border-radius: 30px;
      width: 190px;
      height: 40px;
    }
  }

  &__button-group {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 17px;

    margin-top: 30px;
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  input {
    pointer-events: none;
  }
  &--open {
    z-index: 999;
    opacity: 1;
    pointer-events: all;
    div iframe {
      pointer-events: all;
    }
    input {
      pointer-events: all;
    }
  }
  & > .btn--wrapper {
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  .my-account__table tr:first-child {
    td {
      padding-top: 0;
    }
  }

  &__back {
    position: absolute;
    top: 30px;
    left: -16px;
    position: absolute;
    top: 4px;
    left: -52px;
    cursor: pointer;
    @media (max-width: 1000px) {
      left: 16px;
      top: 38px;
    }
  }

  td {
    color: var(--czarny-500, #111);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    padding-top: 8px;
  }

  td:first-child {
    width: 170px;
    font-weight: 600;
  }

  &__header {
    color: var(--czarny-500, #111);
    font-size: 24px;
    font-weight: 900;
    line-height: 30px; /* 125% */
    margin-bottom: 30px;
    @media (max-width: 1000px) {
      margin-top: 85px;
    }
  }

  &__subtitle {
    color: var(--czarny-300, #666);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  div &-close {
    @media (max-width: 1000px) {
      top: 30px;
      right: 16px;
      position: absolute;
    }
  }

  &__close {
    position: absolute;
    top: 14px;
    right: 14px;
  }

  &__warpper {
    position: relative;
    iframe {
      width: calc(100% + 10px) !important;
      margin: 0px !important;
      pointer-events: none;
    }
    @media (min-width: 1000px) {
      min-width: 400px;
    }
    @media (max-width: 1000px) {
      height: 100%;

      padding-bottom: 61px;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-inline: 16px;
    }
    &--password {
      input,
      input[type='password'] {
        color: var(--szary-500, #808080);
        font-weight: 400;
        line-height: 24px; /* 240% */
        letter-spacing: 1px;
      }
    }

    &--overflow {
      @media (min-width: 1000px) {
        max-height: 100vh;
        max-height: 100dvh;
        overflow: auto;
        // margin-bottom: -80px;
        // padding-bottom: 80px;
        // margin-top: -143px;
        // padding-top: 143px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &::-webkit-scrollbar {
          display: none;
        }
        & > .btn--wrapper {
          width: 100%;
          .btn {
            position: absolute;
            top: -80px;
            color: var(--Czarny-400, #262626);
            font-size: 13px;
            .btn__icon {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
  &__new-password-input.form__field {
    width: 100%;
    .eye {
      @media (max-width: 1000px) {
        top: 50px;
      }
    }
  }
  &__password-strenght {
    .add-website-form__tooltip-content {
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.1);
    }
    h4 {
      display: flex;
      gap: 4px;
    }
    .add-website-form__packet-tooltip {
      align-items: flex-start;
    }
    .tooltip__arrow {
      margin-top: 30px;
    }
  }
  &__terms {
    margin-top: -10px;
    @media (max-width: 1000px) {
      margin-top: 24px;
    }
    a {
      color: var(--niebieski-500, #352fee);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      text-decoration: none;
    }
    li {
      margin-bottom: 12px;
      @media (max-width: 1000px) {
        margin-bottom: 20px;
      }
    }
  }
}
