.change-card {
  width: 190px;
  height: 34px;
  display: flex;
  &__number {
    background-color: rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    width: 50%;
    border-radius: 4px;
    margin-right: -5px;
    font-size: 13px;
    font-weight: 700;
  }
  &__button {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 50%;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    cursor: pointer;
  }
}
