/*
 *  Wszystkie style wspólne dla róznych komponentów, np. marginesy
 */

/*
  * margins & paddings
  */
.m-auto {
  margin: auto;
}

.mt {
  &--50 {
    margin-top: 50px;
  }
}

.mr {
  &--10 {
    margin-right: 10px;
  }

  &--30 {
    margin-right: 30px;
  }
}

/*
 *  display
 */
.flex {
  display: flex;

  &--center {
    align-items: center;
    justify-content: center;
  }

  &--align-center {
    align-items: center;
  }

  &--column {
    flex-direction: column;
  }
}

.relative {
  position: relative;
}

/*
 *  fonts
 */
.fw {
  &--bold {
    font-weight: bold;
  }
}

.fs {
  &--12 {
    font-size: 12px;
  }
}

.lh {
  &--18 {
    line-height: 18px;
  }
}

.fc {
  &--white {
    color: #ffffff;
  }

  &--dark-gray {
    color: #272727;
  }
}

/*
 *  Effects
 */
.inverse {
  &--full {
    filter: invert(100%);

    &--disabled {
      opacity: 0.5;
    }
  }
}

.fw-600 {
  font-weight: 600;
}
