.sectionRocket {
  padding: 380px 30px 100px 30px;
  flex-wrap: wrap;
  background-color: #fff;
  margin-top: -350px;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 96%);
  position: relative;
  @include md {
    padding: 380px 0 200px;
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
  }
  @include lg {
    padding: 400px 0 300px;
  }
  .mainHeading {
    text-align: center;
    margin-bottom: 72px;
  }
  &--img {
    position: absolute;
    right: 100px;
    bottom: 70px;
  }
  p {
    max-width: 580px;
    text-align: center;
    color: #101010;
    opacity: 0.7;
    font-size: 14px;
    @include md {
      font-size: 16px;
    }
    @include xl {
      font-size: 20px;
    }
  }
  span {
    font-size: 14px;
    text-align: center;
    color: #101010;
    width: 100%;
    display: block;
    margin: 30px 0 40px;
    font-weight: $weight__bold;
    opacity: 0.8;
    @include md {
      font-size: 16px;
    }
    @include lg {
      font-size: 20px;
    }
  }
}
