.websitesStatus {
  width: auto;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.82);
  &__wrapper {
    display: flex;
    align-items: center;
    justify-items: center;
    margin: 0 6px 0 0;
  }
  &__icon {
    margin-right: 3px;
  }
  &--indexing {
    color: rgba(255, 64, 24, 0.82);
  }
}
