.navi__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 68px;
  margin: 0 32px 0 32px;
  position: fixed;
  z-index: 998;
  top: 0;
  width: 100%;
  margin: 0;
  background-color: #f2f2f2;
  transition: 0.3s;
  transition-property: top, background-color;

  &--hidden {
    top: -100px;
  }
  @media (max-width: 1024px) {
    margin-top: 0px;
    margin-left: 0;
    background-color: #fff;
    .hamburger {
      top: 24px;
    }
    &--logo {
      height: 64px !important;
      svg {
        top: 0px !important;
        left: 20px;
      }
    }
    &-hidden {
      display: none;
    }

    &--sticky {
      @media (max-width: 1024px) {
        position: sticky;
        background-color: #e4e4e4;
        opacity: 1;
        .navi__header-content {
          height: 64px;
          background-color: transparent;
        }
      }
    }
    &--dark {
      background-color: transparent;
      .hamburger__inner {
        background: var(--bialy, #f2f2f2);
        &::after,
        &::before {
          background: var(--bialy, #f2f2f2);
        }
      }
      .navi__header--logo {
        path {
          fill: #fff;
        }
      }
    }
  }
  @media (min-width: 1025px) {
    justify-content: center;
    // margin: 0 75px 0 87px;
    &--sticky {
      display: none;
      margin: 0 32px 0 32px;
      position: static;
      opacity: 0;
    }
  }
  &__logoMenuMobile {
    width: 65px;
    height: 40px;
    position: absolute;
    right: 140px;
    top: 18px;
    z-index: 999;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &-content {
    max-width: 1136px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 72px;
    @media (max-width: 1000px) {
      padding-top: 0;
    }
  }
  &--logo {
    width: 100px;
    height: 50px;
    position: relative;
    z-index: 11;
    top: 0px;

    img {
      position: absolute;
      top: 47px;
      width: 100%;
      height: 100%;
    }
    svg {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
    }
    @media (min-width: 1025px) {
      padding-right: 100px;
      margin-top: 0;
    }
  }

  &--webinarLogo {
    width: 100px;
    height: 50px;
    z-index: 11;
    position: absolute;
    left: 87px;

    @media (max-width: 1023px) {
      left: 50px;
    }

    img {
      position: absolute;
      top: 47px;
      width: 100%;
      height: 100%;
    }
    svg {
      position: absolute;
      top: 47px;
      width: 100%;
      height: 100%;
    }
    @media (min-width: 1025px) {
      padding-right: 100px;
      margin-top: 0;
    }
    &--active {
      position: fixed;
    }
  }

  &--hamburger {
    &__box {
      -webkit-tap-highlight-color: transparent;
      outline: none;
    }
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 69px;
    right: 29px;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    margin: 0;
    transition: transform 0.6s 0.1s ease-in-out;
    z-index: 10;
    outline: none;
    @media (min-width: 1025px) {
      display: none;
    }

    &--active {
      position: fixed;
      .hamburger__inner {
        background-color: transparent;

        &:before {
          transform: translateY(8px) rotate(45deg);
        }
        &:after {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  }

  &--menu {
    visibility: hidden;
    display: flex;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    height: 0%;
    transition: height 0.3s ease;
    align-items: center;
    @media (min-width: 1025px) {
      visibility: visible;
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: relative;
      background-image: none;
    }
    @media (max-width: 1024px) {
      background-color: #2d2d2d;
      width: 0;
      left: unset;
      right: 0;
      justify-content: unset !important;
      transform: translateX(246px);
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      transition: width 0.2s ease-in-out, transform 0.1s ease-in-out;
    }
    .navi__header--language {
      display: flex;
      justify-content: center;
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        right: 0px;
        top: 6px;
        background-image: url('../../Image/Icon/icon_chevron_black.svg');
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(90deg);
        z-index: 1;
        bottom: 0;
        @media (max-width: 1024px) {
          background-image: url('../../Image/Icon/icon_chevron_white.svg');
          right: -8px;
        }
      }
      @media (max-width: 1024px) {
        margin-top: 14px;
        .language-select {
          color: rgba(255, 255, 255, 1);
        }
      }
      select {
        z-index: 1;
        width: 45px;
        font-weight: $weight__max;
        font-size: 18px;
        text-align: left;
        cursor: pointer;
        @media (max-width: 1024px) {
          font-size: 20px;
        }
      }
      &-white {
        select {
          color: #fff;
          @media (max-width: 1024px) {
            color: #000;
          }
        }
        &::before {
          background-image: url('../../Image/Icon/icon_chevron_white.svg');
          @media (max-width: 1024px) {
            color: #000;
          }
        }
      }
    }
    &--active {
      visibility: visible;
      height: 100vh;
      position: fixed;
      justify-content: center;
      z-index: 10;
      flex-direction: column;
      @media (max-width: 1024px) {
        transform: translateX(0px);
        transition: width 0.2s ease-in-out, transform 0.2s ease-in-out;
        width: 246px;
      }

      nav {
        height: auto !important;
        display: flex;
        justify-items: center;
        flex-direction: column;
        justify-content: center;
        visibility: visible;
        .navi__link {
          color: #fff;
          text-align: center;
          margin: 15px 0;
          font-size: 26px !important;
          z-index: 10;
          &--active {
            @media (max-width: 1024px) {
              background-color: #4845450a;
            }
          }
        }
      }
      .navi__header--language {
        display: flex;
        justify-content: center;
      }
    }
  }
  .navi__links {
    height: 0;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      min-height: 380px;
      padding-top: 100px;
    }
    @media (min-width: 1025px) {
      height: 100%;
      padding-left: 95px;
    }

    @media (max-width: 1024px) {
      .navi__link {
        font-size: 14px !important;
        padding: 14px 0;
        margin: 5px 20px;
        border-radius: 10px;
      }
    }
    @media (max-width: 1120px) {
      .navi__link {
        font-size: 13px;
      }
    }
  }
  .navi__link {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    -webkit-tap-highlight-color: transparent;
    &--active {
      font-weight: $weight__bold;
    }
    font-size: 16px;
    @media (min-width: 1025px) {
      margin: 0 25px;
      font-size: 16px;
    }

    &--login {
      margin-left: auto;
      .btn {
        padding: 10px 30px;
        background-color: transparent;
        border: 2px solid #262626;
        border-radius: 30px;
        color: var(--Czarny-400, #262626);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        height: 40px;
        @media (max-width: 1024px) {
          color: white;
          border: 2px solid white;
        }
      }
    }
  }
}
@mixin hamburger-line {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
.hamburger {
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: 66px;
  right: 29px;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  transition: transform 0.6s 0.1s ease-in-out;
  z-index: 10;
  outline: none;
  @media (min-width: 1025px) {
    display: none;
  }
  &--active {
    position: fixed;
    z-index: 99;
    .hamburger__inner {
      background-color: transparent !important;

      &:before {
        transform: translateY(8px) rotate(45deg);
      }
      &:after {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }
  &__box {
    width: 21px;
    height: 15px;
    display: inline-block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
  }
  &__inner {
    @include hamburger-line;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.15s 0.1s ease-in-out;
    &:before,
    &:after {
      @include hamburger-line;
      content: '';
      left: 0;
      transition: transform 0.15s 0.1s ease-in-out;
      // @media (max-width: 1024px) {
      //   background-color: #fff;
      // }
    }
    &:before {
      top: -8px;
    }
    &:after {
      top: 8px;
    }
  }
}

.navi__header-white {
  @media (min-width: 1024px) {
    background-color: #2d2d2d !important;
  }
  @media (max-width: 1024px) {
    background-color: #2d2d2d !important;
  }
  .navi__link {
    color: #fff;
  }

  .navi__header--language .language-select {
    .css-1wa3eu0-placeholder,
    .language-select__indicator,
    .css-1uccc91-singleValue {
      color: #fff;
    }
  }
  .hamburger__inner,
  .hamburger__inner::before,
  .hamburger__inner::after {
    background-color: #fff;
  }
  // .hamburger--active .hamburger__inner,
  .hamburger--active .hamburger__inner::before,
  .hamburger--active .hamburger__inner::after {
    background-color: #000;
    @media (max-width: 1024px) {
      background-color: #fff;
    }
  }
}
.hamburger--active .hamburger__inner::before,
.hamburger--active .hamburger__inner::after {
  @media (max-width: 1024px) {
    background-color: #fff;
  }
}
.css-1wa3eu0-placeholder {
  font-weight: $weight__normal;
  cursor: pointer;
}
.navi__header-white {
  .navi__link--login .btn {
    @media (min-width: 1024px) {
      color: #fff;
      border: 2px solid #fff;
    }
  }
}
