.form__field {
  position: relative;
  margin: 0 auto 0 auto;
  font-family: $fontPoppins;
  input,
  select,
  textarea {
    // font: 500 14px/18px $fontMontserrat;
    font-family: $fontPoppins;
    width: 100%;
    border: none;
    font-size: 14px;
    border: 1px solid rgba(226, 226, 226, 1);
    border-radius: 2px;
    background-color: #fff;
    outline: none;
    @media (max-width: 1441px) {
      font-size: 12px;
    }
    option {
      color: #444;
    }
  }
  textarea {
    resize: vertical;
  }
  input,
  textarea {
    padding: 13px 15px;
    border-radius: 2px;
    @media (max-width: 1441px) {
      padding: 8px 14px;
    }
  }
  &--svg {
    position: absolute;
    right: 16px;
    top: 53px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    fill: #000;
    pointer-events: none;
  }
  div &--hide-error {
    input {
      border: none !important ;
    }
  }
  div &--loader-visible {
    input {
      border: none !important ;
    }
    .form__field--label-error {
      display: none;
    }
  }

  &--white {
    .form__field--label-error {
      color: var(--czerwony-500, #e20d0d);
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-left: 0;
      max-height: 0;
      transition: 0.3s;
      margin-top: 0px;
      margin-bottom: 0px;
      overflow: hidden;
      a {
        color: var(--czerwony-500, #e20d0d);
        text-decoration: underline;
      }
    }

    &.form__field--error {
      .form__field--label-error {
        max-height: 16px;
        margin-top: 8px;
        margin-bottom: -4px;
      }
    }
    input[type='number'] {
      //hide arrows
      -moz-appearance: textfield;
      appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset;
    }

    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px white inset;
    }
    input[type='password'] {
      font-size: 20px;
    }
    input {
      border-radius: 5px;
      background: #fff;
      line-height: 24px;
      font-size: 14px;
      border: none;
      height: 48px;
      &::placeholder {
        color: var(--szary-500, #808080);
      }
    }
    .react-select {
      input {
        height: unset;
        padding-left: 23 px !important ;
      }
    }
    label.field-label {
      color: var(--czarny-500, #111);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      white-space: nowrap;
    }
  }

  &--gray {
    input {
      border-radius: 5px;
      background: var(--szary-100, #f2f2f2);
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      @media (max-width: 1000px) {
        background: #fff;
      }
      &::placeholder {
        color: var(--szary-500, #808080);
      }
    }
  }
  &--password-checker-open {
    .form__field--label-error {
      margin-top: 33px;
    }
  }
  .field-label {
    font-weight: 400;
    // color: $dark;
    font-size: 12px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 5px 2px;
  }
  &--companyName {
    .field-label {
      margin-top: 10px;
    }
  }
  .loader {
    position: absolute;
    right: 8px;
    bottom: 11px;
  }
  .eye {
    position: absolute;
    right: 18px;
    top: 30px;
    display: flex;
    align-items: center;
    height: 46px;
    &:hover {
      cursor: pointer;
    }
  }
  .react-select {
    &__control {
      cursor: pointer;
      border-style: unset;
      // min-height: 24px;
      height: 33px;
      background-color: #fff;
      margin: 5px 0 20px 0;
      border-radius: 2px;
      font-size: 12px !important;
      padding: 0 0 0 10px;
      line-height: 0;
      font-weight: 400;
      color: rgba(26, 26, 26, 0.65);
      &:hover {
        border-color: #e9e6e6;
      }
      &--is-focused {
        outline: none;
        box-shadow: none;
        // height: 33px;
        // line-height: 33px;
      }
    }
    &--is-multi {
      &__control {
        border-style: unset;
        border-bottom: 1px solid #e9e6e6;
        border-radius: 0;
        min-height: 24px;
        background-color: transparent;
        &:hover {
          border-color: #e9e6e6;
        }
        &--is-focused {
          outline: none;
          box-shadow: none;
          min-height: 24px;
        }
      }
      &__value-container {
        padding: 0;
        min-height: 24px;
        top: -7px;
      }
    }
    &__menu {
      text-align: left;
    }
    &__value-container {
      padding: 0;
      min-height: 24px;
      // top: -7px;
    }
    &__indicators {
      // top: -6px;
      position: relative;
      img {
        height: 15px;
        margin-right: 3px;
      }
    }
    &__single-value {
      color: #212020;
      font-weight: 300;
      // font-size: 1.1rem;
      margin-left: 0;
      overflow: visible;
    }
  }
  &--date-picker-blank {
    input {
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid #2b2b2b !important;
      // font-family: $fontRoboto;
      font-weight: 700;
      font-size: 17px;
      @media (max-width: 1441px) {
        font-size: 15px;
      }
    }
  }
  &--default {
    input,
    select,
    textarea {
      background-color: #fff !important;
      border-radius: 2px;
      border: none;
      height: 33px;
      width: 100%;
      font-size: 12px !important;
      padding: 19px 10px;
      // font-family: $fontLato;
      font-weight: 400;
      color: rgba(26, 26, 26, 0.65);
      @media (max-width: 1441px) {
        font-size: 10px !important;
      }
    }
    textarea {
      height: 100px;
      padding: 10px 10px;
    }
  }
  // &--gray {
  //   input {
  //     // background-color: $inputGray;
  //     // color: $black;
  //   }
  // }
  &--error {
    input,
    textarea,
    .react-select__control {
      border: 1px solid #e20d0d !important;
    }
    .react-select__input {
      input {
        border: none !important;
      }
    }
  }
  &--checkbox {
    height: 20px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin: -15px auto 25px auto;
    font-size: 14px;
    color: #969090;
  }
  &--disabled {
    input[type='text']::placeholder {
      color: #dcdbdb;
    }
  }
  .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  input[type='checkbox'] {
    width: 20px;
    height: 17px;
  }
  &--label-error-down {
    position: absolute;
    bottom: -20px;
    color: #e20d0d;
    font-weight: 400 !important;
  }
  &--label-error {
    // font-family: $fontLato;
    color: #e20d0d;
    margin-left: 3px;
    position: relative;
    animation: show 0.25s ease-in;
  }
  @keyframes show {
    0% {
      right: -40px;
    }
    50% {
      right: 0px;
    }
    80% {
      right: -10px;
    }
    100% {
      right: 0px;
    }
  }
  &--hide-eye {
    .eye {
      display: none;
    }
  }
}

.search__form {
  input {
    border-radius: 5px;
    @media (min-width: 768px) {
      min-width: 446px;
    }
    @media (max-width: 1441px) {
      min-width: 356px;
    }
    @media (max-width: 950px) {
      min-width: 180px;
    }
    font-weight: 600;
    // &::placeholder {
    //   // color: $black;
    // }
    @media (min-width: 375px) and (max-width: 767px) {
      font-size: 11px;
      line-height: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      &::placeholder {
        font-weight: 600;
        opacity: 0.23;
      }
    }
  }
}

.checkbox-wrapper {
  display: inline-block;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  input {
    display: none;
    &:checked ~ small {
      background: #38d58b;
      transition: 0.3s;
    }
    &:checked ~ small:before {
      transform: translate(20px, 0px);
      transition: 0.3s;
      box-shadow: 3px 0 3px rgba(0, 0, 0, 0.25);
      background: #38d58b;
      border: 2px solid #15bc6e;
      width: 19px;
      height: 19px;
    }
    &:checked ~ .product__tile-light {
      display: none;
    }
    &:not(:checked) ~ .product__tile-green {
      display: none;
    }
  }
  small {
    display: inline-block;
    width: 40px;
    height: 17px;
    background: #d9d9d9;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      width: 21px;
      height: 21px;
      background: #fff;
      border-radius: 50%;
      top: -2px;
      left: 0;
      transition: 0.3s;
      box-shadow: -3px 0 3px rgba(0, 0, 0, 0.25);
    }
  }
}

.picker {
  width: 287px;
  height: 39px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  align-items: center;
  border-radius: 5px;

  &__hex {
    width: 170px;
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  &__dropDown {
    position: relative;
    top: 3px;
    right: 8px;
  }

  &__thumbnail {
    background-color: #352fee;
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  &__input {
    display: none;
  }
  &__menu {
    position: absolute;
    @media (max-width: 1024px) {
      position: static;
    }

    .chrome-picker {
      display: flex;
      min-width: 288px;
      .flexbox-fix {
        input {
          padding: 0;
        }
      }
    }
  }
}
// <div className="product__tile">
// <label className="checkbox-wrapper">
//   <input type="checkbox" />
//   <small></small>
// </label>
// </div>
