.svg {
  &--sms {
    .a {
      fill: none;
    }
    .b,
    .c {
      stroke: none;
    }
    .c {
      fill: #000;
    }
  }

  &--icon--rotate {
    transform: rotate(180deg);
  }
  &--close-circle {
    path {
      fill: #e20d0d;
    }
  }
  &--check {
    path {
      fill: none;
    }
  }

  &--email {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
  &--red-circle {
    border-radius: 100%;
    background-color: #e20d0d;
  }
  &--close-circle {
    path {
      fill: #e20d0d;
    }
  }
  &--check {
    path {
      fill: none;
    }
  }

  &--passed {
    width: 28px;
    height: 28px;
  }

  &--wp-warning {
    .a {
      fill: none;
      stroke: #f4a53f;
      stroke-linecap: round;
    }
  }
  &--featherDatabase {
    fill: transparent;
    path {
      fill: none;
      stroke: #38d58b;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    &--deactive path {
      stroke: #f43f3f;
    }
    &--active path {
      stroke: #38d58b;
    }
    &--warning path {
      stroke: #f4a53f;
    }
  }
  &--help {
    .a {
      fill: #0e0e0e;
    }
  }
  &--featherLock {
    path {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    &--red path {
      stroke: #f43f3f;
    }
    &--green path {
      stroke: #38d58b;
    }
    &--orange path {
      stroke: #f4a53f;
    }
    &--gray path {
      stroke: #d8d8d8;
    }
  }
  &--featherSettings {
    .a {
      fill: none;
      stroke: #38d58b;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    &--deactive .a {
      stroke: #f43f3f;
    }
    &--active .a {
      stroke: #38d58b;
    }
    &--warning .a {
      stroke: #f4a53f;
    }
  }
  &--featherGoogle {
    .a {
      fill: #f43f3f;
    }
    &--deactive .a {
      stroke: #f43f3f;
    }
    &--active .a {
      stroke: #38d58b;
    }
    &--warning .a {
      stroke: #f4a53f;
    }
  }
  &--big {
    width: 40px;
    height: 40px;
  }
  &--right-arrow {
    .a {
      fill: none;
      stroke: #fff;
      stroke-width: 1.5px;
    }
  }
  &--right-arrow-white {
    stroke: white;
    stroke-width: 3;
    stroke-linecap: round;
  }
  &--right-arrow-dash {
    .a {
      fill: none;
      stroke: #fff;
      stroke-width: 1.5px;
    }
    &--black {
      .a {
        fill: none;
        stroke: #000;
        stroke-width: 1.5px;
      }
    }
  }
  &--accept {
    .a {
      fill: #38d58b;
    }
    .b {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }
    &--red {
      .a {
        fill: #f43f3f;
      }
      .b {
        fill: none;
        stroke: #f43f3f;
        stroke-width: 2px;
      }
    }
    &--gray {
      .a {
        fill: rgba(216, 216, 216, 0.8);
      }
      .b {
        fill: none;
        stroke: rgba(216, 216, 216, 0.8);
        stroke-width: 2px;
      }
    }
  }
  &--information {
    .a {
      opacity: 0.31;
    }
    .b {
      fill: #fff;
      stroke: #000;
      stroke-width: 2px;
    }
    .c {
      fill: #1a1a1a;
      font-size: 11px;
      font-family: Poppins-Medium, Poppins;
      font-weight: 500;
    }
    .d {
      stroke: none;
    }
    .e {
      fill: none;
    }
  }
  &--accept-brown {
    .a {
      fill: #d57238;
    }
    .b {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
  &--woof-premium {
    .a {
      fill: #c1c1c1;
    }
    .b {
      fill: #fff;
    }
    .c {
      fill: #f29325;
    }
    .d {
      fill: #ececec;
    }
    .e {
      fill: #3832ec;
    }
    .ff {
      fill: #e7e2ff;
    }
    .g {
      fill: #e4bad7;
    }
    .h {
      fill: #e7acce;
    }
    .i {
      fill: #302936;
    }
    .j {
      fill: #c97fb3;
    }
    .k {
      fill: #bd1622;
    }
  }
  &--paws {
    .a {
      opacity: 0.13;
    }
  }
  &--quest {
    .a {
      fill: #fff;
    }
    .b {
      fill: #352fee;
    }
  }
  &--dogPaws {
    .a {
      opacity: 0.22;
    }
    .b {
      fill: #302936;
    }
    .c {
      fill: #fff;
    }
    .d {
      fill: #3832ec;
    }
    .e {
      fill: #f29325;
    }
    .ff {
      fill: #e4bad7;
    }
    .g {
      fill: #e7acce;
    }
    .h {
      fill: #c97fb3;
    }
    .i {
      fill: #bd1622;
    }
    .j {
      fill: none;
    }
  }
  &--youknow {
    .a {
      fill: #fff;
    }
    .b {
      fill: #352fee;
    }
  }
  &--arrow-down {
    .a {
      fill: none;
      stroke: #000;
      stroke-width: 1.5px;
    }
  }
  &--arrow-up {
    .a {
      fill: none;
      stroke: #38d58b;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
  &--chevron-right {
    .a {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
      stroke-width: 3px;
    }
  }
  // &--chevron-up {
  //   .a {
  //     fill: none;
  //     stroke: #fff;
  //     stroke-linecap: round;
  //     stroke-width: 3px;
  //   }
  // }
  &--backup {
    .a {
      fill: none;
      stroke: #352fee;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
  &--broken-connected {
    .a,
    .b {
      fill: none;
      stroke-linecap: round;
    }
    .a {
      stroke: #000;
    }
    .b {
      stroke: #ff9618;
    }
  }
  &--card {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    &-white .a {
      stroke: #fff;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
  &--exclamation-white {
    fill: #fff;
  }
  &--exclamation-circle {
    font-size: 13px;
    font-family: Poppins-Bold, Poppins;
    font-weight: 700;
    &--fullred {
      .a {
        fill: #e20d0d;
      }
      .b {
        fill: #fff;
      }
    }
    &--orange {
      .a {
        fill: #f4a53f;
      }
      .b {
        fill: #fff;
      }
    }
    &--red {
      .a {
        fill: #efd5d5;
      }
      .b {
        fill: #ff0000;
      }
    }
  }

  &--group-file {
    .a {
      fill: #38d58b;
    }
    .b {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
  &--circle {
    .a {
      fill: #f4a53f;
    }
  }
  &--padlock {
    .a {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }
  &--padlock-green {
    .a {
      fill: none;
      stroke: #38d58b;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }
  &--polygon {
    &-black {
      .a {
        fill: #fff;
      }
      .b,
      .c {
        stroke: none;
      }
      .c {
        fill: #000;
      }
    }
    &-blue {
      .a {
        fill: none;
      }
      .b,
      .c {
        stroke: none;
      }
      .c {
        fill: #352fee;
      }
    }
  }
  &--question {
    .a {
      fill: #f4a53f;
    }
    .b {
      fill: #fff;
      font-size: 20px;
      font-family: Poppins-Bold, Poppins;
      font-weight: 700;
    }
  }
  &--close {
    .a {
      opacity: 0.35;
    }
  }
  &--close--gray {
    .a {
      color: rgb(138, 138, 138);
      opacity: 0.5;
    }
  }
  &--plus {
    .a {
      fill: none;
      stroke: #ff9618;
      stroke-width: 3px;
    }
    &--white {
      .a {
        stroke: #fff;
      }
    }
  }
  &--accept-orange {
    .a {
      fill: none;
      stroke: #ff9618;
      stroke-width: 3px;
    }
  }

  &--settings {
    .a {
      fill: none;
      stroke: #1c1c1c;
      stroke-width: 1.3px;
    }
  }
  &--main-logo {
    .a {
      fill: none;
      stroke-linecap: square;
    }
    .b {
      fill: #fff;
    }
    .c {
      fill: #f29325;
    }
    .d {
      fill: #ececec;
    }
    .e {
      fill: #3832ec;
    }
    .glassess {
      fill: #e7e2ff;
    }
    .g {
      fill: #e4bad7;
    }
    .h {
      fill: #e7acce;
    }
    .i {
      fill: #302936;
    }
    .j {
      fill: #c97fb3;
    }
    .k {
      fill: #bd1622;
    }
    .l {
      fill: #191919;
    }
    .m,
    .n {
      stroke: none;
    }
    .n {
      fill: #cbcbcb;
    }
  }
  &--chevron-down {
    .a {
      fill: none;
      stroke: #c3c3c3;
      stroke-width: 4px;
    }
  }
  &--chevron-black {
    .a {
      fill: none;
      stroke: #000;
      stroke-width: 4px;
    }
  }
  &--chevron-black-up {
    transform: rotate(180deg);

    .a {
      fill: none;
      stroke: #000;
      stroke-width: 4px;
    }
  }
  &--corgi-banda {
    .a {
      fill: #fff;
    }
    .b {
      fill: #3832ec;
    }
    .c {
      fill: #f29325;
    }
    .d {
      fill: #e4bad7;
    }
    .e {
      fill: #e7acce;
    }
    .ff {
      fill: #302936;
    }
    .g {
      fill: #c97fb3;
    }
    .h {
      fill: #bd1622;
    }
    .i {
      fill: none;
    }
    .j {
      fill: #ececec;
    }
    .k {
      fill: #e7e2ff;
    }
    .l {
      fill: #f59325;
    }
    .m {
      fill: #cbcbcc;
    }
  }
  &--corgi-premium {
    .a {
      fill: #fff;
    }
    .b {
      fill: #f29325;
    }
    .c {
      fill: #ececec;
    }
    .d {
      fill: #3832ec;
    }
    .e {
      fill: #e7e2ff;
    }
    .ff {
      fill: #e4bad7;
    }
    .g {
      fill: #e7acce;
    }
    .h {
      fill: #302936;
    }
    .i {
      fill: #c97fb3;
    }
    .j {
      fill: #bd1622;
    }
  }
  &--logo-white {
    fill: #fff;
  }
  &--eye {
    height: 20px;
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
  &--crossed-eye {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  &--credit-card {
    width: 20px;
  }
  &--bust-woof {
    .a {
      fill: #c1c1c1;
    }
    .b {
      fill: #fff;
    }
    .c {
      fill: #f29325;
    }
    .d {
      fill: #ececec;
    }
    .e {
      fill: #3832ec;
    }
    .ff {
      fill: #e7e2ff;
    }
    .g {
      fill: #e4bad7;
    }
    .h {
      fill: #e7acce;
    }
    .i {
      fill: #302936;
    }
    .j {
      fill: #c97fb3;
    }
    .k {
      fill: #bd1622;
    }
  }
  &--sit-down {
    .a {
      fill: #fff;
    }
    .b {
      fill: #f29325;
    }
    .c {
      fill: #ececec;
    }
    .d {
      fill: #3832ec;
    }
    .e {
      fill: #e7e2ff;
    }
    .ff {
      fill: #e4bad7;
    }
    .g {
      fill: #e7acce;
    }
    .h {
      fill: #302936;
    }
    .i {
      fill: #c97fb3;
    }
    .j {
      fill: #bd1622;
    }
  }
  &--packets-basic {
    .a,
    .b {
      fill: #fff;
    }
    .a {
      stroke: #c4c4c4;
      stroke-width: 10px;
    }
    .c {
      fill: #e4bad7;
    }
    .d {
      fill: #f29325;
    }
    .e {
      fill: #302936;
    }
    .ff {
      fill: #3832ec;
    }
    .g {
      fill: #cbcbcc;
    }
    .h {
      stroke: none;
    }
    .i {
      fill: none;
    }
  }
  &--packets-premium {
    .a,
    .b {
      fill: #fff;
    }
    .a {
      stroke: #352fee;
      fill: #352fee;
      stroke-width: 10px;
    }
    .c {
      fill: #f29325;
    }
    .d {
      fill: #ececec;
    }
    .e {
      fill: #3832ec;
    }
    .ff {
      fill: #e7e2ff;
    }
    .g {
      fill: #e4bad7;
    }
    .h {
      fill: #e7acce;
    }
    .i {
      fill: #302936;
    }
    .j {
      fill: #c97fb3;
    }
    .k {
      fill: #bd1622;
    }
    .l {
      stroke: none;
    }
    .m {
      fill: none;
    }
  }
  &--pro-packets {
    .a {
      stroke: #303030;
      stroke-width: 10px;
    }
    .b {
      fill: #fff;
    }
    .c {
      fill: #3832ec;
    }
    .d {
      fill: #f29325;
    }
    .e {
      fill: #e4bad7;
    }
    .ff {
      fill: #e7acce;
    }
    .g {
      fill: #302936;
    }
    .h {
      fill: #c97fb3;
    }
    .i {
      fill: #bd1622;
    }
    .j {
      fill: none;
    }
    .k {
      stroke: none;
    }
  }
  &--packets-pro {
    .a {
      stroke: #303030;
      stroke-width: 10px;
    }
    .b {
      fill: #fff;
    }
    .c {
      fill: #3832ec;
    }
    .d {
      fill: #f29325;
    }
    .e {
      fill: #e4bad7;
    }
    .ff {
      fill: #e7acce;
    }
    .g {
      fill: #302936;
    }
    .h {
      fill: #c97fb3;
    }
    .i {
      fill: #bd1622;
    }
    .j {
      fill: none;
    }
    .k {
      stroke: none;
    }
  }
  &--plus {
    .a {
      fill: none;
      stroke: #ff9618;
      stroke-width: 3px;
    }
  }
  &--plus--white {
    .a {
      fill: none;
      stroke: #fff;
      stroke-width: 3px;
    }
  }
  &--pro-cape {
    .a {
      fill: #fff;
    }
    .b {
      fill: #3832ec;
    }
    .c {
      fill: #f29325;
    }
    .d {
      fill: #e4bad7;
    }
    .e {
      fill: #e7acce;
    }
    .ff {
      fill: #302936;
    }
    .g {
      fill: #c97fb3;
    }
    .h {
      fill: #bd1622;
    }
    .i {
      fill: none;
    }
  }
  &--hamburger {
    .a {
      fill: #fff;
    }
  }
  &--dashboard {
    .a,
    .c {
      fill: none;
    }
    .a {
      stroke: #fff;
      stroke-width: 1.5px;
    }
    .b {
      stroke: none;
    }
  }
  &--pages {
    .a,
    .c {
      fill: none;
    }
    .a {
      stroke: #fff;
      stroke-width: 1.5px;
    }
    .b {
      stroke: none;
    }
  }
  &--payments {
    .a {
      fill: #fff;
    }
  }
  &--partners {
    .a {
      fill: #fff;
    }
  }
}
