.password-strength-checker {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 8px;
  padding-bottom: 21px;
  overflow: hidden;
  transition: 0.3s;
  flex-shrink: 0;

  &__bar {
    border-radius: 20px;
    height: 8px;
    width: 100%;
    position: relative;
    background: #d9d9d9;
    transition: 0.3s;
    &--weak {
      background: #e20d0d;
      p {
        color: #e20d0d;
      }
    }
    &--medium {
      background: #f29225;
      p {
        color: #f29225;
      }
    }
    &--strong {
      background: #1aaf54;
      p {
        color: #1aaf54;
      }
    }
  }
  &__label {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    opacity: 0;
    transition: 0.3s;
    text-transform: capitalize;
    &--active {
      opacity: 1;
    }
  }
  &--hidden {
    max-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}
