.single-website {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  @media (max-width: 1370px) {
    max-width: 616px;
    padding-right: 0;
  }
  @media (max-width: 1000px) {
    padding-inline: 16px;
    max-width: 100%;
  }
  &__header {
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--Czarny-500, #111);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px; /* 125% */
    min-height: 30px;
    margin-bottom: 32px;
    max-width: 1232px;
    width: 100%;
    @media (max-width: 1000px) {
      margin-top: 32px;
    }
    a {
      color: inherit;
      cursor: pointer;
    }
  }

  &__header-back {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__resources {
    border-radius: 6px;
    background: rgba(144, 140, 255, 0.2);
    width: 100%;
    padding: 10px;
    display: flex;
    gap: 10px;
    color: var(--Czarny-300, #333);
    font-size: 10px;
    font-weight: 400;
    line-height: 15px; /* 150% */
    margin-top: 16px;

    @media (max-width: 1370px) {
      margin-bottom: 16px;
    }
    @media (max-width: 430px) {
      flex-direction: column;
    }
    &--pending {
      margin-top: 8px;
    }

    span {
      border-radius: 100px;
      background: var(--Niebieski-500, #352fee);
      display: flex;
      width: 40px;
      min-width: 40px;
      height: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      line-height: 18px; /* 150% */
    }
  }

  &__resources-button {
    color: var(--Niebieski-500, #352fee);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 16px;
    cursor: pointer;
    .svg {
      width: 18px;
      height: 18px;
      path {
        stroke: #352fee;
      }
    }
  }

  &__grid {
    width: 100%;
    max-width: 1232px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 16px;

    @media (max-width: 1370px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }

    &--status-bar {
      margin-bottom: 16px;
    }

    &--half {
      grid-template-columns: repeat(3, 1fr);
      max-width: 616px;
      @media (max-width: 1000px) {
        max-width: unset;
        grid-template-columns: repeat(2, 1fr);
      }

      height: 100%;
      .single-website__grid-item--full-width {
        grid-column: span 3;
        @media (max-width: 1000px) {
          grid-column: span 2;
        }
      }
    }

    &--high {
      height: 296px;
      @media (max-width: 1370px) {
        height: 100%;
        // min-height: 296px;
      }
      .single-website__grid-item--blue,
      .single-website__grid-item--black {
        h3 {
          margin-top: 16px;
          max-width: 145px;
        }
      }
    }
  }

  &__grid-item {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    background: #fff;
    color: var(--Czarny-300, #333);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    max-width: 192px;
    min-height: 192px;
    position: relative;
    & > p,
    & > div > p {
      line-height: 20px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    & > .__react_component_tooltip {
      border-radius: 10px !important;
      background: #fff;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
      font-size: 10px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 14px !important; /* 140% */
    }
    &--maximum-security {
      .btn {
        max-width: 166px;
      }
      .single-website__corgi-sitting {
        bottom: -40px;
        width: 100px;
        height: 153px;
        right: 6px;
      }
    }
    &--tablet-296 {
      @media screen and (min-width: 1000px) {
        min-height: 296px;
      }
    }
    &--296 {
      min-height: 296px;
    }

    &-svg {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &--colspan {
      grid-column: span 2;
      max-width: 400px;
      padding: 0;
      @media (max-width: 1000px) {
        max-width: unset;
      }
    }
    &--rowspan {
      grid-row: span 2;
    }
    &--blue {
      padding: 24px;
      background: var(--Niebieski-500, #352fee);
      overflow: hidden;
    }

    &--blue-small {
      .single-website__grid-item-svg {
        right: -10px;
        bottom: -10px;
      }
    }

    &--black {
      background: var(--Czarny-400, #262626);
      padding: 24px;
      overflow: hidden;
      .single-website__grid-item-svg {
        path {
          fill: #000;

          stroke: var(--Szary-700, #404040);
        }
      }
    }
    &--pro {
      padding: 24px;
      background-color: #fff;
      .single-website__corgi-rotated--pro {
        top: unset;
        bottom: 40px;
        @media (max-width: 1000px) {
          bottom: 0px;
        }
      }
    }

    &--full-width {
      grid-column: span 6;
      max-width: unset;
      .single-website__corgi-rotated {
        bottom: 40px;
        top: unset;
        width: 174px;
        height: auto;
      }
    }

    &--no-padding {
      padding: 0;
    }
  }

  &__pro-header-small {
    max-width: 185px;
    display: flex;
  }

  &__status {
    color: var(--Zielony-500, #38d58b);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.045px;
    display: flex;
    align-items: flex-end;
    gap: 16px;
    &--green {
      color: #38d58b;
    }
    &--red {
      color: #e20d0d;
    }
    &--yellow {
      color: #f29225;
      gap: 8px;
      font-size: 14px;
      svg {
        min-width: 24px;
      }
    }
  }

  &__subtitle {
    color: var(--Szary-600, #666);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-transform: capitalize;
  }

  &__plan {
    height: 64px;
    &--card {
      height: 40px;
      svg {
        width: 40px;
      }
    }
    .svg {
      height: 64px;
      width: auto;
    }
    .svg--credit-card {
      height: 40px;
      path {
        fill: #333333;
      }
    }
  }

  &__iframe {
    width: 300%;
    min-height: calc(192px * 3);
    border-radius: 10px;
    border: none;
    transform-origin: 0 0;
    transform: scale(calc(1 / 3));
  }

  &__iframe-wrapper {
    overflow: hidden;
    max-height: 192px;
    pointer-events: none;
    @media (max-width: 1370px) {
      order: -1;
    }
  }

  &__bolded-title {
    color: var(--Szary-100, #f2f2f2);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    z-index: 1;
    line-height: 20px; /* 125% */
    &--half {
      max-width: 177px;
      @media (max-width: 1000px) {
        max-width: 224px;
      }
    }
    &--basic {
      @media (max-width: 1000px) {
        max-width: 177px;
      }
    }
    &--subtitle {
      max-width: 145px;
    }
    &--50 {
      max-width: 50%;
    }
    &--200 {
      max-width: 200px !important;
    }

    &--368 {
      max-width: 368px !important;
    }
  }

  &__bolded-title-wrapper {
    h3 {
      margin-top: 16px !important;
    }
  }

  &__bolded-subtitle {
    color: var(--Szary-100, #f2f2f2);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    z-index: 1;
    strong {
      font-weight: 600;
    }
    &--wide {
      margin-top: 0 !important;
    }
  }

  &__corgi-rotated {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 2;
    &--premium {
      max-width: 175px;
      top: 0px;
      @media (max-width: 1000px) {
        width: 115.001px;
        height: 79.845px;
        top: unset;
        bottom: 32px;
      }
    }
    &--pro {
      max-width: 175px;
      top: 24px;
      @media (max-width: 1000px) {
        max-width: 115.001px;
        top: unset;
        bottom: 32px;
      }
    }
    &--basic {
      @media (max-width: 1000px) {
        right: -15px;
      }
    }
  }
  &__button {
    &--report {
      min-width: 204px;
    }
  }
  &__title {
    color: var(--Czarny-500, #111);
    max-width: 1232px;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.045px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  &__status-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 44px;
    @media (max-width: 1370px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    &--loader {
      align-items: flex-start;
    }
  }

  &__status-top-title {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .single-website__subtitle {
      text-transform: none;
    }
    h3 {
      @media (max-width: 1000px) {
        font-size: 14px;
      }
    }
  }

  &__status-top-buttons {
    display: flex;
    align-items: center;
    gap: 12px;
    @media (max-width: 1370px) {
      width: 100%;
    }
    &--active {
      @media (max-width: 1000px) {
        flex-direction: column;
      }
    }
    &--wrapper {
      display: flex;
      grid-gap: 8px;
      gap: 8px;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      .btn--wrapper {
        @media (max-width: 1370px) {
          flex: 1;
        }
      }
    }
    .btn.gray {
      min-width: 151px;
      &:disabled {
        color: #bfbfbf;
        background: #f2f2f2;
        path {
          stroke: #bfbfbf;
        }
      }

      @media (max-width: 1370px) {
        width: 100%;
        justify-content: center;
      }
    }
    .__react_component_tooltip {
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
      font-size: 12px !important;
    }
    .single-website__select__value-container {
      .bin {
        display: none;
      }
    }
  }

  &__select__single-value {
    color: var(--Czarny-300, #333);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }

  div &__select__control {
    border-radius: 6px;
    background: var(--Szary-100, #f2f2f2);
    height: 40px;
    border: none;
    width: 135px;
    cursor: pointer;
    box-shadow: none;
  }

  div &__select__option {
    color: var(--Czarny-300, #333);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
    padding: 0;
    min-height: 24px;
    cursor: pointer;
    padding-inline: 14px;
    background-color: unset;
    &.single-website__select__option--is-selected {
      display: none;
    }
  }

  div &__select__menu {
    border-radius: 6px;
    background: var(--Szary-100, #f2f2f2);
    box-shadow: none;
  }

  div &__select__menu-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  div &__select__indicator-separator {
    display: none;
  }

  div &__button.btn {
    height: 40px;
    border: none;
    &--report {
      @media (max-width: 1370px) {
        margin-top: 24px;
        width: 100%;
        justify-content: center;
      }
    }
  }

  &__status-graph {
    &--shorted {
      .apexcharts-xaxis-texts-g {
        text:first-child {
          transform: translateX(15px);
        }
        text:last-child {
          transform: translateX(-15px);
        }
      }

      .apexcharts-series {
        path {
          width: 24px;
          border-radius: 4px;
        }
      }
    }
    .apexcharts-series {
      path {
        cursor: pointer;
      }
      path[fill='rgba(92,221,160,1)'] {
        cursor: default;
        pointer-events: none;
      }
      path[fill='rgba(191,191,191,1)'] {
        cursor: default;
        pointer-events: none;
      }
    }

    &--loader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      flex-wrap: wrap;
      overflow: hidden;
      height: 111px;
      margin-top: 63px;
      @media (max-width: 1370px) {
        gap: 8px;
        height: 64px;
      }
    }
    &__day {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }

  &__date-range {
    position: absolute;
    z-index: 2;
    top: 50px;
    transition: 0.3s;
    left: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.14);
    padding: 24px 16px;
    width: 311px;
    & > div {
      border-radius: 10px;
    }
    &--hidden {
      pointer-events: none;
      opacity: 0;
      z-index: -1;
    }
  }

  .apexcharts-legend {
    display: none;
  }

  &__status-legend {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    &--loader {
      margin-top: 56px;
      gap: 16px;
    }
    &--button-mobile {
      margin-top: 29px;
      width: 100%;
      display: block;
    }
  }

  &__status-legend-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Szary-600, #666);

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    span {
      min-height: 24px;
      min-width: 24px;
      border-radius: 4px;
    }
    &--working {
      span {
        background: var(--Zielony-500, #38d58b);
      }
    }
    &--short-unavailability {
      span {
        background: var(--Pomaraczowy-500, #f29225);
      }
    }
    &--long-failure {
      span {
        background: var(--Czerwony-500, #e20d0d);
      }
    }

    &--no_data {
      span {
        background: var(--Szary-300, #bfbfbf);
      }
    }
  }

  &__green-text {
    color: var(--Zielony-500, #38d58b);
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &--large {
      font-size: 48px;
      letter-spacing: -0.48px;
      line-height: 48px;
    }

    &--big {
      font-size: 32px;
      letter-spacing: -0.32px;
      .product__tile--time--minutes {
        justify-content: flex-start;
        font-size: 20px;
        letter-spacing: -0.2px;
      }
      .product__tile--time--hours {
        font-size: 32px;
        letter-spacing: -0.32px;
      }
      .product__tile--time {
        margin-bottom: 0;
      }
    }

    .loader div {
      display: flex;
    }
  }

  &__php {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 12px;
    text-transform: capitalize;
    color: #666666;
    &--svg {
      margin-left: -7px;
    }
    &--correct,
    &--current,
    &--green {
      color: var(--Zielony-500, #38d58b);
      path {
        stroke: var(--Zielony-500, #38d58b);
      }
    }
    &--out_of_date--yellow,
    &--orange {
      color: var(--Pomaraczowy-500, #f29225);
      path {
        stroke: var(--Pomaraczowy-500, #f29225);
      }
    }
    &--out_of_date--red,
    &--red {
      color: var(--Czerwony-500, #e20d0d);
      path {
        stroke: var(--Czerwony-500, #e20d0d);
      }
    }
    &--no-data,
    &--gray {
      path {
        stroke: #666666;
      }
    }
  }
  &__website-specific {
    width: 100%;
    max-width: 100%;
    height: 100%;
    @media (max-width: 1000px) {
      margin-top: 0 !important;
    }
    &--events {
      .websitesSpecifications__item__empty {
        svg {
          margin-top: 60px;
          @media (max-width: 1000px) {
            margin-top: 30px;
            max-width: 100%;
          }
        }
      }
    }
    &-item-header {
      max-width: unset !important;
      padding-right: 0;
    }
    .svg--information {
      width: 24px;
      height: 24px;
    }
    .websitesSpecifications {
      max-width: unset !important;
      padding-right: 0;
      border: none !important;
      @media (max-width: 1370px) {
        min-height: unset;
      }
    }

    .websitesSpecifications__heading {
      @media (min-width: 1300px) {
        height: 44px;
      }
    }
    .websitesSpecifications__item {
      max-width: unset;
      padding: 4px 0;
      width: calc(100% + 24px);
      margin-left: -12px;
      margin-right: -12px;
      padding-inline: 12px;
    }
    .websitesSpecific__footer {
      right: 0;
      float: right;
    }
    .websitesSpecific__item__count {
      padding: 0;
      vertical-align: middle;
      height: 26px;
      min-width: 26px;
      padding-inline: 4px;
      font-size: 12px;
    }
    .websitesSpecifications__item--l {
      align-items: flex-start;
    }
    .websitesSpecifications__item__empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: unset;
      align-items: center;
      p {
        border-radius: 4px;
        background: var(--Szary-100, #f2f2f2);
        padding: 16px 48px;
        max-width: unset;
        width: 100%;
      }
      .svg {
        margin-top: 20px;
        max-width: 200px;
        height: auto;
        path:first-child {
          fill: var(--Szary-100, #f2f2f2);
        }
      }
    }
    .websitesSpecifications__icon {
      margin-top: 3px;
      margin-right: 12px;
      svg {
        width: 18px;
        height: 18px;
      }
      .svg--sms .c {
        fill: #352fee;
      }
      .svg--email .a {
        stroke: #352fee;
      }
    }

    .btn--website-specific {
      border-radius: 4px;
      border: 1px solid #111;
      color: #0e0e0e;
      font-size: 9px;
      font-weight: 700;
      padding: 0 15px 0 19px;
      height: 32px;
      display: flex;
      align-items: center;
      .btn__icon {
        display: flex;
        align-items: center;
        margin: 0px 0px 0px 12px;
        i {
          display: flex;
        }
        svg {
          width: 14px;
          height: 14px;
          path {
            stroke: #0e0e0e;
          }
        }
      }
    }
  }

  .websitesSpecific__item__tooltip {
    @media (max-width: 1370px) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: -12px;
        left: -12px;
        width: 48px;
        height: 48px;
        z-index: 1;
        border-radius: 100%;
      }
    }
  }
  &__flex {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    max-width: 1232px;
    @media (max-width: 1370px) {
      flex-direction: column;
      align-items: center;
    }

    &--margin-top {
      margin-top: 16px;
    }
  }

  &__grid-icon {
    width: 40px;
    height: 40px;
    &--domain {
      margin-left: -3px;
      path {
        stroke-width: 2px;
      }
    }
    &--file {
      margin-left: -3px;
      max-height: 34px;
    }
    &--lock {
      margin-left: -5px;
      path {
        stroke-width: 1px;
      }
    }

    &--green {
      path {
        stroke: var(--Zielony-500, #38d58b);
      }
    }

    &--red {
      path {
        stroke: var(--Czerwony-500, #e20d0d);
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    &--files-incorrect {
      pointer-events: none;
      .single-website__arrow-right {
        display: none;
      }
      .single-website__grid-icon--file {
        path {
          fill: #808080;
        }
      }
    }
  }

  &__arrow-right {
    display: flex;
    width: 52px;
    height: 52px;
    padding: 14px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--Szary-100, #f2f2f2);
    cursor: pointer;

    svg {
      transform: rotate(270deg);
      path {
        stroke: #404040;
        stroke-width: 3px;
      }
    }
  }

  &__bold-bottom {
    color: var(--Czarny-300, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    @media (max-width: 1000px) {
      font-size: 18px;
    }
    &--capitalize {
      text-transform: capitalize;
    }
  }

  &__corgi-sitting {
    position: absolute;
    bottom: 50px;
    right: 24px;
    z-index: 2;
    height: 105px;
    &--small {
      height: 65px;
    }
  }

  &__corgi-cape {
    position: absolute;
    bottom: 50px;
    right: 10px;
    z-index: 2;
    height: 105px;
    width: 115px;
  }

  &__grid-button.btn {
    justify-content: center;
    padding-inline: 0;
    margin-inline: -7px;
    width: calc(100% + 14px);
    @media (max-width: 1000px) {
      max-width: 138;
    }
  }

  &__size {
    color: var(--Czarny-300, #333);
    color: var(--Czarny-300, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    @media (max-width: 1000px) {
      display: flex;
      align-items: flex-end;
      line-height: 1;
    }
    &--big {
      font-size: 48px;
      letter-spacing: -0.48px;
      margin-right: 4px;
      @media (max-width: 1000px) {
        font-size: 32px;
        line-height: 1;
        letter-spacing: -0.32px;
      }
    }
  }

  &__switch-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
    .switcher {
      display: flex;
      align-items: center;
    }
  }

  &__switch-subtitle {
    color: var(--Szary-600, #666);
    font-size: 12px;
    font-weight: 400;
    margin-top: 6px;
    line-height: 18px; /* 150% */
    display: flex;
    margin-bottom: 12px;
    &--url {
      margin-top: 16px;
      color: #333;
      display: flex;
    }
    a {
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
      word-break: break-all;
      &.disabled {
        pointer-events: none;
        text-decoration: none;
        font-weight: 400;
      }
    }
  }

  &__tile-with-switch {
    & > div > p {
      width: calc(100% + 4px);
    }
    &--ftp-error {
      p {
        min-height: 40px;
      }
    }
    &--colspan {
      padding: 24px;
    }
    &--subtitle {
      p:first-child {
        margin-bottom: 0px;
      }
    }
    &--disabled {
      color: #808080;
      pointer-events: none;
      border: 1px solid var(--Szary-300, #bfbfbf);
      background: var(--Szary-100, #f2f2f2);
      .WORDPRESS,
      .PLUGIN,
      .STOP_SIGN {
        path {
          fill: #808080;
        }
      }
      .THEME,
      .MAIL_ICON,
      .SHUFFLE,
      .WARNING_ICON {
        path {
          stroke: #808080;
        }
      }
    }
  }

  &__ftp-icon {
    &--strong {
      path {
        fill: var(--Zielony-500, #38d58b);
      }
    }
    &--medium {
      path {
        fill: var(--Pomaraczowy-500, #f29225);
      }
    }
    &--weak {
      path {
        fill: var(--Czerwony-500, #e20d0d);
      }
    }
  }

  &__ftp-label {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px; /* 100% */
    margin-top: 15px;
    color: #808080;
    &--strong {
      color: var(--Zielony-500, #38d58b);
    }
    &--medium {
      color: var(--Pomaraczowy-500, #f29225);
    }
    &--weak {
      color: var(--Czerwony-500, #e20d0d);
    }
  }

  &__switch-pending-updates-wrapper {
    border-radius: 6px;
    background: rgba(255, 195, 128, 0.5);
    width: 100%;
    padding: 10px;
    display: flex;
    gap: 10px;
    color: var(--Czarny-300, #333);
    font-size: 10px;
    font-weight: 400;
    line-height: 15px; /* 150% */

    @media (max-width: 1370px) {
      margin-bottom: 8px;
    }
    @media (max-width: 430px) {
      flex-direction: column;
    }

    span {
      border-radius: 100px;
      background: var(--Pomaraczowy-500, #f29225);
      display: flex;
      min-width: 25px;
      max-width: fit-content;
      padding-inline: 10px;
      height: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px; /* 150% */
    }
    &--long {
      span {
        padding-inline: 10px;
      }
    }
  }

  &__tile-list {
    .svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
    li {
      color: var(--Szary-100, #f2f2f2);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px; /* 150% */
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__full-width {
    width: 100%;
    margin-top: 24px;
    max-width: 1232px;
  }

  &__phone {
    color: var(--Czarny-500, #111);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 30px;
    line-height: 29px;
  }

  &__ftp-error {
    border-radius: 6px;
    background: rgba(255, 102, 102, 0.2);
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    color: var(--Czarny-300, #333);
    margin-top: 24px;
    margin-right: -7px;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
      margin-bottom: 16px;
    }

    /* Caption */
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    .svg {
      min-width: 24px;
      height: 24px;
    }

    &--row {
      flex-direction: row;
      margin-top: 8px;
      margin-bottom: 20px;
    }
  }

  &__report-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    border-radius: 4px;
    background: #fff;
    padding: 24px;
    h3 {
      color: var(--Czarny-300, #333);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 8px;
      line-height: 24px; /* 150% */
    }
    h4 {
      color: var(--Szary-600, #666);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
    p {
      color: var(--Czarny-300, #333);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--user {
      flex-direction: row;
      p {
        align-items: flex-start;
        span {
          display: none;
        }
      }
    }
  }
  &__modal-wrapper {
    @media (max-width: 1000px) {
      padding-inline: 16px;
      max-width: 376px;
    }
  }
  &__list-modal-wrapper {
    max-width: calc(1136px + 48px);
    width: 100%;
    padding-inline: 24px;
    .notificationsTable__item--icon svg {
      margin: 0;
      width: 18px;
      height: 18px;
    }
    .updatesTable__item--icon svg {
      margin: 0;
      width: 18px;
      height: 18px;
    }
    .svg--sms .c {
      fill: #352fee;
    }
    .svg--email .a {
      stroke: #352fee;
    }
    .tableWebsites__settings .dots {
      top: 2px;
    }
    .tableWebsites__settings--wrap {
      width: 0;
    }

    .cell {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__modal--backups {
    @media (min-width: 1000px) {
      grid-template-columns: 1.25fr 2.5fr 200px 1fr 1.5fr 1fr 0.1fr;
    }
  }
  &__modal--individual {
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-areas: 'name status date';
    @media (max-width: 1000px) {
      border-radius: 5px;
    }
    &.notificationsTable--firstLine {
      display: grid;
    }
  }

  &__modal-list-table {
    color: var(--Czarny-500, #111);

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    li {
      display: flex;
      align-items: center;
      gap: 8px;
      &.notificationsTable__item--whereSend--size {
        @media (max-width: 1000px) {
          display: none;
        }
      }
    }
    .notificationsTable__item--settings {
      display: unset;
    }
    .notificationsTable__item--status {
      justify-content: flex-start;
      .badge {
        width: fit-content;
      }
    }
    .badge {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      height: 32px;
      padding-inline: 14px;
    }
    &--incidents {
      grid-template-columns: 200px 100px 1fr 1fr;
      grid-template-areas: none;
      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  &__list-modal-2fa {
    max-width: 560px;
    .top {
      color: var(--Czarny-500, #111);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
    }
    .phone {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 4px;

      .form__field {
        margin: 0;
        height: 48px;

        &:first-child {
          width: 121px;
          .react-select__control {
            height: 48px;
            margin: 0;
            border-radius: 5px;
          }
        }
        &:last-child {
          width: 158px;
        }
      }
    }
    .table__row {
      display: flex;
      align-items: center;
      height: 64px;
      border-bottom: 0.5px #d9d9d9 solid;
      @media (max-width: 1000px) {
        flex-direction: column;
        height: unset;
        margin-top: 16px;
      }
    }
    .table {
      &--right {
        width: 100%;
      }

      &--left {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
      }

      &--heading {
        color: #666;
        font-size: 14px;
        font-weight: 400;
        @media (max-width: 1000px) {
          display: none;
        }
        .table--left {
          margin-left: 16px;
          width: calc(100% - 24px);
        }
      }
      &--lp {
        color: #666;
        font-size: 14px;
        font-weight: 400;
      }
      &--name {
        font-weight: 600;
      }
    }

    .react-select__clear-indicator {
      display: none;
    }
    .react-select__indicator-separator {
      display: none;
    }
    .buttons {
      margin-top: 56px;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      .btn--wrapper {
        width: 100%;
      }
      button {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        @media (max-width: 1000px) {
          padding-inline: 16px;
        }
      }
    }
    .form__field input {
      border-radius: 5px;
      background: #fff;
      border: none;
      height: 48px;
    }
    .react-select__value-container {
      height: 48px;
      div:nth-child(2) {
        display: none;
      }
    }
    .form__field--label-error {
      font-size: 12px;
    }
    .react-select__single-value {
      color: var(--Czarny-500, #111);
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__packet-tooltip {
    top: -110px !important;

    .add-website-form__tooltip-content {
      box-shadow: unset;
    }
    @media (max-width: 1000px) {
      top: 0 !important;
    }
    p {
      display: block;
      a {
        color: var(--Niebieski-500, #352fee);
        font-weight: 700;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  &__report-list {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    li {
      border-radius: 4px;
      background: #fff;
      padding: 12px 24px;
      color: var(--Czarny-500, #111);
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
    }
  }
  &__report-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    button.btn {
      border-radius: 4px;
      font-size: 10px;
      font-weight: 700;
      padding: 0 18px;
      height: 32px;
      min-height: 32px;
      .btn__icon {
        margin-bottom: -5px;
      }
    }
    .new-blue {
      .svg {
        width: 14px;
        height: 14px;
        path {
          stroke: white;
        }
      }
    }
    .new-blue-stroke {
      .svg {
        width: 14px;
        height: 14px;
        path {
          stroke: #352fee;
        }
      }
    }
  }

  &__report-error {
    border-radius: 5px;
    background: #f5d6d6;
    display: flex;
    padding: 12px 20px;
    align-items: center;
    gap: 16px;
    color: var(--Czarny-300, #333);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-top: 24px;
    span {
      cursor: pointer;
    }
    .svg {
      min-width: 24px;
    }
  }

  &__add-url {
    border-radius: 6px;
    background: var(--Szary-100, #f2f2f2);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 319px;
    padding: 0 0 0 14px;
    gap: 8px;
    @media (max-width: 1000px) {
      width: 100%;
    }
    .btn {
      height: 32px !important;
      font-size: 12px;
      &--transparent {
        margin-right: 8px;
        color: var(--Niebieski-500, #352fee);
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0;
        display: flex;
        align-items: center;
      }
    }
    span {
      display: flex;
      align-items: center;
      max-width: 163px;
      overflow-y: hidden;
      overflow-x: auto;
    }
    input,
    p {
      border: none;
      background: transparent;
      color: var(--Czarny-300, #333);
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
    .loader {
      margin: 0;
      margin-right: 14px;
    }
  }
  &__2fa-input {
    .form__field--text input {
      @media (max-width: 1000px) {
        height: 38px !important;
      }
    }
  }
  .userMenu__form--tel-updated.userMenu__form-half .form__field:nth-child(2) input {
    @media (max-width: 1000px) {
      height: 38px !important;
    }
  }
  &__select {
    .bin {
      display: flex;
    }
    &--url {
      @media (max-width: 1000px) {
        width: 100%;
      }
      .single-website__select__control {
        width: 280px;
        @media (max-width: 1000px) {
          width: 100%;
        }
      }
    }
    &--date-wrapper {
      @media (max-width: 1370px) {
        display: block;
        cursor: pointer;
      }
    }
    &--date {
      .single-website__select__option--is-selected {
        display: flex !important;
        background-color: unset;
        &:hover {
          background-color: #deebff;
        }
      }
      .single-website__select__option {
        &:hover {
          background-color: #deebff;
        }
      }
    }

    &--custom {
      width: 311px;
      .single-website__select__control {
        width: 100%;
      }
      .single-website__custom-dates {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          font-size: 14px;
          border-radius: 100px;
          background: var(--Szary-200, #d9d9d9);
          display: flex;
          width: 118px;
          height: 28px;
          padding: 4px 29px;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
  &__select-option {
    display: flex;
    gap: 8px;
    align-items: center;
    svg {
      height: 18px;
      width: 18px;
      path:first-child {
        fill: #e20d0d;
      }
    }
  }

  &__select-option-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  &__switcher {
    &--disabled {
      cursor: default;
      small {
        background: var(--Szary-100, #f2f2f2) !important;
        &::before {
          background-color: #f2f2f2 !important;
          box-shadow: -1px 2px 8px 0px rgba(0, 0, 0, 0.25) !important;
        }
      }
    }
  }

  &__date-range-wrapper {
    position: relative;
    @media (max-width: 1370px) {
      width: calc(50% - 4px);
      flex: 1;
      .single-website__select__control {
        width: 100%;
      }
    }

    &--custom {
      @media (max-width: 1370px) {
        width: 100%;
        flex: 100%;
        .single-website__select--custom {
          width: 100%;
        }
        .single-website__select__value-container {
          justify-content: center;
        }
        .single-website__date-range {
          width: 100%;
        }
        .react-calendar {
          width: 100%;
        }
        .date-range .react-daterange-picker__calendar {
          height: 100%;
        }
        .react-calendar__tile {
          color: inherit;
        }
        .react-calendar__month-view__days__day--weekend {
          color: #d10000;
        }
        .react-calendar__tile--active {
          color: white;
        }
        .date-range .react-calendar__tile--rangeStart {
          color: white;
        }
        .react-calendar__navigation__label__labelText {
          color: black;
        }
        .react-calendar__navigation__arrow {
          color: black;
        }
        .react-calendar__tile--rangeEnd {
          color: white;
        }
      }
    }
  }

  &__subpage-wrapper {
    @media (max-width: 1000px) {
      width: calc(50% - 4px);
      flex: 1;
      .btn.gray {
        width: 100%;
      }
    }
  }
  &__information {
    position: absolute !important;
    right: 12px;
    top: 12px;
    svg {
      cursor: default;
      width: 24px;
      height: 24px;
    }
  }
  [data-id='tooltip'] {
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    border-radius: 10px !important;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  }

  &__grid-title {
    @media (max-width: 1000px) {
      font-size: 14px;
    }

    &--update-tile {
      margin-bottom: 16px;
    }
  }
  &__updates-are-crucial {
    h3 {
      margin-top: 10px !important;
    }
    .single-website__corgi-rotated--basic {
      top: unset;
      bottom: 54px;
      width: auto;
      height: 29px;
      transform: rotate(90deg);
      right: 30px;
      z-index: 2;
    }
    .btn--wrapper {
      margin-top: 32px;
      z-index: 3;
      .btn {
        padding-inline: 16px;
        width: 100%;
      }
    }
    .single-website__grid-item-svg {
      bottom: -50px;
      right: -20px;
    }
  }

  &__you-can-ahead {
    h3 {
      margin-top: 10px !important;
    }
    .single-website__corgi-rotated--premium {
      top: unset;
      bottom: 64px;
      width: auto;
      width: 79.999px;
      height: 55.545px;
      right: 15px;
      z-index: 2;
    }
    .btn--wrapper {
      margin-top: 32px;
      z-index: 3;
      .btn {
        padding-inline: 8px;
        text-align: center;
        width: 100%;
        justify-content: center;
      }
    }
    .single-website__grid-item-svg {
      bottom: -50px;
      right: -20px;
    }
  }

  &__skeleton {
    &--space-between {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
    }
    &--wrapper-padding {
      padding: 24px;
    }

    &--title {
      width: 100%;
      margin-bottom: 16px;
      max-width: 242px;
    }

    &--title-short {
      width: 100%;
      margin-bottom: 16px;
      max-width: 106px;
    }

    &--bottom {
      width: 100%;
      max-width: 106px;
      height: 31px;
      margin-top: auto;
    }
    &--round {
      border-radius: 100px;
    }

    &--subtitle {
      width: 100%;
      max-width: 162px;
    }

    &--row {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
      span:last-child {
        width: 100%;
      }
    }

    &__row-wrapper {
      display: flex;
      gap: 23px;
      flex-direction: column;
      width: 100%;
    }
    &--overflow {
      overflow: hidden;
    }

    &--margin-bottom-40 {
      margin-bottom: 40px;
    }
  }

  &__modal-bottom {
    margin-bottom: 50px;
  }

  &__domain {
    &--gray {
      path:first-child {
        stroke: var(--Szary-300, #bfbfbf);
      }
      path:last-child {
        fill: var(--Szary-300, #bfbfbf);
      }
    }

    &--red {
      path:first-child {
        stroke: var(--Szary-300, #e20d0d);
      }
      path:last-child {
        fill: var(--Szary-300, #e20d0d);
      }
    }
  }
  &__cancel-subscription {
    @media (max-width: 1000px) {
      height: unset;
    }
  }

  &__form-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &__switch-bolded-subtitle {
    color: var(--Niebieski-500, #352fee);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
    min-height: 36px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    margin-bottom: 36px;
    svg {
      height: 10px;
      margin-bottom: 4px;
      margin-left: 4px;
      path {
        stroke: #352fee;
      }
    }
  }

  &__resources-list-wrapper {
    max-width: 752px;
  }

  &__resources-table {
    width: 100%;
  }

  &__resource-item {
    color: var(--Czarny-500, #111);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    border-radius: 4px;
    background: #fff;
    min-height: 56px;
    padding: 12px 20px;
  }

  &__resource-item-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px !important;
    .switcher .wrapper {
      height: unset;
    }
    .switcher .checkbox-wrapper {
      justify-content: flex-end;
    }
  }

  &__resource-all {
    margin-top: 32px;
    margin-bottom: 20px;
  }

  &__resource-item-name {
    h2 {
      display: flex;
      align-items: center;
    }
  }

  &__resource-item-version {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__resource-item-update {
    border-radius: 50px;
    background: rgba(255, 195, 128, 0.5);
    color: var(--Pomarańczowy-500, #f29225);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    padding: 0 15px;
    height: 32px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    display: none;
    &--active {
      display: flex;
    }
    p {
      @media (max-width: 1000px) {
        display: none;
      }
    }
    svg {
      width: 20px;
      height: 20px;
    }
    @media (max-width: 1000px) {
      padding: 0;
      height: 32px;
      width: 32px;
    }
  }
}
