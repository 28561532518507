.contactFormHelp {
  max-width: 490px;
  &__successInfo {
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
    font-weight: 500;
    color: #38d58b;
    transition: 0.4s;
    @media (max-width: 380px) {
      align-items: center;
    }
    svg {
      width: 15px;
      height: 17px;
      margin-right: 5px;
    }
  }
  &__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.192);
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 380px) {
      p {
        width: 70px;
        text-align: right;
      }
    }
  }
  &__heading {
    font-size: 30px;
    font-weight: 900;
    color: #3b3b3b;
    display: flex;
    align-items: center;
    svg {
      height: 100%;
      width: 30px;
      margin-left: 5px;
    }
  }
  &__paragraph {
    font-size: 14px;
    padding: 20px 0;
    color: #242424;
    opacity: 0.81;
  }
  .form__field {
    max-width: 100%;
    textarea {
      height: 140px;
    }
    padding-bottom: 35px;
  }
}
