.contact {
  background-color: #f2f2f2;
  .mainHeading {
    max-width: 457px;
  }
  .section--under-header {
    max-width: 457px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 48px;
  }

  &__list-item {
    display: flex;
    border-radius: 16px;
    background: #fff;
    gap: 21px;
    padding: 12px 16px;
    align-items: center;
    text-align: left;
    color: var(--Szary-700, #404040);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    a {
      text-decoration: none;
      color: inherit;
    }
    .svg {
      height: 40px;
      width: 40px;
    }
  }

  &__socials {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin-top: 55px;
    color: var(--Szary-600, #666);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    gap: 16px;
    @media (max-width: 1000px) {
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }
  }

  &__socials-list {
    display: flex;
    gap: 8px;
  }

  &__socials-icon {
    height: 32px;
    display: flex;
    .svg {
      height: 32px;
      width: 32px;
      path {
        fill: #352fee;
      }
    }
  }
  &__form-wrapper {
    width: 100%;
    max-width: 559px;
    position: relative;
    margin-top: -72px;
    padding-top: 72px;
    @media (max-width: 1000px) {
      max-width: 100%;
      margin-top: -48px;
      padding-top: 96px;
    }
  }

  &__form {
    display: flex;
    padding: 40px 56px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    background: #fff;
  }

  &__corgi {
    position: absolute;
    width: 82px;
    height: 131px;
    flex-shrink: 0;
    left: -50px;
    bottom: -18px;
    z-index: 1;
    @media (max-width: 1000px) {
      top: 30px;
      right: 22px;
      left: unset;
      width: 52px;
      height: 83px;
    }
  }
  &__demo {
    padding-top: 100px;
    z-index: 1;
    background-color: white;
    clip-path: polygon(0 0, 100% 8%, 100% 100%, 0% 100%);
    position: relative;
    @media (max-width: 1000px) {
      clip-path: unset;
      padding-top: 0;
      background: unset;
      &::after {
        content: '';
        position: absolute;
        top: 117px;
        left: 0;
        width: 100%;
        height: calc(100% - 50px);
        background-color: white;
        z-index: 0;
        clip-path: polygon(0 0, 100% 50px, 100% 100%, 0% 100%);
      }
    }
  }

  &__background {
    @media (max-width: 1400px) and (min-width: 1000px) {
      top: 350px;
    }
  }

  .heroSection {
    @media (max-width: 1000px) {
      padding-top: 72px;
    }
  }
}
