.Countdown {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 70px;
  &-title {
    width: 100%;
    text-align: center;
    color: #858585;
    opacity: 0.5;
    font-weight: $weight__bold;
    font-size: 20px;
    margin-bottom: 20px;
  }
  &-col {
    margin: 7px 5px;
    max-width: 60px;
    @include md {
      max-width: 94px;
      margin: 0 14px !important;
    }
    &-element strong {
      background-image: url('../../Image/number.png');
      background-size: contain;
      background-repeat: no-repeat;
      // display: flex;
      font-size: 24px;
      font-family: 'Roboto Mono', sans-serif;
      font-weight: 700;
      justify-content: space-between;
      opacity: 0.7;
      color: #262626;
      width: 60px;
      letter-spacing: 18px;
      padding-left: 6px;
      @include md {
        width: 94px;
        font-size: 38px;
        letter-spacing: 29px;
        padding-left: 11px;
      }
    }
    &-element span {
      font-size: 12px;
      color: #858585;
      opacity: 0.4;
      font-weight: $weight__bold;
      margin: 0;
    }
  }
  &-col-element {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    &-blue {
      color: rgba(53, 47, 238, 1) !important;
      opacity: 1 !important;
    }
    &-img::after {
      content: '';
      background-image: url('../../Image/sitdown.png');
      background-size: cover;
      position: absolute;
      right: -30px;
      width: 36px;
      height: 60px;
      transform: scaleX(-1);
    }
  }
}

.section__r {
  .Countdown-col-element-img {
    &:after {
      background-image: none;
    }
  }
  .Countdown {
    margin-top: 80px;
    margin-bottom: 0;
    margin-right: -30px;
    justify-content: center;
    @include md {
      justify-content: flex-end;
    }
    &-col:nth-child(5n) {
      margin-right: 0 !important;
    }
    &-title {
      max-width: 530px;
      @include md {
        margin-right: -28px;
      }
    }
  }
}
