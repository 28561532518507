.checkerList {
    &__pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        &--bottom {
            margin-top: 30px;
        }
        &--top {
            margin-bottom: 30px;
        }
    }
    &--popup {
        max-width: 350px;
    }
    .container {
        padding: 50px 0;
    }
    &__table {
        display: flex;
        justify-content: flex-end;
    }
    &__row {
        &:hover {
            background-color: rgba(223, 223, 223, 0.55);
        }
    }
    &__cell {
        padding: 8px 10px
    }
    &--add-phone {
        cursor: pointer;
    }
    .btn {
        font-size: 13px;
        border-radius: 5px;
        padding: 6px 20px;
        margin-top: 20px;
    }
    &--phone-cell {
        display: flex;
        svg {
            margin-left: 10px;
            path{
                fill: #352fee;
            }
        } 
    }
}