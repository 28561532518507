.infoCounter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33%;
  cursor: pointer;
  margin: 0 5px;
  &--error:hover {
    background-color: rgba(255, 64, 24, 0.1) !important;
  }
  &--success:hover {
    background-color: rgba(56, 213, 139, 0.09) !important;
  }
  &--yellow:hover {
    background-color: rgba(244, 165, 63, 0.091) !important;
  }
  &__number {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
    &--error {
      color: #fc5024;
    }
    &--success {
      color: #38d58b;
    }
    &--yellow {
      color: rgba(244, 165, 63, 1);
    }
  }
  &:hover {
    background-color: rgba(105, 98, 96, 0.1);
    border-radius: 12px;
    transition: 0.4s;
    .badge {
      transition: 0.3s;
      background-color: transparent;
    }
  }
  @media (max-width: 768px) {
    margin: 25px 0;
  }
  .badge {
    justify-content: center;
    // margin: 0 5px;
    @media (max-width: 1407px) {
      font-size: 8px;
    }
  }
}
