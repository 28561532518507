.tablePayment {
  display: grid;
  grid-template-columns: 50px 2fr 0.7fr 0.7fr 0.5fr 0.5fr 0.5fr;
  padding: 15px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.82);
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 2fr 0.7fr 0.5fr 0.5fr 0.5fr;
    &__site {
      font-weight: $weight__bold;
    }
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'site site client client'
      'nr nr download download'
      'price price card card';
    &__site {
      grid-area: site;
    }
    &__client {
      grid-area: client;
    }
    &__facture {
      &--nr {
        grid-area: nr;
      }
      &--download {
        grid-area: download;
      }
    }
    &__price {
      grid-area: price;
    }
    &__card {
      grid-area: card;
    }
  }
  &--firstLine {
    font-size: 12px;
    color: #1a1a1a;
    text-transform: lowercase;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__item {
    background-color: white;
    border-radius: 5px;
    margin: 4px 0;
    @media (max-width: 1024px) {
      border-radius: 0;
      div {
        padding: 6px 0;
      }
    }
  }
  &__id {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.82);
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &--loader {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader div {
      width: 50px !important;
      height: 50px !important;
    }
  }
}
