.soon {
  display: flex;
  background-color: gray;
  height: calc(100vh - 60px);
  background-image: url('../../Image/background_v2.svg');
  width: 100%;
  justify-content: center;
  align-items: center;
  background-position-y: -100px;
  @include lg {
    justify-content: unset;
    align-items: unset;
  }
  &__wrapper {
    margin-left: -64px;
    .systemUsers__r {
      width: 100%;
      &__wrapper {
        max-width: 290px;
        width: 100%;
        margin-right: -340px;
        position: absolute;
        right: 40%;
        @media (max-width: 1024px) {
          max-width: 100%;
          margin-right: 0;
          right: 0;
        }
      }
    }
  }
  &__content {
    width: 340px;
    display: flex;
    padding-top: 50px;
    z-index: 99;
    @include lg {
      margin-top: 10%;
      margin-left: 20%;
    }
    @media (max-width: 1024px) {
      padding-left: 50px;
      padding-top: 0;
    }
  }
  &__logo {
    display: none;
    width: 72px;
    height: 36px;
    position: absolute;
    top: 100px;
    @include lg {
      display: block;
    }
  }
  &__r {
    margin-left: 20px;
    h1 {
      font-size: 34px;
      font-weight: $weight__bold;
      letter-spacing: -1px;
      line-height: 42px;
      @include md {
        font-size: 40px;
      }
    }
    span {
      font-size: 17px;
      margin: 30px 0 65px;
      display: block;
      @include md {
        font-size: 20px;
      }
    }
    button {
      font-size: 13px;
    }
  }
  &__l svg {
    height: 90px;
  }
}
