.websiteChecker {
  padding-top: 0px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  $error: #e20d0d;
  $success: #38d58b;
  $warning: rgba(242, 146, 37, 1);
  font-family: 'Poppins';
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;

  &__tile {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    margin-left: 16px;
    margin-right: 16px;
    @media (max-width: 1266px) {
      width: unset;
    }
    &:nth-child(n + 2) {
      margin-top: 24px;
    }
    &--name {
      padding: 35px;
      position: relative;
      background-color: white;
      @media (max-width: 1266px) {
        padding-bottom: 29px;
      }
      .paragraph {
        max-width: 312px;
        text-align: left;
        margin-bottom: 65px;
        @media (max-width: 1266px) {
          text-align: center;
        }
        span {
          font-weight: 700;
        }
      }
    }
    &--report {
      padding: 63px 30px;
    }
    &--success {
      margin-bottom: 40px !important;
    }

    .paragraph {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
    }
    .iconRight {
      position: absolute;
      right: -50px;
      bottom: -17px;
      @media (max-width: 1266px) {
        right: 15px;
        bottom: -47px;
        display: none;
      }
      &--woof {
        right: -80px;
        @media (max-width: 1300px) {
          right: 10px;
        }
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  }
  .progressBar {
    background-color: #d9d9d9;
    border-radius: 20px;
    margin-top: 120px;
  }
  .container {
    width: 100%;
    margin: 165px 125px 50px 125px;
    max-width: 1139px;
    min-height: 800px;
    @media (max-width: 769px) {
      min-height: 600px;
      margin-bottom: 150px;
    }
    &--center {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__title--wrapper {
    margin-top: 40px;
    display: flex;
    max-width: 750px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: -35px;
    @media (max-width: 1266px) {
      margin-top: 0;
      margin-bottom: -29px;
      flex-direction: column;
    }
  }
  .websiteName {
    background: #f2f2f2;
    border-radius: 30px;
    max-width: 750px;
    width: 100%;
    padding: 14px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-right: 20px;
    form {
      width: 100%;
    }
    svg {
      width: 24px;
      height: 24px;
    }
    &--click {
      cursor: pointer;
      min-height: 55px;
    }
    &__url {
      font-weight: 800;
      font-size: 18px;
      line-height: 28px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      // word-break: break-all;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: clip;
    }
    &__icon {
      left: 15px;
      top: 15px;
      position: absolute;
    }
    &__icon-edit {
      right: 15px;
      top: 15px;
      position: absolute;
    }
    .form__field {
      padding: 0 !important;
      background-color: unset;
      input {
        background: unset;
        box-shadow: unset;
        height: auto;
        padding: 0;
        width: 100%;
        padding-left: 0 !important;
        text-align: center;
      }
    }
  }
  .reportLabel {
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    margin-top: 56px;
    margin-bottom: 24px;
    @media (max-width: 1266px) {
      text-align: center;
    }
    text-align: left;
    &--warning {
      color: $warning;
    }
    &--error {
      color: $error;
    }
    &--success {
      color: $success;
    }
  }
  .report {
    display: flex;
    max-width: 750px;
    width: 100%;
    padding-bottom: 64px;
    border-bottom: 0.5px solid #a6a6a6;
    z-index: 2;
    @media (max-width: 769px) {
      align-items: center;
      flex-direction: column;
    }
    &--left,
    &--right {
      width: 50%;
      @media (max-width: 769px) {
        width: 100%;
      }
    }
    &--right {
      @media (max-width: 769px) {
        margin-top: 40px;
        padding-left: 20px;
      }
    }
    &--left {
      @media (max-width: 769px) {
        display: flex;
        justify-content: center;
      }
    }
    &__levelLabel {
      font-weight: 800;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.0025em;
      color: #404040;
      margin-bottom: 38px;
    }
    &__level {
      display: flex;
      align-items: center;
      &--paragraph {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
      }
      &:nth-child(n + 2) {
        margin-top: 16px;
      }
    }
    &__dot {
      width: 24px;
      height: 24px;
      border-radius: 20px;
      margin-right: 16px;
      &--success {
        background-color: $success;
      }
      &--warning {
        background-color: $warning;
      }
      &--error {
        background-color: $error;
      }
    }
    &__error {
      display: flex;
      align-items: flex-start;
      padding: 40px 0;
      max-width: 750px;
      width: 100%;
      justify-content: space-between;
      border-bottom: 0.5px solid #a6a6a6;
      @media (max-width: 769px) {
        flex-direction: column;
      }
      &--paragraph {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #404040;
        display: flex;
        align-items: center;
        max-width: 50%;
        width: 100%;
        @media (max-width: 769px) {
          max-width: 100%;
        }
      }
      .svg {
        margin-right: 20px;
        flex-shrink: 0;
      }
    }
    &__link {
      color: #3832ec;
      width: 100%;
      @media (max-width: 769px) {
        padding-left: 40px;
        margin-top: 16px;
        line-break: anywhere;
      }
    }
    &__usersList {
      margin-left: 25px;
      width: 100%;
      list-style: disc;
      color: #3832ec;
      @media (max-width: 769px) {
        margin-left: 60px;
        margin-top: 16px;
      }
    }
  }
  .checkSite--form {
    max-width: 750px;
    width: 100%;
    position: relative;
    .progressBar {
      margin-top: 60px;
    }
    input {
      color: #666666;
      background: #f2f2f2;
      font-size: 16px;
      line-height: 151%;
      border: none;
      box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.05);
    }
  }
  .tiles {
    max-width: 760px;
    display: flex;
    flex-direction: column;
    z-index: 9;
    margin-top: 280px;
    @media (max-width: 1500px) {
      margin-top: 170px;
      margin-bottom: 150px;
    }

    @media (max-width: 1000px) {
      right: 0px;
      margin-left: 20px;
      margin-right: 20px;
    }
    @media (max-width: 769px) {
      margin-bottom: 280px;
      margin-top: -10px;
    }
    &--paws {
      z-index: 99;
      right: -270px;
      bottom: -150px;
      position: absolute;
      @media (max-width: 769px) {
        display: none;
      }
    }
    &--background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
    }
    &__heading {
      display: flex;
      align-items: flex-start;
      @media (max-width: 769px) {
        flex-direction: column-reverse;
        text-align: center;
      }
      .left {
        display: flex;
        flex-direction: column;
        @media (max-width: 700px) {
          text-align: center;
        }
      }
      .right {
        display: flex;
        align-items: flex-start;
        @media (max-width: 769px) {
          width: 100%;
          justify-content: center;
          margin-bottom: 16px;
        }
        svg {
          max-height: 128px;
          max-width: 185px;
        }
      }
    }
    &--heading {
      font-weight: 800;
      font-size: 24px;
      line-height: 36px;
      color: #404040;
      margin-bottom: 24px;
    }
    &--paragraph {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      margin-bottom: 71px;
      max-width: 500px;
      @media (max-width: 700px) {
        text-align: center;
      }
    }
    &__item {
      max-width: 330px;
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;
      width: 100%;
      position: relative;
      @media (max-width: 700px) {
        align-items: center;
      }
      svg {
        margin-bottom: 8px;
      }
      &--heading {
        color: #404040;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.0025em;
        margin-bottom: 8px;
        @media (max-width: 700px) {
          text-align: center;
        }
      }
      &--paragraph {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        @media (max-width: 700px) {
          text-align: center;
        }
      }
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 769px) {
        justify-content: center;
      }
      @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .bottom {
    display: flex;
    z-index: 9;
    position: relative;
    min-height: 616px;
    margin: 90px 0 20px;
    @media (max-width: 1000px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: 769px) {
      flex-direction: column;
      margin-bottom: -120px;
    }
    &--background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    &__wrapper {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      width: 100%;
      background-color: #fff;
      overflow: hidden;
      @media (max-width: 1500px) {
        margin-top: -110px;
      }
      @media (max-width: 769px) {
        margin-top: -220px;
        overflow: unset;
      }
    }
    &__content {
      max-width: 940px;
      width: 100%;
    }
    &__r,
    &__l {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__r .btn--wrapper {
      margin-top: 48px;
      .btn {
        padding: 10px 50px;
      }
    }
    &__l {
      margin-right: 32px;
      @media (max-width: 769px) {
        margin-right: 0;
        align-items: center;
        margin-top: -180px;
        z-index: 99999;
        .svg {
          max-width: 220px;
          max-height: 220px;
        }
      }
    }
    &--heading {
      font-weight: 800;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      margin: 24px 0;
      max-width: 369px;
      @media (max-width: 769px) {
        text-align: center;
      }
    }
    &--paragraph {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #f2f2f2;
      max-width: 427px;
      @media (max-width: 769px) {
        text-align: center;
      }
    }
    .btn--wrapper {
      .btn {
        padding: 14px 50px;
        @media (max-width: 769px) {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
  &__bg {
    position: absolute;
    margin-top: 200px;
  }
  &--success {
    position: relative;
  }
  &__success {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    @media (max-width: 769px) {
      margin-left: 16px;
      margin-right: 16px;
      .btn--wrapper {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .btn {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    &--heading {
      max-width: 457px;
      font-weight: 900;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: -0.01em;
      color: #38d58b;
      text-align: center;
      @media (max-width: 769px) {
        margin-top: 30px;
      }
    }
    &--email {
      font-weight: 800;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.0025em;
      color: #404040;
      margin-top: 40px;
    }
    &--padding {
      max-width: 350px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #666666;
      margin-top: 24px;
    }
    .btn {
      margin-top: 30px;
    }
  }
  &__loadingUrl {
    // background: unset;
    box-shadow: unset;
  }
}
.CircularProgressbar {
  width: 235px;
  height: 235px;
  &-text {
    font-weight: 700;
    font-family: 'Poppins';
    font-size: 30px !important;
  }
}

.checker-register {
  padding: 0;
  @media (max-width: 769px) {
    overflow: unset;
  }
  &__content {
    position: relative;
    padding: 47px 73px;
    @media (max-width: 769px) {
      padding: 73px 17px 33px 17px;
    }
  }
  .image {
    max-width: 168px;
    max-height: 155px;
    position: absolute;
    left: -75px;
    top: -10px;
    @media (max-width: 769px) {
      top: -70px;
      left: calc(50% - 70px);
    }
  }
  .heading {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
    color: #000000;
    @media (max-width: 769px) {
      text-align: center;
    }
  }
  .paragraph {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #808080;
    margin-bottom: 32px;
    @media (max-width: 769px) {
      text-align: center;
    }
  }
  &--rodo {
    font-weight: 400;
    font-size: 10px !important;
    line-height: 15px !important;
    color: #808080;
    padding-left: 40px;
  }
  .expand-text--button {
    justify-content: flex-start;
    text-transform: capitalize;
    font-size: 12px;
    padding-left: 40px;
  }
  .checkbox__rule span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding-top: 8px;
    color: #666666 !important;
    a {
      font-weight: 500;
      color: #666666;
      text-decoration: underline;
    }
  }
  .checkbox__rule .checkmark {
    border: 2px solid #bfbfbf;
  }
  .form__field {
    @media (max-width: 769px) {
      width: 100%;
      max-width: unset;
    }
  }
  .popUp--form-l input {
    width: 100%;
  }
  .svg--eye {
    margin-bottom: 1px !important;
  }
}

.livePreview {
  width: 300px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.18);
  @media (max-width: 1266px) {
    height: 280px;
    width: 100%;
  }
  iframe {
    background: lightgray 0px -0.336px / 100% 396.472% no-repeat;
    pointer-events: none;
    width: 1440px;
    height: 500%;
    transform: scale(0.2083);
    transform-origin: 0 0;
    @media (max-width: 1266px) {
    }
  }
}
