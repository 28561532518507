.faq {
  padding-top: 0px;
  margin-top: -80px;
  background-color: #2d2d2d;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &-wraps {
    cursor: pointer;
  }
  &__container {
    width: 100%;
    margin: 145px;
    max-width: 996px;
    @media (max-width: 1300px) {
      margin: 125px 90px 125px 90px;
    }
    @media (max-width: 1000px) {
      margin-top: 150px;
    }
    @media (max-width: 800px) {
      margin-left: 0;
      margin-right: 0;
      padding: 0 20px;
    }
    h1 {
      font-size: 154px;
      font-weight: $weight__max;
      color: #ffffff;
      opacity: 0.14;
      margin-right: 40px;
      display: flex;
      align-items: center;
      @media (max-width: 1600px) {
        font-size: 140px;
      }
      @media (max-width: 800px) {
        font-size: 110px;
      }
      @media (max-width: 550px) {
        font-size: 90px;
      }
      @media (max-width: 500px) {
        font-size: 63px;
      }
      @media (max-width: 400px) {
        font-size: 53px;
      }
    }
  }
  &--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 84px;
    align-items: center;
    @media (max-width: 500px) {
      margin-bottom: 40px;
      svg {
        width: 133px;
        height: 68px;
      }
    }
  }
  &--head {
    color: #f1f1f1;
    opacity: 0.87;
    font-size: 21px;
    font-weight: $weight__bold;
    padding: 21px 280px 21px 27px;
    background-color: rgba(255, 255, 255, 0.08);
    position: relative;

    @media (max-width: 900px) {
      padding: 21px 100px 21px 27px;
    }
    @media (max-width: 550px) {
      padding-right: 85px;
    }
    @media (max-width: 550px) {
      padding-right: 65px;
    }
    svg {
      position: absolute;
      right: 30px;
      height: 20px;
      width: 20px;
      top: 40%;
      opacity: 0.6;
    }
    .up {
      opacity: 0.9;
      transform: rotate(180deg);
    }
  }
  &--descript {
    color: rgba(241, 241, 241, 0.6);
    font-size: 17px;
    background-color: rgba(255, 255, 255, 0.02);
    margin-bottom: 9px;
    overflow: hidden;
    max-height: 0;
    transition: 0.4s;
    p {
      padding: 26px 28px;
    }
    &--active {
      max-height: 800px;
    }
  }
  // .others__contact--corgiLine {
  //   margin-bottom: -600px;
  //   position: absolute;
  //   // @media (max-width: 429px) {
  //   //   margin-bottom: -600px;
  //   // }
  //   // @media (max-width: 400px) {
  //   //   margin-bottom: -750px;
  //   // }
  //   // @media (max-width: 315px) {
  //   //   margin-bottom: -900px;
  //   // }
  // }
  &__contact {
    width: 100%;
    overflow: hidden;
    position: relative;
    .others__contact {
      padding-top: 100px;
    }
  }
}
