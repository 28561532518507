.dragdrop {
  &--delete-modal {
    max-width: 375px;
    padding: 21px 24px;
    .heading {
      margin-bottom: 47px;
    }
    .paragraph {
      margin-bottom: 53px;
    }
    .buttons {
      width: 100%;
      justify-content: space-between;
      .btn--wrapper {
        width: 100%;
        &:first-child {
          margin-right: 10px;
        }
      }
      .btn {
        width: 100%;
      }
    }
  }
}