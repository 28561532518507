.homepage,
.home-page {
  background-color: #fff;
  margin-top: 64px;
  &__main-logo {
    max-height: 472px;
    width: auto;
    z-index: 1;
    @media (max-width: 1000px) {
      margin-top: 89px;
      margin-left: -13%;
      max-width: calc(70% - 40px);
    }
  }
  .section__r {
    @media (max-width: 1000px) {
      margin-top: 64px;
    }
  }
  .banner {
    @media (max-width: 1000px) {
      flex-direction: column;
      margin: 0;
    }
  }
  .section {
    padding: 0;
    margin-inline: 16px;
    @media (max-width: 1000px) {
      padding: 0 16px;
      margin-top: 0;
      margin-inline: 0px;
    }

    &__c {
      margin-top: unset;
    }

    &--no-margin {
      margin-inline: 0;
      padding: 0;
    }

    &--center {
      justify-content: center;
    }

    &--row {
      flex-direction: row;
    }

    &--column {
      flex-direction: column;
    }

    &--hero {
      margin-top: 0;
      margin-bottom: 0;

      @media (max-width: 1000px) {
        margin-top: -260px;
      }
    }
    &--who-earns {
      margin-top: 0;
      padding-top: 160px;
      @media (max-width: 1000px) {
        margin-bottom: -120px;
        padding-top: 0;
      }
      .homepage__subtitle--one-tool {
        padding-top: 50px;
        @media (max-width: 1000px) {
          padding-top: 120px;
        }
      }
    }

    &--gray {
      background: #f2f2f2;
      padding-bottom: 200px;
    }

    &--gray-small-margin {
      padding-bottom: 40px;
    }

    &--relative {
      display: flex;
      justify-content: center;
      .homepage__section-content {
        position: relative;
        width: 1136px;
        max-width: calc(100vw - 32px);
      }
    }
  }
  .headingButton {
    @media (max-width: 1000px) {
      max-width: max-content;
      height: 48px;
      margin-right: auto;
    }
  }
  .section__wrapper--blue {
    z-index: 0;
  }
  .section--background {
    @media (max-width: 1000px) {
      top: unset;
      bottom: 200px;
      .section--background-shields {
        height: 400px;
      }
    }
  }
  .section--under-header {
    max-width: 353px;
  }

  .mainHeading {
    margin-top: 0;
    .paragraph {
      display: inline-block;
    }
    .typewrite {
      display: unset;
    }
  }
  .section__wrapper--gray {
    background: #fff;
  }

  &__subtitle {
    color: var(--Czarny-300, #333);
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 111.111% */
    letter-spacing: -0.36px;

    @media (max-width: 1000px) {
      font-size: 24px;
      line-height: 30px;
    }

    &--big {
      font-size: 48px;
      line-height: 56px;

      @media (max-width: 1000px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &--black {
      color: #262626;
    }

    &--partner {
      max-width: 576px;
    }

    &--how-it-works {
      @media (min-width: 1000px) {
        max-width: 380px;
      }
    }

    &--white {
      color: #fff;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 56px; /* 116.667% */
      letter-spacing: -0.96px;
      @media (max-width: 1000px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &--paws {
      max-width: 442px;
    }

    &--one-tool {
      max-width: 555px;
    }

    &--mg-b-24 {
      margin-bottom: 24px;
    }
  }

  &__description {
    color: var(--Szary-700, #404040);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    @media (max-width: 1000px) {
      font-size: 14px;
      line-height: 20px;
    }

    &--white {
      color: var(--Szary-100, #f2f2f2);
    }

    &--one-tool {
      margin-top: 40px;
      margin-bottom: 56px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 556px;
    gap: 24px;
    margin-bottom: 64px;
    @media (max-width: 1000px) {
      justify-content: center;
    }
  }
  &__list-item {
    width: calc(50% - 12px);
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 1000px) {
      width: 100%;
      &:nth-child(1) {
        order: 0;
      }
      &:nth-child(2) {
        order: 3;
      }
      &:nth-child(3) {
        order: 1;
      }
      &:nth-child(4) {
        order: 4;
      }
      &:nth-child(5) {
        order: 2;
      }
      &:nth-child(6) {
        order: 5;
      }
    }
    color: var(--Szary-100, #f2f2f2);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    b {
      font-weight: 700;
    }
    .svg {
      min-width: 32px;
    }
  }

  &__features.section {
    max-width: 459px;
    margin: 0;
    @media (max-width: 1000px) {
      max-width: 100%;
      padding: 0;
    }
  }

  &__feature-wrapper {
    gap: 120px;

    svg,
    img {
      width: 100%;
      max-width: 600px;
      @media (max-width: 1000px) {
        margin-bottom: 120px;
      }
    }
    @media (max-width: 1000px) {
      gap: 72px;
      flex-direction: column-reverse !important;
      &:nth-child(even) {
        flex-direction: column !important;
      }
    }
    &--backup {
      @media (max-width: 1000px) {
        img {
          margin-bottom: 0;
        }
      }
    }
  }

  &__features-container {
    gap: 120px;
    flex-direction: column;
    margin-top: 150px;
    @media (max-width: 1000px) {
      margin-top: 200px;
      gap: 0;
    }
  }

  &__one-tool {
    display: flex;
    background-color: #f2f2f2;
    padding-top: 70px;
    padding-bottom: 70px;
    width: 100%;
    .section {
      max-width: 50%;
    }
    img {
      max-width: 769px;
      @media (max-width: 1000px) {
        margin-right: -76px;
      }
    }

    @media (max-width: 1000px) {
      padding-top: 60px;
      padding-bottom: 0;
      flex-direction: column-reverse;
      margin-bottom: -200px;
      .section {
        max-width: 100%;
      }
    }
  }

  &__one-tool-image {
    display: flex;
    align-items: flex-end;
  }

  &__section-content {
    max-width: 1136px;
    width: 100%;
    z-index: 1;
  }
  &__paws {
    display: flex;
    gap: 24px;
    margin-top: 120px;
    padding-bottom: 156px;
    justify-content: space-between;
    @media (max-width: 1000px) {
      margin-top: 60px;
      flex-direction: column;
      align-items: center;
      gap: 72px;
    }
  }

  &__paw {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    @media (max-width: 1000px) {
      margin-bottom: 24px;
      gap: 24px;
    }
    .svg {
      height: 196px;
      path:nth-child(1) {
        fill: #c3c1ff;
      }
    }
  }

  &__paw-bottom {
    border-radius: 10px;
    background: #c3c1ff;
    color: var(--Niebieski-500, #352fee);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    padding: 16px 30px;
    b {
      font-weight: 700;
    }
    &--1 {
      max-width: 222px;
    }
    &--2 {
      max-width: 236px;
    }
    &--3 {
      max-width: 259px;
    }
  }

  &__background-paws {
    position: absolute;
    bottom: -100px;
    right: 0;
    z-index: 1;

    path {
      fill: rgba(217, 217, 217, 0.4);
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }

  &__background-shield {
    position: absolute;
    bottom: -200px;
    right: 0;
    z-index: 1;
    transform: rotate(-8deg);
    right: -20%;
  }

  &__background-corgi-sit-down {
    position: absolute;
    bottom: -150px;
    right: -100px;
    z-index: 2;
    width: 182.647px;
    height: 263.565px;
    @media (max-width: 1000px) {
      right: 35%;
      transform: translateX(50%);
    }
  }

  &__earn-most {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 120px;
    margin-top: 48px;
    padding-bottom: 40px;
    @media (max-width: 1000px) {
      padding-bottom: 180px;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    .svg--corgi-shield {
      width: 113px;
      z-index: 1;
    }
    .svg--money {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 1000px) {
        height: 100%;
      }
    }
  }

  &__earn-most-left {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100%;
    @media (max-width: 1000px) {
      width: 100%;
      padding-bottom: 100px;
    }
  }

  &__earn-most-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    h3 {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.045px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    p {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }

  &__earn-most-list-item {
    max-width: calc(50% - 12px);
    border-radius: 10px;
    background: var(--Niebieski-600, #1913bf);
    padding: 24px 32px;
    @media (max-width: 1000px) {
      max-width: 100%;
      padding: 32px;
    }
  }

  &__demo {
    max-width: 459px;
    img {
      margin-bottom: 40px;
      height: 230px;
    }
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        width: 100%;
      }
    }
  }

  &__demo-description {
    color: var(--Szary-700, #404040);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  &__calendry {
    width: 100%;
    max-width: 604px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    .calendly-inline-widget {
      border-radius: 10px;
      width: 604px !important;
      max-width: 100% !important;
      height: 927px !important;
      iframe {
        border-radius: 10px;
      }
      @media (max-width: 1000px) {
      }
    }
  }

  &__calendry-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 120px;
    align-items: center;
    padding-bottom: 150px !important;
    @media (max-width: 1000px) {
      padding-bottom: 96px !important;
      flex-direction: column-reverse;
      margin-top: -280px;
      gap: 72px;
    }
  }

  &__reviews {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 140px;
    @media (max-width: 1000px) {
      margin-top: 60px !important;
      margin-bottom: 0px;
    }
  }
  &__reviews-wrapper {
    margin-top: 150px;
  }
  .section__wrapper-margin .section {
    @media (max-width: 1000px) {
      padding-top: 60px;
    }
  }

  &__gray {
    background-color: #f2f2f2;
    position: absolute;
    width: 116%;
    height: 110%;
    transform: rotate(8deg);
    z-index: 1;
    overflow: hidden;

    @media (max-width: 1023px) {
      width: 200%;
      height: 100%;
    }
  }

  .homepage__how-it-works {
    margin-bottom: -80px;
    padding-bottom: 80px;
    @media (max-width: 1000px) {
      padding-top: 10px;
    }
    & > .homepage__section-content {
      @media (max-width: 1000px) {
        padding-top: 50px;
      }
    }
    .homepage__subtitle {
      @media (min-width: 1000px) {
        margin-bottom: 64px;
      }
    }
  }

  &__how-it-works-switch {
    display: flex;
    gap: 0px;
    margin-top: 56px;
    overflow: auto;
    max-width: 100%;
    @media (max-width: 1023px) {
      margin-top: 36px;
    }
  }

  &__swich-item {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 20px;
    transition: 0.3s;
    cursor: pointer;
    gap: 8px;
    border-radius: 50px;
    white-space: nowrap;
    .svg {
      transition: 0.3s;
      opacity: 0;
      width: 24px;
      min-height: 24px;
      path {
        stroke: #fff;
      }
    }

    &--active {
      background: var(--Czarny-300, #333);
      color: #fff;
      .svg {
        opacity: 1;
      }
    }
  }

  &__how-it-works-indicator {
    color: var(--Pomaraczowy-500, #f29225);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px; /* 125% */
    @media (max-width: 1000px) {
      font-size: 18px;
      font-weight: 800;
      line-height: 26px;
      letter-spacing: -0.045px;
      margin-top: -3px;
    }
  }

  &__how-it-works-list-item {
    border-radius: 10px;
    background: #fff;
    padding: 32px;
    gap: 32px;
    display: flex;
    cursor: pointer;
    @media (max-width: 1000px) {
      gap: 12px;
    }
    h2 {
      color: var(--Czarny-500, #111);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
      @media (max-width: 1000px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    p {
      color: var(--Szary-600, #666);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s;

      @media (max-width: 1000px) {
        transition: 0.6s;
      }
    }

    &--active {
      cursor: default;
      p {
        max-height: 300px;
      }
      .homepage__how-it-works-chevron {
        svg {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__how-it-works-chevron {
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }

  &__how-it-works-click {
    font-weight: 700;
    text-decoration-line: underline;
    color: inherit;
  }

  &__how-it-works-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__how-it-works-list-item-image {
    display: none;
    @media (max-width: 1000px) {
      max-width: 100%;
      margin-bottom: 40px;
      margin-top: 32px;
      &--active {
        display: block;
      }
    }
  }

  &__how-it-works-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 32px;
    max-width: 556px;
    width: 100%;
  }

  &__how-it-works-image {
    width: 100%;
    img {
      max-width: 100%;
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }

  &__how-it-works-container {
    display: flex;
    gap: 86px;
    padding-bottom: 12px;
    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 40px;
    }
  }

  &__paws-wrapper.section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
