.cookiesAgreement {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 214px;
  width: 100%;
  background-color: #000000;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 134px;
  z-index: 999999;

  &__icon {
    img {
      width: 70px;
      height: 70px;
    }
    margin-right: 50px;
  }
  &__text {
    font-size: 16px;
    p {
      font-weight: 400;
      color: #ffffff;
      line-height: 25px;
      max-width: 1100px;
      a {
        // font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        text-decoration: underline;
      }
    }
    margin-right: 100px;
  }
  &__button {
    min-width: 190px;
  }
  @media (max-width: 1200px) {
    padding: 0 50px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    height: max-content;
    padding: 30px 31px;
    &__icon {
      align-self: flex-start;
      margin: 10px 0 10px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    &__text {
      font-size: 12px;
      margin-bottom: 30px;
      margin-right: 0;
    }
  }
}
