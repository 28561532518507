.popUpEvents {
  max-width: 375px;
  padding: 17px;

  &__head {
    font-size: 17px;
    font-weight: $weight__bold;
    margin-bottom: 30px;
  }
  &__content {
    display: flex;
    &-php {
      padding: 30px;
    }
  }
  &__iconWrap {
    margin-left: 5px;
  }
  &__info {
    font-size: 12px;
    margin-left: 30px;
    width: 100%;
    &-top {
      border-bottom: 1px solid rgba(112, 112, 112, 0.081);
      padding-bottom: 10px;
    }
    &-mid {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-bottom {
      color: rgba(9, 9, 9, 0.28);
      margin-top: 36px;
    }
    &--gray {
      color: rgba(9, 9, 9, 0.28);
      margin-left: 10px;
    }
    &--bold {
      font-weight: $weight__max;
    }
  }
  &__buttons {
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .btn {
      min-width: 143px;
    }
    .btn--wrapper:nth-child(2) .btn {
      display: flex;
      justify-content: center !important;
    }
  }
  &--backup {
    .popUpEvents__info-mid,
    .popUpEvents__info-bottom {
      margin-top: 20px;
    }
    .popUpEvents__buttons {
      margin-top: 30px;
    }
  }
  .badge {
    min-width: 100px;
    display: flex;
    justify-content: center;
  }
}


