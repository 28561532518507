.report{
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    z-index: 99999999;
    .btn-forward{
        background: #262626;
        border-radius: 36px;
        width: 101px;
        height: 23px;
        margin-top: 20px;
        font-weight: 900;
        font-size: 8px;
        line-height: 8px;
        color: #fff;
        display: flex;
        justify-content: space-around;
        gap: -10px;
        align-items: center;
        cursor: pointer;
    }
    &__link {
        cursor: pointer;
    }
    &__header{
        font-weight: 900;
        font-size: 20px;
        line-height: 30px;
        padding: 23px 224px 0 33px;    
    }
    &__subheader{
        font-weight: 900;
        font-size: 14px;
        line-height: 30px;
        padding: 33px 224px 0 53px;  
    }
    &__content{
        &--group{
            width: 640px;
            display: flex;
            justify-content: space-between;
            padding-top: 29px;
            margin: auto;
            padding-bottom: 30px;
            .tile{
                font-size: 12px;
                font-weight: 900;
                background-color: rgb(255, 255, 255);
                border-radius: 5px;
            // box-shadow: 0px 3px 11px rgb(0 0 0 / 6%);
                height: 178px;
                width: 171px;
                padding: 18px 10px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                &__up{
                    padding-top: 20px;
                    width: 70%;
                    height: 50%;
                    display: flex;
                    justify-content: center;
                    &--paragraph{
                        text-align: center;
                    }
                    &--uptime{
                        text-align: center;
                        font-size: 49px;
                        font-weight: 700;
                        color: #38D58B;
                    }
                    &--item{
                        display: flex;
                        justify-content: space-between;
                        p{
                            font-weight: 900;
                            font-size: 12px;
                            line-height: 15px;
                        }
                    }
                }
                &__up-custom{
                    padding-top: 20px;
                    width: 70%;
                    height: 50%;
                    p{
                        text-align: center;
                    }
                    &--item{
                        display: flex;
                        justify-content: space-between;
                        p{
                            font-weight: 900;
                            font-size: 12px;
                            line-height: 15px;
                        }
                    }
                }
                &__up-check{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    width: 70%;
                    height: 70%;
                    &--item{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        p{
                            font-weight: 900;
                            font-size: 12px;
                            line-height: 15px;
                        }
                        .coreItem{
                            margin-left: 15px;
                        }
                        .pluginItem{
                            margin-left: 32px;
                            position: relative;
                            right: 20px;
                        }
                    }
                }
                &__down{
                    padding-top: 20px;
                    width: 80%;
                    display: flex;
                    justify-content: center;
                    &--paragraph{
                        color: #D8D8D8;
                    }
                    &--date{
                        color: #38D58B;
                        .resetFontColor{
                            color: #D8D8D8;
                            // display: block;
                            margin: 0 10px 0 10px;
                        }
                    }
                    &--websiteName{
                        font-weight: 900;
                        font-size: 10px;
                        line-height: 15px;
                        text-align: center;
                        color: #38D58B;
                    }
                    .lighter{
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 15px;
                        text-align: center;
                    }
                    .sslExpires{
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 15px;
                        text-align: center;
                        color: #FF4018;
                    }
                }
            }

            .wideTile{
                font-size: 12px;
                font-weight: 900;
                background-color: rgb(255, 255, 255);
                border-radius: 5px;
                // box-shadow: 0px 3px 11px rgb(0 0 0 / 6%);
                height: 178px;
                width: 410px;
                padding: 18px 10px;
                position: relative;

                &__header{
                    font-weight: 900;
                    font-size: 14px;
                    line-height: 30px;
                    margin: auto;
                    text-align: center;
                }

                .table{
                    margin: 30px auto auto 20px;
                    tr{
                        height: 30px;
                        th{
                            color: #707070;
                            font-size: 7px;
                            font-weight: 700;
                            text-align: left;
                        }
                        td{
                            font-weight: 900;
                            font-size: 10px;
                            line-height: 131%;
                        }
                    }
                }
            }

            .lineBreak{
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .customFont{
                color: #38D58B;
            }
        } 

        &--chart{
            width: 640px;
            height: 140px;
        }
        &--list{
            .updatesHeader{
                font-weight: 800;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                margin: 33px 500px 33px auto;
            }        
            .notificationsHeader{
                font-weight: 800;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                margin: 33px 470px 33px auto;
            }   
        }      
    }
    &__summary {
        width: 100%;
        max-width: 750px;
        margin-top: 32px;
        .heading {
            font-weight: 800;
            font-size: 18px;
            line-height: 28px;
            color: #666666;
        }
        .paragraph {
            margin-top: 16px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #666666;
            &--row {
                display: flex;
                align-items: center;
                margin-top: 32px;

                @media(max-width: 769px) {
                    flex-direction: column;
                    span {
                        width: 100%;
                        text-align: left;
                    }
                    .btn--wrapper {
                        width: 100%;
                        margin-top: 25px;
                        .btn {
                            margin-left: 0;
                            width: 100%;
                            text-align: center;
                            justify-content: center;
                        }
                    }
                }
                .btn--blue-new {
                    margin-left: 32px;
                }
            }
        }
        .bold {
            font-weight: 700;
        }
    }
}

@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      print-color-adjust: exact;
      -webkit-print-color-adjust:exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  .separator{
    height: 50px;
  }
  @page {
    size: auto;
    margin: 20mm;
  }

@media print{
    .pagebreak { 
        clear: both;
        page-break-before: always;
    }
}  

.list{
    width: 90%;
    margin: auto;
    margin-top: 20px;
    tr{
        height: 30px;
        th{
            padding: 10px;
        }
    }
    td{
        text-align: left;
        vertical-align: auto;
        padding: 10px;
    }
    .notificationType{
        width: auto;
        display: flex;
        gap: 10px;
        justify-content: space-around;
        align-items: center;
    }
    .head{
        text-align: left;
        font-weight: 700;
        font-size: 7px;
        line-height: 131%;
        color: #707070; 
        margin-bottom: 50px;
    }
    .row{
        font-weight: 500;
        font-size: 8px;
        line-height: 131%;
        color: #000000;
        background: #FFFFFF;
        box-shadow: 0px 3px 11px rgb(0 0 0 / 6%);
        border-radius: 5px;
        margin-top: 20px;
    }
}

.sslTile{
    height: 178px;
    width: 171px;
    box-shadow: unset;
  }

  .emptyList{
    width: 90%;
    height: 43px;
    padding: 15px 10px;
    background-color: #E5E5E5;
    border-radius: 5px;
    text-align: left; 
    font-size: 8px;
    margin: auto;
  }

  .success {
    width: 43px;
    height: 13.65px;
    background: rgba(56, 213, 139, 0.15);
    border-radius: 10.5px;
    color: #38D58B;
    font-weight: 600;
    font-size: 8px;
    line-height: 131%;
  }