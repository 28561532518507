.header {
  width: 100%;
  background-color: #ffffff;
  height: 87px;
  display: flex;
  // align-items: center;
  position: fixed;
  justify-content: space-between;
  z-index: 99;
  @include lg {
    height: 60px;
    align-items: center;
    justify-content: center;
    padding-left: 100px;
  }
  &__logo {
    width: auto;
    max-height: 100%;
    display: flex;
    align-items: center;
    svg {
      max-height: 48px;
      width: auto;
      cursor: pointer;
    }
  }

  .wrap__websites-details {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    height: 60px;
    @include lg {
      justify-content: flex-end;
      padding-inline: 10px;
    }
  }
  // @media (max-width: 1024px) {
  //   background-color: transparent;
  // }
  &__hamburger {
    margin: 43px 30px 0 0;
    width: 20px;
    height: 20px;
    .svg--hamburger {
      .a {
        fill: #000;
      }
    }
  }
  .user-menu {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    min-width: 160px;
    transition: background-color 0.15s ease-in-out;
    @include lg {
      margin-top: unset;
    }
    &__header {
      height: 60px;
      display: flex;
      // justify-content: space-around;
      align-items: center;
      z-index: 2;
      cursor: pointer;
    }
    &__icon {
      width: 30px;
      height: 39px;
      margin-right: 12px;
    }
    &__fullname {
      font-family: $fontPoppins;
      font-size: 14px;
      font-weight: 700;
      margin-right: 19px;
    }
    .svg--chevron-down {
      width: 15px;
      height: 8px;
      transition: transform 0.2s ease-in;
      &.up {
        transform: rotate(180deg);
        transition: transform 0.2s ease-in;
      }
    }
    &__items {
      flex: 1;
      flex-direction: column;
      justify-content: space-around;
      display: none;
      padding: 0 0 10px;
      &--expanded {
        display: flex;
      }
    }
    &__item {
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
      &--red {
        color: #f32727;
      }
    }
    &--expanded {
      background-color: #fff;
      height: 160px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      box-shadow: 0px 3px 40px #66666629;
      transition: background-color 0.15s ease-in-out;
    }
  }
  &--logo {
    height: 84px;
    & > div {
      height: 70px;
    }
    align-items: center;
    padding-top: 14px;
    .user-menu {
      margin-top: 0;
      padding: 0;
      min-width: 140px;
      &__logo {
        padding-top: 14px;
        width: 100px;
        left: 16px;
        height: 70px;
        margin-top: 2px;
      }
    }
    .header__hamburger {
      margin: 23px 16px 0 0;
    }
  }
}
