.date-range {
  display: flex;
  flex-direction: column;
  &__label {
    color: var(--Czarny-500, #111);
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
  }

  .rdrCalendarWrapper {
    width: 332px;
  }
  &__calendar {
    width: 332px;
    position: relative;
    .rdrDay {
      height: 40px;
    }
    .rdrDayNumber {
      font-size: 14px;
    }
    .rdrMonthAndYearWrapper {
      transition: 0.3s;
      position: absolute;
      top: 60px;
      width: 332px;
      border-radius: 10px 10px 0px 0;
      background: #fff;
      box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.14);
      z-index: 2;
    }
    .rdrMonths {
      transition: 0.3s;
      position: absolute;
      top: 120px;
      width: 332px;
      border-radius: 0px 0px 10px 10px;
      background: #fff;
      box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.14);
      z-index: 2;
    }
    &--hidden {
      .rdrMonthAndYearWrapper {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
      .rdrMonths {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }
    .rdrDateDisplayWrapper {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      padding: 0;
    }
    .rdrDateDisplay {
      width: 100%;
      margin: 0;
      background-color: white;
    }

    .rdrDateDisplayItemActive {
      border: none;
    }
    .rdrDateDisplayItem {
      box-shadow: none;
      input {
        height: 28px;
        border-radius: 100px;
        background: var(--Szary-100, #f2f2f2);
        color: var(--Szary-700, #404040);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
    }
  }

  &__inputs-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    border-radius: 5px;
    background: #fff;
    padding: 12px 20px;
    cursor: pointer;
  }

  .react-daterange-picker__inputGroup {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    color: var(--Szary-700, #404040);
    border-radius: 100px;
    background: var(--Szary-100, #f2f2f2);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-daterange-picker__calendar {
    width: 100%;
    background: #fff;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.14);
    padding: 24px 16px;
    border-radius: 10px;
    margin-top: 10px;
  }

  &.react-daterange-picker {
    border-radius: 10px;
    width: 100%;
    position: unset !important;
  }
  .react-daterange-picker__button {
    display: none;
  }
  .react-daterange-picker__wrapper {
    border: none;
  }
  .react-calendar {
    border: none;
  }
  .react-daterange-picker__range-divider {
    margin-inline: 10px;
  }
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile--active {
    background: var(--Niebieski-300, #908cff);
  }
  .react-calendar__tile--rangeEnd {
    background: var(--Niebieski-300, #908cff);
    border-radius: 0px 6px 6px 0px;
    color: #fff;
  }
  .react-calendar__tile--rangeStart {
    background: var(--Niebieski-300, #908cff);
    border-radius: 6px 0px 0px 6px;
    color: #fff;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: var(--Szary-400, #a6a6a6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    abbr {
      text-decoration: none;
    }
  }

  &--open {
    .react-daterange-picker__calendar {
      position: relative !important;
      display: block;
      width: 100%;
      margin-top: 24px;
      padding: 0;
      border-radius: unset;
      box-shadow: none;
    }
  }
}
