.systemUsers {
  display: flex;
  flex-wrap: wrap-reverse;
  @include lg {
    flex-wrap: nowrap;
  }
  &__l {
    background-color: #f1f1f17e;
    width: 100%;
    min-height: 100vh;

    @media (min-width: 1024px) {
      padding-left: 60px;
      width: calc(61% + 3px);
      z-index: 9999;
      position: relative;
    }
    @media (min-width: 1520px) {
      padding-left: 220px;
    }
    &--top {
      padding-top: 30px;
      background-color: #fff;
      position: relative;
      width: calc(100% + 8px);
      &:after {
        content: '';
        width: 9999px;
        position: absolute;
        height: 100%;
        top: 0;
        right: 100%;
        background-color: #fff;
      }
    }
    &--logo {
      a {
        outline: none;
      }
      img {
        height: 36px;
        width: 72px;
      }
    }
    &--user {
      display: flex;
      margin-left: -15px;
      margin-top: 15px;
      padding-left: 10px;
      @media (min-width: 1024px) {
        margin-top: 10px;
      }
      a {
        text-decoration: none;
        color: #000;
        outline: none;
        transition-duration: 0.03s;
      }
      a div {
        padding: 15px 16px;
        font-weight: $weight__normal;
        opacity: 0.4;
        cursor: pointer;
        @media (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
    &__content--top {
      display: flex;
      margin: 20px 0;
      margin-left: -13px;
      &-active {
        border-bottom: 2px solid black;
        font-weight: $weight__bold;
      }
      div {
        margin: 4px 13px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  &__r {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 24px 40px 0;
    width: 100%;
    position: fixed;
    z-index: 9;
    top: 0;
    transition-duration: 0.2s;
    @media (max-width: 1024px) {
      height: 100px;
    }
    &--active {
      overflow-y: scroll;
      height: 100vh;
      @media (max-width: 1024px) {
        z-index: 99;
      }
      .systemUsers__r__wrapper {
        right: calc(40% - 7px) !important;
        @media (max-width: 1024px) {
          right: 0 !important;
        }
      }
    }
    &--empty {
      height: 100px;
      overflow-y: hidden;
      z-index: 9999999999;
    }
    @include md {
      width: 20%;
      height: 100vh;
      right: 0;
      min-width: 380px;
      padding: 0 77px 40px 77px;
      flex-shrink: 0;
    }
    @include lg {
      padding: 0 24px 40px 30px;
    }
    &__wrapper {
      @include md {
        min-width: 290px;
        margin-left: 20px;
      }
      .btn--desktop {
        margin-top: -10px;
        display: none;
        @include lg {
          display: block;
        }
        &-payment {
          position: absolute;
          top: -47px;
          @media (max-wdith: 1024px) {
            display: none;
          }
          .btn--icon img {
            margin-bottom: 5px;
          }
        }
      }
      .btn--mobile {
        display: block;
        @include lg {
          display: none;
        }
      }

      @include lg {
        margin-right: auto;
      }
    }
  }
  &--active {
    background-color: #dedede6b;
    z-index: 1;
    opacity: 1 !important;
    div {
      color: #000;
      opacity: 1 !important;
      font-weight: $weight__bold !important;
    }
  }
  &__info {
    display: flex;
    position: relative;
    z-index: 999;
    padding-bottom: 10px;
    background-color: #fff;
    width: 100vw;
    align-items: center;
    padding-top: 15px;
    padding-left: 20px;
    @include lg {
      padding-left: 0;
      padding-bottom: 0;
      background-color: transparent;
      width: auto;
    }
    // @include lg {
    //   padding-top: 34px;
    // }
  }
  &--userLogo img {
    width: 80px;
  }
  &--name {
    margin-left: 10px;
    position: relative;
    @include lg {
      margin-left: 20px;
    }
    p {
      font-size: 18px;
      font-weight: $weight__bold;
      margin-bottom: -10px;
    }
    span {
      font-size: 10px;
      opacity: 0.29;
      font-weight: $weight__normal;
      text-transform: capitalize;
    }
    svg {
      position: absolute;
      left: -62px;
      top: 0;
    }
  }
  &__settings {
    align-items: center;
    background-color: #f7f7f7;
    bottom: 35px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 34px;
    width: 34px;
    transition-duration: 0.3s;
    margin: 0 20px 0 auto;
    img {
      width: 16px;
    }
    &--active {
      background-color: rgba(0, 0, 0, 1);
      .a {
        stroke: #fff;
      }
    }
    &--active-desktop {
      @media (min-width: 1024px) {
        background-color: rgba(0, 0, 0, 1);
        .a {
          stroke: #fff;
        }
      }
    }
  }
  &--filtering {
    margin-top: 40px;
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 40px;
    p {
      font-size: 14px;
      font-weight: $weight__bold;
      margin-bottom: 32px;
    }
    span {
      height: 34px;
      background-color: #f7f7f7;
      width: 100%;
      display: block;
      border-radius: 5px;
      margin-bottom: 40px;
    }
  }
  &--packets {
    padding: 30px 0;
    font-size: 14px;
    font-weight: $weight__normal;
    border-bottom: 1px solid #f7f7f7;
  }
  &--packetsSingle {
    display: flex;
    align-items: center;
    margin-left: -20px;
    justify-content: space-between;
    position: relative;
    &--l {
      display: flex;
      align-items: center;
    }
    &--r {
      display: flex;
      justify-items: flex-end;
      label:nth-child(2) {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
      }
    }
    &--icon {
      &:before {
        content: '';
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 14px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
        border: 1px solid rgba(112, 112, 112, 0.1);
      }
      &-active:before {
        border: 0;
        padding: 14px;
        background-color: #f1f1f1;
      }
      &-active:after {
        content: '';
        display: block;
        margin-left: 12px;
        margin-top: -19px;
        width: 3px;
        height: 8px;
        border: solid #ff9618;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    img {
      width: 80px;
      height: 80px;
    }
  }
  // .positionRelative {
  //   position: relative;
  //   padding-bottom: 0;
  //   @include lg {
  //     position: fixed;
  //   }
  // }
}

.preorder {
  margin-right: 10px;
  padding-right: 13px;
}
br {
  display: block;
  height: 5px;
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.3);
}
.table {
  width: 100%;
  &__heading {
    height: 31px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    th {
      color: rgba(0, 0, 0, 0.3);
      padding: 10px;
      text-align: left;
      font-size: 12px;
    }
  }
  &__row {
    td {
      padding: 16px 10px;
      background-color: white;
      vertical-align: middle;
    }
    td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    td:nth-child(8) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  // &--tel,
  // &--nip,
  // &--name {
  //   min-width: 160px;
  // }
  &--more {
    border-radius: 5px;
    border: 3px solid #f1f1f17e;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border-left: 4 px solid #f1f1f17e;
    writing-mode: vertical-lr;
    max-width: 34px;
    width: 34px;
    font-size: 20px;
    p {
      margin-left: -2px;
      font-size: 16px;
      letter-spacing: 2px;
      font-weight: $weight__bold;
    }
  }
  &--color {
    &--Basic {
      background-color: #c4c4c4;
    }
    &--Premium {
      background-color: #352fee;
    }
    &--Pro {
      background-color: #303030;
    }
  }
  &--package {
    div {
      display: flex;
      // align-items: center;
      align-items: stretch;
    }
    p {
      width: 18px;
      height: 18px;
      border-radius: 20px;
      margin-right: 5px;
    }
  }
}

.btn--desktop-payment {
  margin-top: -30px;
  position: relative;
  .btn {
    margin-left: 0px;
    z-index: 99;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}
