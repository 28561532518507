.badge {
  padding: 0px 12px;
  height: 23px;
  border-radius: 11px;
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  color: #fff;
  line-height: 23px;
  display: flex;
  align-items: center;
  &--success {
    color: rgba(83, 218, 155, 1);
    background-color: rgba(83, 218, 155, 0.15);
  }
  &--yellow {
    color: rgba(244, 165, 63, 1);
    background-color: rgba(244, 165, 63, 0.1);
  }
  &--error {
    color: rgba(255, 64, 24, 1);
    background-color: rgba(255, 64, 24, 0.1);
  }
  &--gray {
    color: #666666;
    background-color: #f2f2f2;
  }

  .loader {
    margin-right: 5px;
  }
}
