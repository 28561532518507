.login-page {
  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;
    position: relative;
    background: #e4e4e4;
    @media (min-width: 1024px) {
      min-height: calc(100vh - 80px);
      min-height: 100vh;
      padding-top: 175px;
      z-index: 9;
      margin-top: -95px;
    }
    @media (max-width: 1024px) {
      padding-bottom: 80px;
    }
  }
  &__form {
    position: relative;
    padding: 48px 96px;
    border-radius: 16px;
    background: #f2f2f2;
    width: calc(100% - 40px);
    max-width: 559px;
    z-index: 2;
    margin-bottom: 80px;
    @media (max-width: 1024px) {
      padding-inline: 16px;
      width: 100%;
    }
  }
  &__title {
    color: #000;
    margin-bottom: 40px;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
  }
  &__sub-title {
    color: var(--czarny-300, #333);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    max-width: 357px;
    margin-bottom: 40px;
    @media (max-width: 1024px) {
      max-width: 100%;
    }
    &--email {
      font-weight: 700;
    }
  }
  &__validation {
    padding-top: 23px;
    margin-bottom: 42px;
    overflow: hidden;
    transition: 0.3s;
    max-height: 0;
    &--show {
      max-height: 99px;
    }
  }
  &__remember-password {
    color: var(--czarny-300, #333);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-decoration-line: underline;
    margin-top: 16px;
    cursor: pointer;
  }
  &__header-wrapper {
    max-width: 1136px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 32px 0 32px;
    height: 72px;
    a {
      height: 50px;
    }
  }
  &__submit {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 48px;
    margin-top: 40px;
  }
  &__back {
    z-index: 2;
    position: absolute;
    top: 51px;
    left: 72px;
    cursor: pointer;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__header-overlay {
    height: 95px;
    // margin-top: -72px;
    position: relative;
    z-index: 1;
    // padding: 14px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background: #e4e4e4;
    z-index: 20;
    @media (max-width: 1024px) {
      display: none;
    }

    .svg {
      height: 50px;
      width: 100px;
    }
    &--dark {
      .login-page__register {
        color: var(--bialy, #f2f2f2);
      }
      .svg path {
        fill: var(--bialy, #f2f2f2);
      }
    }
  }
  &__register {
    color: var(--czarny-400, #262626);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    gap: 4px;
    padding-top: 4px;
    &--outside {
      margin-top: 40px;
      @media (min-width: 1024px) {
        display: none;
      }
    }

    p {
      font-weight: 700;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
