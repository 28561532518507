.popUp {
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px 3px 40px #00000021;
  position: fixed;
  padding: 50px 30px 31px 30px;
  left: 0;
  margin: 0 auto;
  right: 0;
  max-height: 95vh;
  width: calc(100% - 30px);
  z-index: 999999999;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 50%;
  transform: translateY(-50%);
  @media (min-width: 450px) {
    padding: 56px 52px 31px 57px;
  }
  @include md {
    border-radius: 16px;
    position: fixed;
    max-width: 741px;
    // height: 694px;
    // height: max-content;
    padding: 40px 40px 21px 40px;
    overflow-x: visible;
    overflow-y: visible;
    margin: auto auto;
  }
  @include lg {
    padding: 30px 52px 35px 57px;
  }
  &__nameSteps {
    font-size: 18px;
    color: #000000;
    font-weight: $weight__bold;
    padding-bottom: 24px;
  }
  &__descriptSteps {
    color: rgba(142, 142, 142, 0.64);
    font-size: 10px;
    max-width: 200px;
  }
  .heading {
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;  
  }
  .paragraph {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  .buttons {
    display: flex;
  }
  &--close-svg {
    width: 15px;
    height: 15px;
    margin-top: 9px;
  }
  &.addWebsite {
    .errors {
      margin: 0 auto 10px auto;
    }
    .incidents {
      max-width: unset;
    }
  }
  &--formSettings {
    height: 100%;
    display: flex;
    flex-direction: column !important;
    form {
      height: 100%;
    }
  }
  &--column {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: space-between;

    &__field {
      width: 100%;
    }
    &__button {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .btn--wrapper {
      max-height: 50px;
      margin-bottom: 20px;
      .btn {
        border-radius: 5px;
        font-size: 9px;
        width: 143px;
        justify-content: center;
      }
    }

    .form__field {
      max-width: 100% !important;
      padding-right: 70px;
      margin-bottom: 15px !important;
      .eye {
        right: 78px;
      }
      .field-label {
        font-size: 13px;
        position: relative;
      }

      &--label-error {
        font-size: 9px;
        // position: absolute;
        // // top: -12px;
        // bottom: -64px;
        // right: 0;
      }
      input {
        background-color: rgba(249, 249, 249, 1);
        max-height: 40px;
      }
    }
    &__field--urlError {
      .form__field {
        padding-right: 0;
      }
    }
  }
  .popUp--column__field {
    // .react-select__indicators{
    //   margin-bottom: 5px;
    // }
    // .react-select__single-value {
    //   height: 8px
    // }
  }
  .popUp--column__field--urlError .form__field--label-error {
    font-size: 9px;
    position: absolute;
    top: -12px;
    right: 0;
  }
  &__steps {
    &--partner {
      margin-top: 20px;
    }

    &--link {
      text-decoration: underline;
      color: #000;
    }

    display: flex;
    align-items: flex-end;
    h1 {
      font-size: 21px;
      font-weight: 700;
      line-height: 21px;
      color: #242424;
    }
    span {
      font-size: 15px;
      color: rgba(36, 36, 36, 0.36);
      line-height: 18px;
      font-weight: 400;
      margin-left: 8px;
    }
  }
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 70px;
    @include md {
      margin-bottom: 10px;
    }
    @include xl {
      margin-bottom: 20px;
    }
    h3 {
      flex-shrink: 0;
      color: #3b3b3b;
      font-weight: $weight__max;
      font-size: 30px;
      width: 100%;
      text-align: center;
      @media (min-width: 340px) {
        font-size: 35px;
      }
      @media (min-width: 550px) {
        text-align: left;
      }
      @include md {
        font-size: 30px;
      }
      @include xl {
        font-size: 35px;
      }
    }
    &-h {
      margin-left: 20px;
    }
    p {
      max-width: 270px;
      font-size: 14px;
      opacity: 0.55;
      color: #3b3b3b;
    }
  }
  &--form {
    display: flex;
    flex-direction: column;
    height: calc(100% - 120px);
    @media (min-width: 635px) {
      flex-direction: row;
      padding-bottom: 0;
    }
    @include md {
      height: calc(100% - 60px);
    }
    @include xl {
      height: calc(100% - 47px);
    }
    &-expiration-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  .expiration-date {
    width: 100px !important;
  }
  .expiration-cvv {
    width: 111px !important;
  }
  &--form-l {
    align-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .expand-text {
      margin-bottom: auto;
    }
    p {
      color: #fff;
      width: 100%;
      img {
        width: 100px;
        height: 50px;
      }
    }
  }
  .rulez {
    margin-top: 20px;
  }
  .second_step {
    .form__field--label-error {
      top: 0;
      right: 0;
      @include lg {
        right: -19px;
      }
    }
    .invalid-feedback {
      color: #e20d0d;
      font-size: 12px;
    }
    .popUp--form-l,
    .popUp--form-r {
      height: 190px;
      width: 50%;
      max-width: 50%;
    }
    &-form {
      max-height: 190px;
      &-rulez {
        @media screen and (min-width: 756px) and (max-height: 660px) {
          margin-top: -10px;
        }
        @media screen and (min-width: 756px) and (max-height: 610px) {
          margin-top: -18px;
        }
        @media screen and (min-width: 756px) and (max-height: 590px) {
          margin-top: -35px;
        }
      }
      &-rulez-text {
        font-size: 12px;
        line-height: 18px;
        display: block;
        color: #4b4b4b;
        opacity: 0.58;
        @media screen and (min-width: 756px) and (max-height: 660px) {
          font-size: 11px;
        }
      }
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      .popUp--form-l,
      .popUp--form-r {
        width: calc(50% - 10px);
        height: auto;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 756px) {
          height: auto;
          width: 100%;
          max-width: 100%;
          margin: 0;
        }
      }
      &--partner {
        align-content: flex-start;
      }
    }
  }
  .form__field {
    width: 100%;
    max-width: 273px;
    margin: 0;
    &--textarea {
      min-height: 100px;
      height: 100%;
    }
    .field-label {
      color: #1c1c1c;
      height: 14px;
      font-weight: $weight__bold;
      @include lg {
        margin-bottom: 8px;
        margin-top: 14px;
      }
      @media screen and (min-width: 756px) and (max-height: 744px) {
        height: 8px;
      }
      @media screen and (min-width: 756px) and (max-height: 600px) {
        margin-top: 10px;
      }
      @media screen and (min-width: 756px) and (max-height: 580px) {
        margin-top: 7px;
        font-size: 11px;
      }
      @media screen and (min-width: 756px) and (max-height: 565px) {
        margin-top: 4px;
      }
      // @include xl {
      //   margin-bottom: 8px;
      //   margin-top: 14px;
      // }
    }
    // .react-select__value-container {
    //   padding: 10px 10px;
    // }
    .react-select__control {
      height: 48px;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      margin-bottom: 0;
    }
    input {
      border-radius: 5px;
      padding: 15px 10px;
      @media screen and (min-width: 756px) and (max-height: 744px) {
        padding: 12px 10px;
      }
      @media screen and (min-width: 756px) and (max-height: 660px) {
        padding: 10px 10px;
      }
      @media screen and (min-width: 756px) and (max-height: 630px) {
        padding: 7px 10px;
        font-size: 12px;
      }
    }
    &:nth-child(6) {
      max-height: 125px;
      height: 100%;
      margin-bottom: 30px;
      textarea {
        height: 100px;
      }
    }
  }
  &--form-r {
    display: flex;
    flex-direction: column;
    position: relative;
    align-content: flex-end;
    @media (max-width: 756px) {
      padding-bottom: 30px;
    }
    .btn--wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      // margin-top: 30px;
    }
    .btn--black {
      width: 245px;
      justify-content: center;
      position: relative;
      .btn__icon {
        position: absolute;
        right: 30px;
        top: 15px;
      }
    }
  }
  &--form-l,
  &--form-r {
    width: 100%;
  }
  .popUp--header-packets {
    margin-bottom: 20px;

    @media (min-width: 700px) {
      margin-bottom: 40px;
      margin-left: 20px;
    }
    @media screen and (min-width: 756px) and (max-height: 744px) {
      margin-bottom: 30px;
    }
    @media screen and (min-width: 756px) and (max-height: 566px) {
      margin-bottom: 5px;
    }
  }
  &--form-packets {
    .popUp--form-l-addpayment {
      justify-content: space-between !important;
      button {
        margin-bottom: 30px;
      }
    }
    form {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      @include md {
        flex-wrap: nowrap;
      }
    }
    min-height: 350px;
    .form__field:nth-child(3) input {
      height: auto;
    }
    .popUp--form-l,
    .popUp--form-r {
      align-content: flex-start;
      justify-content: flex-start;
      display: flex;
      .form__field {
        max-width: 100%;
      }
    }
    // .popUp--form-r--padding {
    //   @media (min-width: 757px) {
    //     padding-top: 82px;
    //   }
    // }
    .popUp--form-r {
      @media (min-width: 756px) {
        margin-left: 20px;
      }
      .btn--wrapper {
        padding-top: 30px;
        @media (min-width: 756px) {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
  &--close {
    position: absolute;
    right: 18px;
    top: 18px;
    color: gray;
    cursor: pointer;
    z-index: 9;
  }
  .packets__item-content-footer {
    width: calc(100% - 20px);
    @include md {
      width: calc(100% - 70px);
    }
    @include lg {
      width: calc(100% + 73px);
    }
  }
  .checkbox__rule {
    .checkmark {
      top: 2px;
    }
  }

  &.changeParameters {
    height: fit-content;
    padding-bottom: 30px;
    .popUp__nameSteps {
      font-size: 13px;
      margin-top: 15px;
    }
    .btn--black {
      border-radius: 5px;
      font-size: 12px;
      padding: 7px 25px;
      justify-content: center;
    }
  }
  &__card-status,
  &__card-manage,
  &__ftp-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  &__card-manage {
    margin-top: 15px;
    p {
      font-size: 12px;
      color: rgba(9, 9, 9, 0.75);
      font-weight: 400;
    }
  }
  &__ftp-account {
    margin-top: 25px;
    &--title {
      font-size: 14px;
      color: #000000;
      font-weight: $weight__bold;
    }
    &--connected {
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 400;

      .svg {
        width: 12px;
        height: 12px;
        margin-left: 10px;
      }
    }
  }
  &__change-card {
    .packagesType__wrapper {
      margin-top: 16px;
    }
    .paymentWebsite__wrapper {
      margin-top: 20px;
    }
    .cardContent {
      margin-bottom: 10px;
    }
    &--choose {
      margin-top: 25px;
      float: right;
    }
    .eye {
      top: 23px;
    }
  }
  .table {
    &-errors {
      margin: 40px auto;
      width: 800px;
      table,
      th,
      td {
        border: 1px solid;
        text-align: center;
        padding: 10px;
      }
    }
  }
}

.diagnosticDialog {
  padding: 30px 32px 35px 32px;
  overflow: scroll;
}

.popUp--packets {
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 700px) {
    flex-direction: row;
  }
  @include lg {
    margin: 30px 0 20px -60px;
  }
  @include xl {
    margin: 40px 0 30px -60px;
  }
  @media screen and (min-width: 756px) and (max-height: 744px) {
    margin: 20px 0 20px -60px;
  }
  &--partner {
    margin: 55px 0 30px -60px;
    @media (max-width: 1000px) {
      margin: 50px 0 30px 30px;
    }
  }
  &--l {
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    height: 70px;
    position: relative;
    width: 100%;
    p {
      margin-left: 70px;
      @media (min-width: 450px) {
        margin-left: 40px;
      }
    }
    section {
      position: relative;
    }
    &--currency {
      font-size: 18px !important;
      position: absolute;
      right: 5px;
      top: 10px;
      opacity: 1;
    }
    &--vat {
      right: 5px;
      font-size: 8px !important;
      position: absolute;
      top: 26px;
      opacity: 0.4;
    }
    strong {
      padding-right: 20px;
      &:after {
        right: -10px !important;
      }
    }
    div {
      height: 200px;
      position: absolute;
      top: -65px;
      width: 200px;
      left: -90px;
      @media (min-width: 450px) {
        left: -120px;
      }
      @include md {
        left: -130px;
      }
    }
    img {
      width: 100%;
    }
    &:after {
      content: '';
      position: absolute;
      top: -15px;
      left: 70px;
      background-image: url('../../Image/logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 80px;
      height: 30px;
      @media (min-width: 450px) {
        left: 40px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      right: -10px;
      top: -10px;
      background-image: url('../../Image/shield.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }
  }
  &--r {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-self: center;
    line-height: 16px;
    margin-bottom: 40px;
    @media (min-width: 700px) {
      padding-left: 40px;
      margin-bottom: 0;
    }
    @include md {
      padding-left: 20px;
    }
    @include lg {
      padding-left: 20px;
    }
  }
}
.packets-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  max-height: 50px;
  .packets-button-back {
    // opacity: 0.2;
    border: 1px solid #70707021;
    border-radius: 36px;
    color: #212121;
  }
  button {
    width: 245px;
    display: flex;
    justify-content: center;
    @media (max-width: 766px) {
      // width: 150px;
      margin-bottom: 30px;
    }
    // @media (max-width: 400px) {
    //   width: 120px;
    //   margin-bottom: 30px;
    // }
  }
}
.packets-button {
  &-next {
    @include lg {
      margin-top: 25px;
    }
  }
}

.popUp--form-nip-prefix {
  display: flex;

  .form__field {
    display: flex;
    align-content: flex-end;
    flex-direction: column;
    &:first-child {
      width: 100px;
    }
    &:nth-child(2) {
      input {
        width: calc(100% - 10px);
        margin-left: 10px;
      }
    }
    .react-select__control {
      margin: 0;
      height: 46px;
      border-radius: 5px;
      border: 1px solid #e2e2e2 !important;
      @media screen and (min-width: 756px) and (max-height: 744px) {
        height: 41px;
      }
      @media screen and (min-width: 756px) and (max-height: 660px) {
        height: 38px;
        min-height: 30px;
      }
      @media screen and (min-width: 756px) and (max-height: 630px) {
        height: 30px;
        min-height: 30px;
        font-size: 10px !important;
        .css-tlfecz-indicatorContainer {
          padding: 0 3px 0;
        }
      }
    }
  }
}
.popUp--form-tel {
  display: flex;

  .form__field {
    display: flex;
    align-content: flex-end;
    flex-direction: column;
    &:first-child {
      width: 56px;
    }
    &:nth-child(2) {
      input {
        width: calc(100% - 10px);
        margin-left: 10px;
      }
    }
  }
}

.popUp--contact-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  h3 {
    width: 100%;
    text-align: center;
  }
  p {
    text-align: center;
    margin: 20px 0;
  }
  margin-bottom: 0;

  @media (min-width: 700px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    h3 {
      width: auto;
    }
  }
}
.popUp--form-contact {
  flex-direction: column;
  // height: 100%;
  padding-bottom: 10px;
  justify-content: center;
  @media (max-width: 1024px) {
    height: auto !important;
  }
  @media (min-width: 700px) {
    flex-direction: row;
  }
  .popUp--form-r .btn--wrapper {
    justify-content: center;
  }

  &--description {
    color: #242424;
    text-align: left;
    max-width: 270px;
    display: block;
    padding: 9px 0;
    font-size: 14px;
    line-height: 16px;
  }
  &--logo {
    display: none;
    @media (min-width: 700px) {
      margin-top: 16px;
      display: block;
    }
  }
  .popUp--form-r {
    flex-direction: none;
    align-items: center;
    .form__field--textarea {
      height: 100%;
      @media (max-width: 1024px) {
        min-height: 300px;
        textarea {
          min-height: 220px;
        }
      }
      textarea {
        height: calc(100% - 60px);
      }
    }
  }
  .popUp--form-l {
    align-content: space-between;
    margin-top: 150px;
    .recaptcha {
      margin: 30px 0;
    }
    @media (min-width: 700px) {
      padding: 0 20px;
      margin-top: 0;
      margin-right: 50px;
    }
    @media (max-width: 1024px) {
      margin-top: -20px;
    }
  }
  &--description-gray {
    color: #242424;
    opacity: 0.51;
    font-size: 10px;
    display: block;
    margin: 7px 0;
    a {
      color: #000;
      font-weight: $weight__bold;
    }
  }
  &--law span {
    display: block;
    margin: 9px 0;
    font-size: 11px;
  }
}
#react-select-1-input,
#react-select-2-input,
#react-select-3-input,
#react-select-4-input,
#react-select-5-input,
#react-select-6-input,
#react-select-7-input,
#react-select-8-input,
#react-select-9-input,
#react-select-1-input,
#react-select-12-input,
#react-select-13-input,
#react-select-14-input,
#react-select-15-input,
#react-select-16-input {
  border: none !important;
}

.form__field--countryList
// .form__field--select
{
  .css-yk16xz-control {
    border: 1px solid #e2e2e2 !important;
    @media screen and (min-width: 756px) and (max-height: 660px) {
      min-height: 30px;
      height: 37px;
      font-size: 12px;
    }
    @media screen and (min-width: 756px) and (max-height: 660px) {
      min-height: 30px;
      height: 36px;
      font-size: 12px;
    }
    @media screen and (min-width: 756px) and (max-height: 630px) {
      height: 33px;
    }
  }
  .css-2b097c-container {
    border: 0 !important;
  }
  input {
    height: 33px !important;
    border: 1px solid #e2e2e2 !important;
    @media screen and (min-width: 756px) and (max-height: 744px) {
      height: 28px !important;
    }
    @media screen and (min-width: 756px) and (max-height: 660px) {
      height: 10px !important;
    }
  }
  @media screen and (min-width: 756px) and (max-height: 660px) {
    .form__field--countryList .css-1uccc91-singleValue {
      font-size: 12px !important;
    }
    .css-g1d714-ValueContainer {
      padding: 0 6px !important;
    }
  }
}

.errors {
  &__single-error {
    padding: 10px 10px;
    border: 1px solid #e20d0d;
    color: #e20d0d;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
    font-family: $fontPoppins;
    animation: fadein 1.2s;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.preorder-button {
  @media screen and (min-width: 756px) and (max-height: 744px) {
    padding: 10px 30px;
  }
  @media screen and (min-width: 756px) and (max-height: 660px) {
    padding: 10px 30px !important;
  }
  @media screen and (min-width: 756px) and (max-height: 630px) {
    font-size: 13px !important;
    .btn__icon {
      top: 10px !important;
      height: 8px !important;
      width: 8px !important;
    }
  }
}

.popUpPackages {
  height: 85%;
  @media (max-width: 1420px) {
    height: 88%;
  }
}

.registerUrl {
  &--wrapper {
    position: relative;
  }
  &--error {
    position: absolute;
    top: 13px;
    right: 0;
    font-size: 9px;
    font-weight: $weight__normal;
    color: #ff0000;
  }
}

.registerUrl--wrapper {
  span {
    // @media (min-width: 756px) {
    //   position: absolute;
    //   right: -14px;
    // }
    // @media (max-width: 456px) {
    position: absolute;
    right: 0;
    top: -20px;
    // }
  }
}
.popupChangePassword {
  max-width: 375px;
  padding: 20px 15px;
  height: fit-content !important;
  max-height: unset !important;
  .form__field {
    max-width: 100%;
  }
  .userMenu__form-button {
    margin-bottom: 0;
  }
  .userMenu__content .userMenu__form-button {
    @media (max-width: 1024px) {
      position: unset;
    }
  }
  .popUp--close {
    z-index: 99;
  }
}

.incidents {
  padding: 20px 15px;
  height: auto;
  max-width: 900px;
  max-height: 90vh;
  overflow-x: auto;
}

.addWebsite {
  padding: 20px 15px;
  height: auto;
  max-width: 390px;
  max-height: 90vh;
  overflow-x: hidden;
  .eye {
    top: 22px;
  }
  .popUp__steps {
    margin-bottom: 30px;
    &--black,
    &--gray {
      font-size: 18px;
      font-weight: $weight__bold;
    }
    &--black {
      color: #000000;
      margin-left: 0;
    }
  }
  .popUp--form-r .btn--wrapper {
    margin-bottom: 30px;
    position: relative;
  }
  .select-field__option {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: $weight__normal;
    .mastercard {
      width: 40px;
      height: 20px;
    }
    .visa {
      width: 50px;
      height: 30px;
      margin-top: 2px;
    }
  }
}

.signUpToWebinar {
  width: 40%;
  height: auto;
  overflow: scroll;
  @media (max-width: 1023px) {
    width: 95%;
  }
}

.incorrectFile {
  padding-bottom: 40px;
  &__header {
    font-size: 17px;
    font-weight: 700;
    color: #ff4018;
    padding-bottom: 30px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    @media(max-width: 769px) {
      flex-direction: column;
    }
    .btn--wrapper {
      width: 100%;
      &:first-child {
        margin-right: 10px;
        @media(max-width: 769px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
    .btn {
      width: 100%;
    }
  }
  .fileFormat {
    font-weight: 700;
  }
}

.addLogo {
  max-width: 375px;
  padding: 20px;
}

.actions {
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
}

.partnerClientsWebsite {
  .packagesItem {
    cursor: default;
  }
}
