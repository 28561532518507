.checkbox {
  margin-top: 24px;
  &:hover {
    cursor: pointer;
    .checkbox__dummy-input {
      border: 2px solid var(--niebieski-300, #908cff);
    }
  }
  input {
    display: none;
  }
  &__dummy-input {
    width: 24px;
    height: 24px;
    display: flex;
    flex: 1;
    min-width: 24px;
    position: relative;
    border-radius: 6px;
    border: 2px solid var(--szary-300, #bfbfbf);
    transition: 0.3s;
    max-width: 24px;
    margin-top: 4px;
  }
  &__svg {
    opacity: 0;
    transition: 0.3s;
    height: 16px;
    width: 16px;
    margin-top: 2px;
    margin-left: 3px;
    path {
      stroke: white;
    }
    &--checked {
      opacity: 1;
    }
  }
  &__option {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    &--selected {
      .checkbox__dummy-input {
        border: 2px solid var(--niebieski-600, #1913bf) !important;
        background: var(--niebieski-500, #352fee);
      }
    }
  }
  &__title-wrapper {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 316px;
    a {
      color: #352fee;
      cursor: pointer;
    }
  }
  &__error {
    color: var(--czerwony-500, #e20d0d);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 2px;
    margin-left: 0;
    position: absolute;
  }
}
