.my-websites-list {
  &__payment {
    p {
      color: var(--czarny-500, #111);
      font-size: 12px;
      font-weight: 500;
      margin-left: 8px;
      @media screen and (max-width: 1000px) {
        text-wrap: nowrap;
      }
    }
  }

  &__credit-card {
    path {
      fill: #14181f;
    }
    &--inactive {
      path {
        fill: #e20d0d;
      }
    }
  }
  &__credit-card-dot {
    width: 6px;
    height: 6px;
    margin-right: 6px;
    circle {
      fill: #a6a6a6;
    }
  }

  &__no-credit-card {
    color: var(--szary-400, #a6a6a6) !important;
    font-size: 12px;
    line-height: 18px; /* 150% */
    margin-left: 0 !important;
  }

  &__settings {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1.5px solid var(--czarny-400, #262626);
    cursor: pointer;
    .svg {
      width: 14px;
      height: 14px;
    }
  }

  &__preview-btn {
    border-radius: 4px;
    border: 1.5px solid var(--czarny-400, #262626);
    display: flex;
    width: 78px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 0;
    font-size: 10px;
  }

  &__settings-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 1000px) {
      grid-area: settings;
      .btn--wrapper:first-child {
        width: 100%;
        .btn {
          width: 100%;
        }
      }
    }
  }

  &__downgrade-paragraph {
    color: var(--szary-600, #666);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    margin-top: 8px;
    margin-bottom: 8px;
    b {
      font-weight: 700;
    }

    &--second {
      margin-top: 14px;
    }
  }
  .tableWebsites__status {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  &__url {
    .svg--featherGoogle {
      margin-right: 10px;
      @media screen and (max-width: 1000px) {
        margin-left: 0px;
      }
    }
    padding-left: 20px;
    @media screen and (max-width: 1000px) {
      position: relative;
      ::before {
        content: '';
        width: 6px;
        height: 6px;
        flex-shrink: 0;
        position: absolute;
        top: 8px;
        left: 3px;
        border-radius: 100px;
      }
      &--red {
        ::before {
          background: #e20d0d;
        }
      }
      &--green {
        ::before {
          background: #38d58b;
        }
      }
      &--gray {
        ::before {
          background: #a6a6a6;
        }
      }
    }
  }

  &__website-status {
    @media screen and (max-width: 1000px) {
      .btn--wrapper {
        width: 100%;
      }
      .btn {
        width: 100%;
      }
    }
  }

  &__item-wrapper {
    @media screen and (max-width: 1000px) {
      margin: 0;
      margin-bottom: 8px;
    }
  }
  &__wrapper {
    &--partner {
      grid-template-columns: 0.25fr 0.5fr 1.5fr 180px 1fr 1.5fr 1.5fr 105px 115px 118px;
    }

    &--admin {
      @media screen and (min-width: 1000px) {
        grid-template-columns: 0.25fr 0.5fr 1.5fr 1fr 180px 120px 230px 100px 118px !important;
      }
      @media screen and (max-width: 1000px) {
        grid-template-columns: unset;
        grid-template-areas:
          'url url url url'
          'package package package payment'
          'owner owner diagnosticse diagnostics'
          'websitesStatus websitesStatus data data'
          'settings settings settings settings' !important;
        .tableWebsites__status {
          display: none;
        }
      }
    }
  }

  &__item {
    grid-template-columns: 0.25fr 1fr 2.5fr 180px 1.5fr 230px 118px;
    // grid-template-columns: 0.25fr 1fr 2.5fr 180px 1.5fr 105px 115px 118px;
    @media screen and (max-width: 1000px) {
      grid-template-columns: unset;
      grid-template-areas:
        'url url url url'
        'package package package payment'
        'websitesStatus1 websitesStatus data data'
        'settings settings settings settings';
    }
    &--incomplete {
      // grid-template-columns: 0.25fr 1fr 2.5fr 180px 1.5fr 1.5fr 230px 118px;
      @media screen and (max-width: 1000px) {
        grid-template-columns: unset;
        grid-template-areas:
          'url url url url'
          'package package package payment'
          'websitesStatus websitesStatus data data'
          'settings settings settings settings';
      }
    }
    &--admin {
      @media screen and (min-width: 1000px) {
        grid-template-columns: 0.25fr 0.5fr 1.5fr 1fr 180px 1fr 230px 100px 118px !important;
      }
    }
    //     tom@test.corgi.pro
    &--admin-incomplete {
      @media screen and (min-width: 1000px) {
        grid-template-columns: 0.25fr 0.5fr 1.5fr 1fr 180px 120px 230px 100px 118px !important;
      }
    }
    &--admin-clients {
      @media screen and (min-width: 1000px) {
        grid-template-columns: 0.25fr 1fr 2.5fr 180px 1.5fr 1.5fr 230px 118px;
      }
      @media screen and (max-width: 1000px) {
        .tableWebsites__status {
          display: none;
        }
      }
    }
    &--partner {
      @media screen and (min-width: 1000px) {
        grid-template-columns: 0.25fr 0.5fr 1.5fr 180px 1fr 1.5fr 1.5fr 230px 118px;
      }
      .tableWebsites__package__price {
        margin-right: 10px;
      }
    }
    .websitesStatus__wrapper {
      width: 50%;
      margin: 0;
    }
  }
  &__configuration-button {
    border-radius: 4px;
    border: 1.5px solid var(--niebieski-500, #352fee);
    display: flex;
    width: 230px;
    height: 32px;
    color: var(--niebieski-500, #352fee);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 0;
    font-size: 10px;
  }

  &__header {
    grid-template-columns: 0.25fr 1fr 2.5fr 180px 1.5fr 105px 115px 118px;
    &--address {
      padding-left: 20px;
    }

    &--admin {
      grid-template-columns: 0.25fr 0.5fr 1.5fr 1fr 180px 120px 105px 115px 100px 118px;
    }

    &--admin-clients {
      grid-template-columns: 0.25fr 1fr 2.5fr 180px 1.5fr 1.5fr 105px 115px 118px;
    }
  }

  &__delete {
    cursor: pointer;
  }

  &__packet {
    display: flex;
    border-radius: 5px;
    background: #fff;
    padding: 0 16px 0 32px;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    color: var(--czarny-500, #111);
    font-size: 14px;
    font-weight: 600;

    .btn {
      height: 32px;
      padding: 0;
      width: 95px;
    }
  }

  &__modal-wrapper {
    max-width: 360px;
    width: 100%;
    .StripeElement {
      border: none;
    }
  }

  &__modal-btn {
    text-align: center;
    font-size: 13px;
    font-weight: 700;
  }

  &__packet-header {
    color: var(--czarny-500, #111);
    font-size: 14px;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    margin-bottom: 8px;
    margin-top: 24px;
    text-transform: capitalize;
  }

  &__row {
    &--inactive-row {
      pointer-events: none;
      .tableWebsites__status,
      .tableWebsites__url,
      .tableWebsites__package,
      .tableWebsites__package__name,
      .tableWebsites__activation,
      .tableWebsites__comission,
      .tableWebsites__payment {
        filter: grayscale(1) opacity(0.4);
      }
    }
    &--hidden {
      display: none;
    }
  }

  &__websitesStatus-loader {
    color: var(--czarny-400, #262626);
    font-size: 12px;
    line-height: 18px; /* 150% */
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .websitesStatus__icon {
    display: flex;
    svg {
      fill: transparent;
    }
  }

  &__packet-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__error {
    border-radius: 5px;
    background: #f5d6d6;
    height: 64px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
    p {
      color: var(--czarny-300, #333);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      display: flex;
      flex-direction: column;
    }
    .svg {
      path {
        fill: #f5d6d6;
      }
    }
  }
  &__upgrades {
    color: var(--czarny-300, #333);

    /* Body 2 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-bottom: 26px;
    @media (max-width: 1000px) {
      margin-bottom: 20px;
    }
  }

  &__card-label {
    color: var(--czarny-500, #111);
    font-size: 14px;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    margin-bottom: 12px;
    &--chosen {
      margin-top: 48px;
    }
  }

  &__button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    gap: 18px;
    transition: 0.3s;
    .btn--wrapper {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__card-remove {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    &--open {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__card-wrapper {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 30px 24px;
    width: 360px;
    h3 {
      color: var(--czarny-500, #111);
      font-size: 18px;
      font-weight: 800;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.045px;
      text-align: center;
    }
    p {
      margin-top: 16px;
      color: var(--czarny-300, #333);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      span {
        font-weight: 700;
      }
    }
  }

  &__card-remove-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    gap: 18px;
    transition: 0.3s;
    .btn--wrapper {
      width: 100%;
    }
    .btn {
      width: 100%;
      height: 32px;
      min-height: 32px;
    }
  }

  &__bold {
    font-weight: 700;
  }

  &__add-card-button {
    top: -40px;
  }

  &__change-card {
    margin-top: 40px;
  }
}
.websitesStatus__icon {
  display: flex;
  svg {
    fill: transparent;
  }
}
