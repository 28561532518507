.allUsersTable {
  display: grid;
  // grid-template-columns: 50px repeat(6, 1fr);
  grid-template-columns: 50px 1.5fr 1.5fr 1.5fr 0.5fr 1.5fr 1fr 0.5fr 0.5fr 0.25fr;
  column-gap: 10px;
  cursor: pointer;
  &--counters {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 0.5fr 50px;
    grid-template-areas:
      'name role website'
      'email email ddf'
      'company company company'
      'partner partner partner';
  }

  &__helper {
    background: transparent;
    position: absolute;
    top: -10px;
    width: 95%;
    height: 100%;
  }

  &__line {
    padding: 10px 0;
    border-bottom: 2px solid rgba(39, 45, 59, 0.15);
    word-break: break-all;
    position: relative;

    &--options {
      color: rgba(153, 153, 153, 1);
      font-size: 34px;
      line-height: 10px;
      cursor: pointer;
      &--active {
        color: transparent;
      }
    }

    &:last-child {
      margin-bottom: 100px;
    }
    @media (max-width: 1024px) {
      border-bottom: 0;
      background-color: #fff;
      border-radius: 5px;
      margin-bottom: 15px;
      padding: 5px 6px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin: 5px 0;
        font-size: 15px;
      }
      &--id {
        display: none;
      }
      &--ddf {
        grid-area: ddf;
      }
      &--name {
        grid-area: name;
      }
      &--email {
        grid-area: email;
      }
      &--company {
        grid-area: company;
      }
      &--partner {
        grid-area: partner;
      }
      &--data,
      &--recommending {
        display: none;
      }
      &--role {
        grid-area: role;
        text-align: center;
      }
      &--count {
        grid-area: website;
      }
    }
    &:hover {
      background-color: rgba(223, 223, 223, 0.55);
    }
    &--id,
    &--count {
      text-align: center;
    }
  }

  &__websites {
    width: 100%;
    height: auto;
    background-color: white;
    @media (max-width: 1024px) {
      background-color: unset;
    }
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.06);
    transition: width 600ms ease-out, height 600ms ease-out;
  }

  &__settings {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 40px #66666629;
    position: absolute;
    padding: 16px 20px;
    right: 0;
    top: 0;
    width: 270px;
    z-index: 9;
    height: 0;
    display: none;

    &--paragraph {
      font-size: 14px;
    }

    &--disabled {
      font-size: 14px;
      opacity: 0.3 !important;
      pointer-events: none !important;
      cursor: not-allowed !important;
    }

    &--active {
      transition-duration: 0.3s;
      height: auto;
      visibility: visible;
      display: block;
      @media (max-width: 1024px) {
        right: unset;
      }
    }
    .dots {
      color: #999999;
      font-size: 34px;
      padding: 0;
      cursor: pointer;
      z-index: 8;
      position: absolute;
      right: 12px;
      top: -8px;
      user-select: none;
    }
  }
}
.table-heading {
  padding: 16px 0;
  font-weight: 500;
  cursor: auto;
  @media (max-width: 1024px) {
    display: none;
  }
  &--id,
  &--count {
    text-align: center;
  }
}
