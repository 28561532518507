.validation-item {
  display: flex;
  height: 19px;
  align-items: center;
  gap: 8px;
  p {
    color: var(--granatowy-500, #222450);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    font-style: normal;
  }
  svg {
    width: 14px;
    height: 14px;
  }
  &__wrapper {
    overflow: hidden;
    transition: 0.3s;
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    flex-shrink: 0;
    &--show {
      padding-top: 23px;
      max-height: 99px;
    }
  }
}
