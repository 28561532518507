.checkYourWebsite {
  height: 100vh;

  .checkSite {
    margin-top: 0;
    margin-bottom: 0;
    height: 100vh;
    align-items: center;
    &__content {
      margin-top: 0;
    }  
    .checkSite__background {
      top: unset;
      margin-top: -200px;
    }
  }
}