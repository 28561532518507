.myData__stripe {
  overflow: hidden;
  padding-right: 2px;
  &--tax {
    padding: 8px 10px;
    background-color: #f9f9f9;
    border-radius: 4px;
    margin-top: 3px;
  }
  &--tax-wrapper {
    margin-top: 5px;
  }
  &--hideName {
    .StripeElement {
      margin-top: -88px !important;
    }
  }
  .StripeElement {
    background-color: unset;
    border: 0;
    padding: 0;
  }
}
.userMenu__form {
  .form__field input,
  .form__field select,
  .form__field textarea {
    font-size: 14px !important;
  }
  .select__option-container {
    span {
      font-size: 10px !important;
    }
  }
}
.userMenu__form .css-yk16xz-control,
.userMenu__form .css-1pahdxg-control {
  background-color: rgb(249, 249, 249) !important;
}

.myData__form {
  .field-label {
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}
.myData {
  div &__phone {
    margin-top: 16px;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      .form__field--select {
        max-width: 100px;
      }
    }
    div .react-select__control {
      margin: 0;
      height: 38px;
      border-radius: 5px;
    }
    .react-select__value-container {
      height: 38px;
    }
    .react-select {
      width: 100px;
    }
  }
}
