.notificationsTable {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 3fr 0.75fr;
  column-gap: 10px;
  font-size: 12px;
  padding: 18px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'url url url url'
      'icon where where where'
      'content content content content'
      'data data data data';
    row-gap: 20px;
    &__item {
      &--icon {
        grid-area: icon;
        font-weight: 500;
      }
      &--url {
        grid-area: url;
      }
      &--whereSend {
        // font-weight: 500;
        // text-transform: uppercase;
        &--archiwum-size {
          grid-area: where;
        }
        &--size {
          display: none;
        }
      }
      &--content {
        grid-area: content;
      }
      &--data {
        grid-area: data;
        color: rgba(0, 0, 0, 0.24);
      }
    }
  }
  &--backups {
    grid-template-columns: 1.25fr 3fr minmax(140px, 2fr) 1fr 1fr 1fr 0.1fr;
    .badge {
      display: flex;
      justify-content: center;
    }
    .notificationsTable__item--icon svg {
      flex-shrink: 0;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr 50px;
      // padding-bottom: 0;
      row-gap: 20px;
      grid-template-areas:
        'type type type size'
        'website website restore restore'
        'data data data dots'
        'settings settings settings settings';
      .notificationsTable__item {
        &--icon {
          grid-area: type;
        }
        &--url {
          grid-area: website;
        }
        &--whereSend {
          grid-area: size;
          font-weight: 700;
          text-transform: uppercase;
        }
        &--data {
          grid-area: data;
          color: rgba(0, 0, 0, 0.24);
        }
        &--settings {
          grid-area: dots;
          &--active {
            display: none;
          }
        }
        .backup__restore {
          grid-area: restore;
          display: flex;
          justify-content: flex-end;
        }
      }
      .tableWebsites__settings {
        padding-left: 0;
        .dots {
          right: 26px;
        }
      }
    }
  }

  &__item {
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px 0;
    position: relative;
    @media (max-width: 1024px) {
      border-radius: 0;
    }
    &--url {
      font-size: 14px;
      font-weight: 700;
    }
    &--icon {
      display: flex;
      svg {
        margin-right: 10px;
        margin-top: 5px;
      }
    }
    &--settings {
      color: #9999999a;
      font-size: 34px;
      line-height: 20px;
      padding: 0;
      cursor: pointer;
      user-select: none;
      margin-top: -5px;
      // line-height: 30px;
      line-height: 9px;
      cursor: pointer;
      position: relative;
    }
  }
  &--firstLine {
    text-transform: lowercase;
    li {
      &::first-letter {
        text-transform: uppercase;
      }
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.backup__restore {
  display: flex;
}
