.how-it-works {
  &__main-logo {
    max-width: 350px;
    margin-bottom: -20px;
    @media (max-width: 1000px) {
      max-width: 250px;
      padding-top: 48px;
      margin-bottom: 0;
    }
  }
  .section--background {
    z-index: 1;
    @media (max-width: 1000px) {
      bottom: -50px;
    }
  }
  .blue {
    color: var(--Niebieski-500, #352fee);
  }
  .section--under-header {
    max-width: 554px;
  }

  &__main-image-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    @media (max-width: 1000px) {
      justify-content: center;
    }
  }
  .mainHeading {
    max-width: 653px;
  }
  .section__r {
    max-width: 653px;
  }
  b {
    font-weight: 700;
  }
  &__features-list {
    display: flex;
    gap: 121px;
    row-gap: 90px;
    flex-wrap: wrap;
  }
  &__feature-index {
    color: var(--Szary-100, #f2f2f2);
    font-size: 36px;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: -0.36px;
    display: flex;
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    background: var(--Niebieski-500, #352fee);
  }

  &__feature-heading {
    margin-top: 16px;
    margin-bottom: 16px;
    color: var(--Czarny-400, #262626);
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: -0.045px;
    &--blue {
      color: var(--Niebieski-500, #352fee);
    }
  }

  &__feature {
    max-width: 459px;
  }

  &__feature-description {
    color: var(--Szary-700, #404040);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  &__do-you-know {
    position: absolute;
    bottom: 60px;
    right: 0;
    display: inline-flex;
    padding: 42px 20px 34px 56px;
    align-items: center;
    border-radius: 16px 0px 0px 16px;
    background: var(--Niebieski-500, #352fee);
    width: calc(50% - 10px);
    @media (max-width: 1000px) {
      margin-top: 100px;
      position: relative;
      width: calc(100% + 16px);
      padding: 42px 16px 115px 32px;
      bottom: unset;
    }
  }

  &__do-you-know-content {
    max-width: 368px;
    position: relative;
    @media (max-width: 1000px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__do-you-know-title {
    color: var(--Szary-100, #f2f2f2);
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.045px;
    margin-top: 24px;
    margin-bottom: 24px;
    &--yellow {
      color: var(--Pomaraczowy-400, #faa84b);
    }
  }
  &__do-you-know-text {
    color: var(--Szary-100, #f2f2f2);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__corgi-headphones {
    position: absolute;
    bottom: -44px;
    left: calc(100% + 53px);
    width: 139px;

    @media (max-width: 1000px) {
      left: unset;
      right: 0;
      height: 129px;
      width: auto;
      bottom: -130px;
    }
  }

  &__question-mark {
    position: absolute;
    left: 0;
    top: -80px;
  }

  &__paws {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    .a {
      opacity: 1;
    }
    path {
      fill: rgba(255, 255, 255, 0.6);
    }
    @media (max-width: 1000px) {
      bottom: 50px;
    }
  }
  .homepage__subtitle {
    &--36 {
      @media (max-width: 1000px) {
        font-size: 36px;
        line-height: 40px;
      }
    }

    &--color-262626 {
      color: var(--Czarny-400, #262626);
    }
  }
  &__tricks {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 200px;
    .homepage__subtitle {
      margin-bottom: -80px;
    }

    @media (max-width: 1000px) {
      gap: 80px;
    }
  }

  &__tricks-section {
    @media (max-width: 1000px) {
      margin-top: 0px !important;
      padding-bottom: 100px;
      margin-bottom: 100px !important;
    }

    .homepage__subtitle {
      max-width: 500px;
    }
  }

  &__gray-to-white {
    background-color: #f2f2f2;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__trick {
    display: flex;
    gap: 130px;
    margin-top: 40px;
    color: white;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 48px;
      margin-top: 0;
    }
    &:nth-child(2n) {
      @media (max-width: 1000px) {
        flex-direction: column-reverse;
      }
    }
  }

  &__trick-title {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px; /* 125% */
    margin-bottom: 16px;
  }

  &__trick-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    max-width: 556px;
    @media (min-width: 1000px) {
      flex: 50%;
    }
  }

  &__trick-image {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @media (min-width: 1000px) {
      flex: 50%;
    }
    @media (max-width: 1000px) {
      margin-bottom: -80px;
    }
    .svg {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-25%, -50%);
      @media (max-width: 1000px) {
        position: relative;
        transform: unset;
      }
    }
    &--backup {
      @media (min-width: 1000px) {
        transform: translate(-15%, -50%);
      }
      @media (max-width: 1000px) {
        width: 233px;
      }
    }
    &--security.svg {
      @media (min-width: 1000px) {
        right: 0;
        transform: translate(10%, -50%);
      }
      @media (max-width: 1000px) {
        width: 272px;
        left: 30px;
      }
    }
    &--updates {
      width: 500px;
      height: auto;
      top: 25px !important;
      @media (max-width: 1000px) {
        width: 296px;
      }
    }
    &:after {
      border-radius: 15px 0px 0px 15px;
      background: var(--Niebieski-600, #1913bf);
      content: '';
      position: absolute;
      bottom: 0px;
      height: 221px;
      width: 100vw;
      z-index: -1;
      transform: translateY(50%);
      @media (max-width: 1000px) {
        width: 100%;
        transform: translateY(50%);
        width: calc(100% + 16px);
        height: 149px;
        bottom: 50%;
      }
    }

    &--right {
      &:after {
        left: 0;
      }
    }
    &--left {
      justify-content: flex-start;
      .svg {
        right: 0;
      }
      &:after {
        right: 0;
        border-radius: 0px 15px 15px 0px;
      }
    }
    &--update {
      @media (max-width: 1000px) {
        margin-bottom: 47px;
        margin-top: 47px;
        height: 149px;
      }
      .svg {
        transform: translate(0, -50%);
        left: 0;
        @media (max-width: 1000px) {
          transform: translate(0, -50%);
          top: 65% !important;
          position: absolute;
        }
      }
    }
    &--monitoring {
      @media (max-width: 1000px) {
        position: relative;
        min-height: 300px;
        &:after {
          bottom: 53%;
        }
      }
    }
    .svg--monitoring {
      right: -50px;
      transform: translate(0, -85%);
      @media (max-width: 1000px) {
        transform: unset;
        right: -200px;
        position: absolute;
        transform: translate(0, -15%);
      }
    }
  }

  &__trick-button {
    margin-top: 48px;
    font-size: 13px;
  }

  &__ups {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    z-index: 1;
    position: absolute;
    top: 70%;
    right: 50%;
    transform: translate(50%, 20%);
    @media (max-width: 1000px) {
      right: unset;
      left: 0;
      transform: unset;
      top: 47%;
      height: 48px;
    }
    &--bold {
      color: var(--Pomaraczowy-500, #f29225);
      font-size: 18px;
      font-weight: 800;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.045px;
    }

    &-content {
      position: relative;
      color: var(--Czarny-300, #333);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      white-space: nowrap;
      @media (max-width: 1000px) {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .svg {
      position: absolute;
      left: 95%;
      top: 95%;
      transform: unset;
      @media (max-width: 1000px) {
        height: 72.956px;
        width: auto;
      }
    }
  }

  &__why-i-need-corgi {
    display: flex;
    flex-direction: column;
    margin-top: 400px;
    @media (max-width: 1000px) {
      margin-top: 300px;
      margin-bottom: 40px;
    }
  }

  &__why-i-need-corgi-list {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 94px;
    position: relative;
    margin-bottom: 140px;
    @media (max-width: 1000px) {
      padding-top: 40px;
    }
  }

  &__why-i-need-corgi-list-item {
    border-radius: 10px;
    background: var(--Szary-100, #f2f2f2);
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    max-width: calc(50% - 12px);
    @media (max-width: 1000px) {
      max-width: 100%;
    }
  }

  &__why-i-need-corgi-list-item-wrapper {
    position: relative;
    max-width: calc(50% - 12px);
    .how-it-works__why-i-need-corgi-list-item {
      width: 100%;
      max-width: 100%;
    }
    @media (max-width: 1000px) {
      max-width: 100%;
    }
  }

  &__hosting {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: calc(100% - 18px);
    width: 400px;
    height: auto;
    @media (max-width: 1000px) {
      width: 100%;
      max-width: 252px;
      bottom: calc(100% - 30px);
    }
  }

  &__why-i-need-corgi-bg {
    position: absolute;
    left: -45px;
    bottom: 24px;
    z-index: -1;
    .svg {
      transform: rotate(-90deg);
      width: 95px;
      height: 137px;
    }

    @media (max-width: 1000px) {
      top: -52px;
      right: 0;
      left: unset;
      .svg {
        transform: rotate(0deg);
        width: 74px;
        height: 106px;
      }
    }
  }
  .homepage__calendry-wrapper {
    @media (max-width: 1000px) {
      margin-top: -140px;
    }
  }
}
