.packets {
  background-color: #f1f1f1;
  position: relative;
  @include lg {
    padding-top: 60px;
  }
  .packets__wrapper-header {
    padding-top: 50px;
    display: grid;
    justify-content: center;
    @include md {
      padding-top: 0;
    }
    h2 {
      display: block;
      justify-self: center;
      text-align: center;
      max-width: 260px;
      width: 100%;
      padding: 0 20px;
      @include md {
        max-width: 100%;
      }
    }
    p {
      color: #3e3e3e;
      margin: 16px 40px 0 40px;
      font-size: 14px;
      opacity: 0.75;
      text-align: center;
      @include md {
        margin-top: 0;
        font-size: 16px;
      }
      @include lg {
        font-size: 18px;
        margin-bottom: 100px;
        opacity: 0.7;
        font-weight: $weight__normal;
        color: #4b4b4b;
      }
      @include xl {
        font-size: 20px;
        margin-top: 30px;
      }
    }
  }
  &__choice {
    border: 2px solid #352fee;
    border-radius: 36px;
    display: flex;
    font-size: 20px;
    justify-self: center;
    justify-content: space-between;
    position: relative;
    margin: 30px 0;
    -webkit-tap-highlight-color: transparent;
    div {
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      border-radius: 36px;
      padding: 8px 20px;
      font-size: 15px;
    }
    @include lg {
      margin: 56px 0 28px 0;
      width: 348px;
      div {
        font-size: 20px;
        padding: 15px 39px;
      }
    }
    @include xl {
      margin: 70px 0 35px 0;
      width: 437px;
      div {
        padding: 15px 60px;
      }
    }
  }
  &__choice-active {
    background-color: #352fee;
    color: #fff;
    transition-duration: 0.5s;
  }

  &__items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // align-items: baseline;
    padding-bottom: 20px;
  }
  &__item {
    max-width: 330px;
    margin: 25px 50px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1366px) {
      margin: 25px 30px;
    }
    &--disable {
      // opacity: 0.3;
      color: rgba(255, 255, 255, 0.3);
      &-black {
        color: rgba(0, 0, 0, 0.3);
      }
      &:after {
        content: '';
        background-image: url('../../Image/Icon/x.svg') !important;
        background-position: center;
        background-size: cover;
        width: 12px !important;
        height: 12px !important;
        left: -22px !important;
      }
    }
    hr {
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.18);
      height: 0;
    }
  }
  &__item-header {
    display: grid;
    position: relative;
    img {
      justify-self: center;
      margin-bottom: -60px;
      @media (min-width: 1140px) {
        position: absolute;
        right: -112px;
        top: -34px;
        width: 220px;
        object-fit: cover;
      }
    }
    h3 {
      font-size: 35px;
      font-weight: $weight__max;
      margin-left: 10px;
      color: #373737;
    }
    P {
      opacity: 0.4;
      font-size: 14px;
      margin-left: 10px;
      color: #373737;
    }
    &-packets-plus {
      margin-left: 30px !important;
      position: relative;
      &:after {
        content: '';
        font-size: 20px;
        color: orange;
        left: -26px;
        top: 2px;
        position: absolute;
        background-image: url('../../Image/Icon/icon_plus.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
    }
  }
  &__item-content {
    border-radius: 16px;
    min-width: 280px;
    margin: 0 0 36px 0;
    padding: 0 20px;
    box-shadow: 0px 3px 40px #00000029;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition-duration: 0.4s;
    height: 100%;
    // @include md {
    //   height: 750px;
    // }
    &-smaller {
      min-height: 503px;
    }
    ul {
      padding: 20px 0 24px 0;
      height: 100%;
      // min-height: 350px;
    }
    li {
      font-size: 14px;
      margin-left: 25px;
      padding-right: 20px;
      padding: 7px 50px 7px 0;
      line-height: 15px;
      position: relative;
      font-weight: $weight__tiny;
      &:after {
        content: '';
        left: -28px;
        position: absolute;
        top: 27%;
        background-image: url('../../Image/Icon/icon_v.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
    }
    section {
      padding: 7px 50px 14px 20px;
      font-size: 14px;
      color: #000000;
      opacity: 0.4;
      line-height: 14px;
    }
    &-blue {
      background-color: #352fee;
      color: #fff;
    }
    &-black {
      background-color: #212121;
      color: #fff;
    }
    &-blue,
    &-black {
      section {
        color: #fff;
        opacity: 0.4;
      }
    }
  }
  &__item-footer-wrapper {
    padding: 20px 0;
    border-top: 2px solid rgba(255, 255, 255, 0.18);
    &-black {
      border-top: 2px solid #707070;
    }
  }
  &__item-content-footer {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    width: calc(100% - 38px);
    @media (min-width: 635px) {
      margin: 0 18px;
    }
    p {
      font-size: 12px;
      font-weight: $weight__bold;
      line-height: 15px;
    }
    span {
      font-size: 10px;
      font-family: $fontLato;
      opacity: 0.44;
    }

    strong {
      font-size: 34px;
      font-weight: $weight__bold;
      margin-right: 20px;
      position: relative;
      .packets__item-content-footer-currency {
        content: 'PLN';
        font-size: 14px;
        position: absolute;
        right: -80px;
        top: 5px;
        opacity: 1;
      }
      .packets__item-content-footer-currency-prev {
        content: 'PLN';
        font-size: 9px;
        position: absolute;
        right: -68px;
        top: -5px;
        opacity: 1;
        color: #909090;
      }
      .packets__item-content-footer-vat {
        content: '+VAT';
        font-size: 14px;
        position: absolute;
        right: -80px;
        top: 21px;
        font-weight: $weight__normal;
        opacity: 0.44;
        font-size: 8px;
      }
    }
    .packets__item-content-footer-prev-price {
      font-size: 18px;
      color: #909090;
      position: relative;
      font-weight: 500;
      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 45%;
        right: -17px;
        // width: 40px;
        border-top: 1px solid;
        border-color: inherit;
        -webkit-transform: skewY(-15deg);
        -moz-transform: skewY(-15deg);
        transform: skewY(-15deg);
      }
    }
    &-hidden {
      display: none;
    }
    div {
      display: flex;
      align-items: center;
    }
  }
  &__item-details {
    display: flex;
    justify-content: center;
    border-top: 2px solid rgba(112, 112, 112, 0.27);
    cursor: pointer;
    &-white {
      color: rgba(255, 255, 255, 0.5) !important;
    }
    p {
      padding: 20px 0;
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      font-weight: $weight__normal;
    }
  }
  &__item-footer {
    .btn {
      width: 100%;
      justify-content: center;
      display: flex;
      text-align: center;
    }
    .btn__icon {
      position: absolute;
      right: 30px;
    }
    .btn--wrapper {
      display: flex;
      justify-content: center;
      @media (min-width: 1140px) {
        justify-content: right;
      }
    }
    &-second {
      .btn--wrapper:first-child {
        display: none;
      }
    }
    &-first {
      .btn--wrapper:nth-child(2n) {
        display: none;
      }
    }
  }
}

.CardField {
  width: 100%;
  display: none;
}
.ElementsApp .CardField {
  display: none;
}
.StripeElement {
  background: white;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 15px 10px;
  .CardField-input-wrapper {
    display: none;
  }
  @media screen and (min-width: 756px) and (max-height: 744px) {
    padding: 11px 10px;
  }
  @media screen and (min-width: 756px) and (max-height: 660px) {
    padding: 9.5px 10px;
  }
  @media screen and (min-width: 756px) and (max-height: 630px) {
    padding: 6.5px 10px;
  }
}

.packagesType__wrapper {
  label {
    color: #1c1c1c;
    font-size: 18px;
    font-weight: $weight__bold;
    padding-bottom: 15px;
    display: block;
  }
}
.packagesItem {
  width: 100%;
  padding: 13px 65px 13px 13px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 5px 0;
  cursor: pointer;
  &--payment {
    cursor: auto;
    background: rgba(0, 0, 0, 0.06) 0% 0% no-repeat padding-box;
  }
  &__label {
    display: flex;
    align-items: center;
    &--name {
      font-size: 17px;
      font-weight: $weight__bold;
    }
  }
  &__image {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  &__price {
    position: relative;
    &--value {
      font-size: 24px;
      font-weight: $weight__bold;
    }
    &--currency {
      font-size: 13px;
      font-weight: $weight__bold;
      position: absolute;
      top: 2px;
      right: -25px;
    }
    &--tax {
      font-size: 8px;
      font-weight: $weight__bold;
      position: absolute;
      top: 17px;
      right: -25px;
      color: rgba(0, 0, 0, 0.4);
    }
    &--img {
      position: absolute;
      right: -55px;
      width: 15px;
      height: 15px;
      top: 10px;
      display: none;
    }
  }
  &--active {
    background-color: #000000;
    .packagesItem__label--name,
    .packagesItem__price--value,
    .packagesItem__price--currency {
      color: #ffffff;
    }
    .packagesItem__price--tax {
      color: rgba(255, 255, 255, 0.44);
    }
    .packagesItem__price--img {
      display: block;
    }
  }
}
