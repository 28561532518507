.diagnosticModal {
  &__heading {
    // width: 284px;
    height: 30px;
    font-size: 22px;
    font-weight: 900;
    line-height: 36px;
  }

  &__dashboard {
    margin-top: 30px;

    &--chooseAll {
      margin-left: 57px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
    }

    &--upperSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--date {
      font-size: 14px;
      font-weight: 500;
    }

    &--row {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &--btns {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &--details {
      margin: 10px auto auto 55px;
      max-height: 209px;
      overflow: scroll;
      // background: transparent;
    }

    &--inProgress {
      font-size: 12px;
      text-align: center;
      color: red;
    }
  }
}

.checkBox {
  width: 55px;
  height: 51px;
  display: flex;
  align-items: center;

  &__btn {
    width: 25px;
    height: 25px;
    border-radius: 6px;
    border: 2px solid #bfbfbf;
    cursor: pointer;

    &--checked {
      background: #352fee;
      border: 2px solid #1913bf;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.subRow {
  width: 639px;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: 0.25fr 3fr 0.4fr 0.25fr;
  align-items: center;
  border-radius: 5px;

  &__number {
    padding-left: 15px;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }

  &__name {
    font-size: 13px;
    font-weight: 500;
    line-height: 36px;
  }

  &__dropDown {
    cursor: pointer;
  }
}

.missed {
  opacity: 0.3;
}

.errorMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #666666;
  text-align: left;
}
