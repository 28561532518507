.reviews-slider {
  margin-top: 88px;
  overflow: hidden;
  @media (max-width: 1000px) {
    margin-top: 48px;
  }
  &__container {
    display: flex;
    // gap: 20px;
  }

  .review {
    border-radius: 12px;
    background: var(--Szary-100, #050099);
    flex: 0 0 100%;
    min-width: 0;
    min-height: 367px;
    position: relative;
    overflow: hidden;
    padding: 48px 56px;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    margin-right: 20px;
    @media (max-width: 1000px) {
      padding: 32px 16px;
      flex-direction: column-reverse;
    }

    &__logo {
      &--desktop {
        @media (max-width: 1000px) {
          width: 0px !important;
          height: 0px !important;
          position: absolute;
          right: -100px;
        }
      }
      &--mobile {
        display: none;
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      z-index: 1;
      @media (max-width: 1000px) {
        justify-content: flex-end;
      }
    }

    &__right {
      z-index: 1;
      max-width: 457px;
      position: relative;

      .review__logo {
        margin-bottom: 32px;
      }

      h2 {
        color: var(--Czarny-300, #fff);
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.045px;
        @media (max-width: 1000px) {
          font-weight: 600;
        }
      }
      p {
        color: var(--Szary-600, #f2f2f2);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-top: 16px;
        @media (max-width: 1000px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      padding-top: 40px;
      gap: 16px;
    }

    &__button {
      border-radius: 30px;
      border: 2px solid var(--Niebieski-500, #f2f2f2);
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &--next {
        transform: rotate(180deg);
      }
      path {
        stroke: #f2f2f2;
      }
    }

    &__apostrophe {
      position: absolute;
      top: 0;
      left: -90px;
      z-index: 0;
      @media (max-width: 1000px) {
        display: none;
      }
    }

    &__person {
      display: flex;
      align-items: center;
      gap: 40px;
      z-index: 1;
      @media (max-width: 1000px) {
        align-items: flex-start;
        gap: 16px;
      }
      &--center {
        margin: auto 0;
      }
      img {
        max-width: 100px;
        @media (max-width: 1000px) {
          max-width: 72px;
        }
      }
    }

    &__person-details {
      h3 {
        color: var(--Czarny-400, #fff);
        font-size: 18px;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
        letter-spacing: -0.045px;
        @media (max-width: 1000px) {
          font-size: 16px;
        }
      }
      p {
        color: var(--Szary-600, #d9d9d9);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      a {
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        border-radius: 30px;
        border: 2px solid #fff;
        height: 32px;
        padding: 0 24px 0 20px;
        display: flex;
        gap: 8px;
        width: fit-content;
        margin-top: 24px;
        @media (max-width: 1000px) {
          margin-top: 16px;
        }
      }
    }

    &__background {
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 70%;
      path {
        fill: linear-gradient(138deg, #1d17d3 11.55%, #050099 71.7%);
        background: linear-gradient(138deg, #1d17d3 11.55%, #050099 71.7%);
      }
      @media (max-width: 1000px) {
        height: 40%;
      }
    }
  }
  &__background {
    position: absolute;
    bottom: -140px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    @media (max-width: 1000px) {
      display: none;
    }
  }
}
