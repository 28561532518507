.number {
  display: flex;
  max-width: 800px;
  margin: 80px 0;
  @media (max-width: 550px) {
    flex-wrap: wrap;
    margin: 60px 0;
  }

  &__wrapper {
    h1 {
      @media (max-width: 550px) {
        margin-top: 30px;
        margin-bottom: -30px;
      }
      @media (max-width: 500px) {
        font-size: 60px;
      }
    }
  }
  &__numb {
    font-size: 155px;
    color: #ffffff;
    opacity: 0.42;
    font-weight: $weight__max;
    position: relative;
    min-width: 170px;
    @media (max-width: 1500px) {
      font-size: 130px;
    }
    @media (max-width: 1100px) {
      font-size: 120px;
      min-width: 140px;
    }
    @media (max-width: 550px) {
      font-size: 78px;
    }
    @media (max-width: 500px) {
      font-size: 60px;
    }
    p {
      position: absolute;
      top: -40px;
      @media (max-width: 550px) {
        position: static;
        top: 0;
      }
    }
    &-1 {
      left: 30px;
      @media (max-width: 1100px) {
        left: 20px;
      }
    }
    &-4 {
      left: -10px;
    }
  }
  &__content {
    h3 {
      font-size: 35px;
      color: #ffffff;
      opacity: 0.93;
      font-weight: $weight__max;
      @media (max-width: 1100px) {
        font-size: 30px;
      }
      @media (max-width: 500px) {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }
    p {
      color: #ffffff;
      opacity: 0.63;
      font-weight: $weight__max;
      margin: 15px 0 8;
      @media (max-width: 1100px) {
        font-size: 15px;
      }
      @media (max-width: 500px) {
        margin-bottom: 20px;
      }
    }
    span {
      color: #f1f1f1;
      opacity: 0.6;
      font-size: 17px;
      margin: 8px 0 20px;
      display: block;
      @media (max-width: 1100px) {
        font-size: 15px;
      }
    }
  }
  &__list {
    display: flex;
    @media (max-width: 500px) {
      flex-wrap: wrap;
    }
    ul {
      width: 50%;
    }

    li {
      padding: 10px 20px;
      color: #ffffff;
      font-size: 14px;
      background-image: url('../../Image/Icon/icon_v.svg');
      background-repeat: no-repeat;
      background-position-x: -2px;
      background-position-y: center;
      @media (max-width: 1100px) {
        padding: 6px 20px;
      }
    }
  }
  &_2,
  &_4 {
    margin-left: auto;
    max-width: 823px;
    margin-right: 150px;
    @media (max-width: 1600px) {
      margin-right: 20px;
    }
    @media (max-width: 1450px) {
      margin-right: 0;
    }
  }
}

.proBono{
  display: flex;
  justify-content: space-between;
  gap: 150px;
  max-width: 100%;
  align-items: center;

  @media(max-width: 1023px){
    gap:20px;
    justify-content: center;

    &__image3{
      padding-top:50px;
      width:236px;
    }

    &__btn{
      margin:50px auto auto auto;
    }
  
    &__image1{
      width:155px;
    }

    &__image2{
      width:195px;
    }

    p{
      width: 280px;
    }

  }



  @media(min-width: 1920px){
    max-width:80%;
  }

  &__content{
    max-width:800px;

    span{
      padding-top:30px;
    }
   
    @media(min-width:1920px){
      max-width:1200px;
    }
  }
}

.patrol {
  display: flex;
  margin-top: 100px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media (max-width: 900px) {
    margin-top: 40px;
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__packet {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    @media (max-width: 1000px) {
      width: auto;
    }
    @media (max-width: 900px) {
      justify-content: flex-start;
    }
    &-deskt {
      display: none;
      display: block;
      text-align: center;
      color: #ffffff;
      opacity: 0.29;
      margin-top: 5px;
      @media (max-width: 1000px) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @media (max-width: 1000px) {
        display: block;
        text-align: center;
        margin-top: 5px;
        color: #ffffff;
        opacity: 0.29;
      }
    }
    .btn--wrapper {
      width: 220px;
    }
    button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      @media (max-width: 1100px) {
        font-size: 14px;
      }
      @media (max-width: 1000px) {
        display: none;
      }
    }
    &-img {
      @media (max-width: 1000px) {
        max-width: 200px;
        &-second {
          max-width: 100%;
        }
      }
      @media (max-width: 600px) {
        max-width: 150px;
      }
      &-second img {
        width: 100%;
      }
    }
    &--button button {
      margin-top: 0;
    }
  }
  .number__content {
    margin-left: 110px;
    max-width: 750px;
    @media (max-width: 1000px) {
      margin-left: 0;
      margin-top: 30px;
    }
    h3,
    p {
      margin-bottom: 20px;
    }
    .btn--wrapper {
      display: none;
      @media (max-width: 1000px) {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
    }
  }
  .number__list {
    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }
    li {
      background-image: url('../../Image/Icon/icon_v.svg');
      background-position-x: 0px;
      padding: 10px 25px;
    }
  }
}
