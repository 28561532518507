.websitesDetails {
  &__top {
    display: flex;
    min-height: 260px;
    @media (max-width: 1300px) {
      flex-direction: column;
    }
  }

  &__services {
    display: flex;
    min-height: 80px;
    width: 63%;
    justify-content: flex-start;
    border: 1px solid rgba(112, 112, 112, 0.12);
    border-radius: 12px;
    margin: 10px 0 10px auto;

    .switcherWrapper__button {
      margin-left: 20px;
    }

    .wrapper  {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .switcher-label{
      font-size: 14px;
      font-weight: bold;
      padding: 5px;
    }

    .checkbox-wrapper {
      display: flex;
      justify-content: flex-start;
      margin-left: 8px;
    }
  }
}
.UserInfoPayments {
  background-color: rgba(128, 128, 128, 0.1);
  border-radius: 15px;
  padding: 20px 0;
  max-width: 400px;
  min-height: 220px;
  display: flex;
  flex-wrap: wrap;
  &__wrapper {
    max-width: 400px;
    width: 100%;
  }
  &__helper {
    font-size: 12px;
    color: #0e0e0e;
    padding-left: 20px;
    .UserInfoPayments__helper--btn {
      font-size: 24px !important;
    }
    @media (max-width: 768px) {
      margin-left: 20px;
    }
    @media (max-width: 410px) {
      margin-left: 0;
    }
  }
  &__switcher {
    width: calc(100% - 40px);
    border: 1px solid black;
    margin: 15px 20px;
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    &--item {
      padding: 9px 0;
      width: 50%;
      text-align: center;
      cursor: pointer;
    }
    &--item--active {
      transition: 0.2s;
      padding: 9px 0;
      border-radius: 8px;
      background-color: #000;
      color: #fff;
    }
  }
  &__heading {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid rgba(112, 112, 112, 0.12);
    padding-bottom: 6px;
    padding: 10px 20px 5px 0;
    margin-left: 20px;
    width: calc(100% - 40px);
    margin-bottom: 12px;
  }

  &__bottom {
    display: flex;
    width: 100%;
  }
  // &__r .loader {
  //   padding-top: 26px;
  // }
  &__l,
  &__r {
    width: 50%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 145px;
    align-content: space-between;
    margin-top: 18px;
    .loader {
      padding-top: 26px;
    }
    @media (max-width: 768px) {
      padding: 0;
      .badge {
        display: flex;
        justify-content: center;
        font-size: 9px;
      }
    }
    .btn--wrapper {
      display: flex;
      justify-content: center;
    }
    .btn {
      max-width: 140px;
      max-height: 42px;
    }
  }
  &__r {
    .btn {
      border-radius: 5px;
      font-size: 12px;
      padding: 7px 25px;
      text-transform: lowercase;
    }
    &--noPayments {
      align-content: unset;
    }
  }
  &__l {
    align-content: space-between;
    &--helper {
      font-weight: $weight__bold;
      font-size: 14px !important;
    }
  }

  &__dropDown {
    margin: auto auto 5px 50px;
      &--price {
        margin-right: 50px;
        .react-select__control .react-select__indicators .react-select__indicator-separator {
          display: none !important;
        }
        .form__field--select {
          width: 80px;
          height: 32px;

          .react-select__control {
            background-color: #6962601a;
            border-radius: 5px;
          }

          .react-select__menu {
            font-size: 12px;
          }
        }

        @media (max-width: 1024px) {
          margin-right: 20px;
        }
      }
  }

  &__userName,
  &__money,
  &__numberSites {
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
  }
  &__money {
    font-size: 24px;
    width: 100%;
    margin-bottom: 7px;
    &--bigSize {
      margin-bottom: -48px;
    }
    &--bigSize-customMargin {
      margin-bottom: -9px;
    }
  }
  &__userName {
    font-size: 15px;
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 5px;
    text-transform: capitalize;
    span:last-child {
      margin-left: 5px;
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
  &__numberSites {
    font-size: 28px;
    padding-bottom: 13px;
    width: 100%;
  }

  .badge {
    margin-bottom: 41px;
    width: 60%;
    justify-content: center;
  }
  .btn--wrapper {
    width: 100%;
  }
  .btn {
    width: 100%;
    justify-content: center;
  }
}

.websitesInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
    &:first-child {
      margin-bottom: 20px;
    }
  }
  .btn {
    text-transform: lowercase;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 60px;
    border: 1px solid rgba(112, 112, 112, 0.12);
    border-radius: 12px;
    padding: 30px 20px 20px 20px;
    min-height: 232px;
    @media (max-width: 1300px) {
      margin-left: 0;
      margin-top: 20px;
    }
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }
  }
  &__heading {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid rgba(112, 112, 112, 0.12);
    padding-bottom: 6px;
  }
  &__counters {
    display: flex;
    justify-content: space-between;
    text-transform: lowercase;
    @media (max-width: 1300px) {
      margin-bottom: 20px;
    }
    &__empty {
      @media (max-width: 1300px) {
        margin-top: 20px;
      }
      .infoCounter {
        flex-shrink: 0;
        margin-right: 15px;
      }
    }
  }
  &__counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33%;
    cursor: pointer;
    margin: 0 5px;
    &--red:hover {
      background-color: rgba(255, 64, 24, 0.1) !important;
    }
    &--green:hover {
      background-color: rgba(56, 213, 139, 0.09) !important;
    }
    &:hover {
      background-color: rgba(105, 98, 96, 0.1);
      border-radius: 12px;
      transition: 0.4s;
      .badge {
        transition: 0.3s;
        background-color: transparent;
      }
    }
    @media (max-width: 768px) {
      margin: 25px 0;
    }
    .badge {
      justify-content: center;
      // margin: 0 5px;
      @media (max-width: 1407px) {
        font-size: 8px;
      }
    }
    &__text {
      font-size: 12px;
      max-width: 230px;
      display: flex;
      align-items: center;
    }
  }
  &__number {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
    &--red {
      color: #fc5024;
    }
    &--green {
      color: #38d58b;
    }
  }
  .btn--wrapper {
    display: flex;
    justify-content: flex-end;
  }
}

.websitesSpecific {
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  border: 1px solid rgba(112, 112, 112, 0.12);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__footer .btn--wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    max-width: 350px;
    @media (max-width: 800px) {
      max-width: 100%;
    }
  }
  &__footer {
    @media (min-width: 1370px) {
      position: absolute;
      bottom: 0;
      right: 40px;
    }
  }

  &__item {
    width: 33%;
    max-width: 415px;
    position: relative;

    // @media (max-width: 1200px) {
    //   display: flex;
    //   flex-wrap: wrap;
    //   align-content: space-between;
    // }
    @media (max-width: 1024px) {
      margin-top: 20px !important;
      width: 100%;
    }
    @media (max-width: 800px) {
      max-width: 100%;
      width: 100%;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 40px;
      margin-bottom: 20px;
      @media (max-width: 1300px) {
        padding-right: 20px;
      }
      @media (max-width: 1024px) {
        padding-right: 40px;
      }
      @media (max-width: 768px) {
        padding-right: 0;
      }
    }
    &__heading {
      display: flex;
      display: flex;
      align-items: center;
    }
    &__heading p {
      font-size: 18px;
      font-weight: 700;
      margin-left: 15px;
      @media (max-width: 1300px) {
        margin-left: 10px;
      }
    }
    &__count {
      min-width: 22px;
      min-height: 22px;
      background-color: blue;
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 5px;
    }
    &__tooltip {
      cursor: default;
    }
  }
  &__item:first-child {
    .websitesSpecific__item__header {
      max-width: 415px;
      @media (max-width: 800px) {
        max-width: 100%;
      }
    }
    .websitesSpecifications {
      border-right: 1px solid rgba(112, 112, 112, 0.12);
      max-width: 415px;
      @media (max-width: 800px) {
        max-width: 100%;
      }
      @media (max-width: 1024px) {
        border: 0;
      }
    }
  }

  &__item:nth-child(2) {
    .websitesSpecifications {
      border-right: 1px solid rgba(112, 112, 112, 0.12);
      @media (max-width: 1024px) {
        border: 0;
      }
    }
  }
  &__item:nth-child(2),
  &__item:nth-child(3) {
    padding-left: 40px;
    @media (max-width: 1300px) {
      padding-left: 20px;
    }
    @media (max-width: 1024px) {
      padding-right: 0;
    }
    @media (max-width: 800px) {
      padding-left: 0;
    }
  }
  &__item:nth-child(3) {
    @media (max-width: 1024px) {
      margin-top: 50px;
      padding-left: 0;
    }
  }
  &__website {
    .websitesSpecifications {
      height: auto;
      min-height: auto;
      padding-bottom: 30px;
    }
    .websitesSpecific__item {
      &:nth-child(2),
      &:nth-child(3) {
        max-width: 100%;
        padding-left: 0;
        @media (max-width: 1500px) {
          padding-left: 20px;
        }
        @media (max-width: 1024px) {
          padding-left: 0;
        }
      }
    }
  }
}

.websitesSpecifications {
  padding-right: 40px;
  width: 100%;
  height: calc(100% - 52px);
  min-height: 225px;
  @media (max-width: 1300px) {
    padding-right: 20px;
  }
  @media (max-width: 1024px) {
    padding-right: 40px;
  }
  @media (max-width: 768px) {
    padding-right: 0;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    max-width: 350px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    width: 100%;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      min-height: 100px;
      align-content: center;
    }
    &__empty {
      background-color: rgba(128, 128, 128, 0.1);
      border-radius: 15px;
      display: flex;
      justify-content: center;
      font-size: 12px;
      padding: 25px 20px;
      width: 100%;
      line-height: 18px;
      p {
        max-width: 200px;
      }
    }
    @media (max-width: 800px) {
      max-width: 100%;
    }
    @media (max-width: 700px) {
      padding-left: 0;
    }
    &:hover {
      background-color: #0000000a;
      transition: 0.2s;
      border-radius: 10px;
    }
    &--l {
      display: flex;
      align-items: center;
      width: calc(100% - 110px);
      // @media (max-width: 1200px) {
      //   width: calc(100% - 160px);
      // }
    }
  }
  &__heading {
    font-size: 12px;
    width: 100%;
    &-space {
      margin-right: 5px;
    }
    &--bold {
      font-weight: 500;
      display: flex;
      flex: 1;
      min-width: 0;
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        @media (max-width: 800px) {
          max-width: 400px;
        }
        @media (max-width: 600px) {
          max-width: 300px;
        }
        @media (max-width: 500px) {
          max-width: 200px;
        }
        @media (max-width: 400px) {
          max-width: 150px;
        }
      }
    }
    &--gray {
      opacity: 0.26;
    }
  }
  &__data {
    font-size: 12px;
    opacity: 0.28;
    font-weight: 500;
    flex-shrink: 0;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 1px;
  }
}

.reportSection{
  width: 450px;
  padding-top: 50px;
  form{
    display: flex;
    justify-content: flex-start;
    gap: 30px;
  }
  .form__field .react-select__control{
    width: 200px;
  }
}