.data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000000;

    &__row {
        & td,
        th {
            padding: 15px 35px;
            text-align: left;
        }

        & td:first-child {
            border-left-style: solid;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & td:last-child {
            border-right-style: solid;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
        }

        &--light {
            background: #ffffff;
        }
    }
}

.table-loader {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(1px);
}

.table-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    margin-left: 30px;

    &__errors{
        padding:12px;
        color: red;
        font-size:12px;
        font-weight: bold;
    }

    &__date {
        padding: 12px;
        background: #ffffff;
        border: none;
        border-radius: 5px;
    }

    &__filter-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 34px;
        color: #ffffff;
        font-size: 12px;
        font-style: normal;
        font-weight: bold;
        line-height: 18px;
        background: #0e0e0e;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;

        &::after {
            content: "";
            width: 25px;
            height: 25px;
            background-image: url("../../Image/Icon/filter.svg");
            background-repeat: no-repeat;
            background-position: center right;
        }

        &:hover {
            opacity: 0.7;
            transition: opacity 0.3s ease-in-out;
        }

        &:active {
            opacity: 0.5;
        }
    }
}
