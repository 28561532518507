.packages {
  max-width: 1136px;
  margin-top: 16px;
}
.packagesTop {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  align-items: flex-start;
  position: relative;
  z-index: 99;
  @media (max-width: 850px) {
    display: none;
  }
  @media (min-width: 1000px) {
    min-height: 127px;
  }
  &--head {
    font-size: 49px;
    color: #111111;
    font: Poppins;
    opacity: 1;
    max-width: 268px;
    line-height: 55px;
    font-weight: $weight__max;
    letter-spacing: -2px;

    font-size: 36px;
    line-height: 46px;

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &--fixed {
    .packagesTop__wrap {
      position: relative;
    }
    .packagesTop__content {
      position: fixed;
      top: 40px;
      height: 70px;
    }
    .packagesTop__button {
      opacity: 0;
      z-index: 0;
      pointer-events: none;
    }
    .packagesTop--image {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__content {
    border-radius: 12px;
    height: 127px;
    width: 100%;
    padding: 9px 24px;
    position: relative;
    transition: transform 0.8s ease-in-out;
    max-width: 269px;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.16);
    &--basic {
      background: var(--Szary-100, #f2f2f2);
    }
    &--premium {
      background-color: #352fee;
      .packagesTop__price {
        color: #f2f2f2;
      }
    }
    &--pro {
      background-color: #212121;
      .packagesTop__price {
        color: #f2f2f2;
      }
    }
  }
  &__button {
    display: flex;
    height: 50px;
    padding: 9px 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: #fff;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .btn--wrapper {
      width: 100%;
    }
    .btn {
      width: 100%;
      border-radius: 4px;
      background: var(--Niebieski-500, #352fee);
      color: #fff;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 0;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__name {
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: -0.045px;

    &--white {
      color: #ffffff;
    }
  }
  &--image {
    position: absolute;
    top: -10px;
    width: 54px;
    right: 16px;
    z-index: 9999999;
    height: auto;
    transition: 0.3s;
  }
  &__type {
    font-size: 14px;
    font-weight: $weight__normal;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      font-size: 10px;
    }
    &--black {
      color: #373737;
      opacity: 0.43;
    }
    &--white span {
      color: #ffffff;
      opacity: 0.61;
    }
    &--plus {
      margin-right: 3px;
      width: 6px;
    }
  }
  &__price {
    color: var(--Czarny-300, #333);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.045px;
    display: flex;
    align-items: flex-end;
    gap: 6px;
    margin-top: 2px;
    span {
      color: var(--Szary-500, #808080);
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 2px;
      @media (min-width: 1000px) {
        color: #bfbfbf;
      }
    }
  }

  &__image {
    @media (min-width: 1000px) {
      display: none;
    }
    display: flex;
    justify-content: center;
    &-mobile {
      max-width: 35px;
      height: auto;
    }
    &-mobile-gray {
      opacity: 0.35;
    }
  }
}
.svg--information {
  .a {
    opacity: 1;
  }
  .b {
    fill: transparent;
  }
  .c {
    fill: #808080;
    color: #808080;
  }
}

.packagesTakePreorder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  margin-top: 20px;
  @media (max-width: 850px) {
    display: none;
  }
  &--text {
    color: #4b4b4b;
    font-weight: $weight__normal;
    font-size: 20px;
    max-width: 220px;
    line-height: 30px;
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }
  .btn--wrapper {
    max-width: 308px;
    .btn {
      width: 100%;
      justify-content: center;
      @media (max-width: 1110px) {
        font-size: 14px;
      }
      @media (max-width: 1036px) {
        font-size: 12px;
      }
      @media (max-width: 968px) {
        padding: 12px 15px;
      }
    }
  }
  @media (min-width: 1000px) {
    .btn--wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .btn {
      max-width: 218px;
      width: 100%;
      border-radius: 4px;
      background: var(--Niebieski-500, #352fee);
      color: #fff;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 0;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.packagesTable {
  margin-top: 35px;
  @media (max-width: 850px) {
    // background-color: rgba(255, 255, 255, 0.35);
  }
  &--head-mobile {
    font-size: 25px;
    font-weight: $weight__max;
    padding: 0 15px;
    max-width: 260px;
    @media (min-width: 850px) {
      display: none;
    }
    &-padding {
      padding: 40px 15px 0px 15px;
    }
  }
  &--headSmall-mobile {
    font-size: 17px;
    font-weight: $weight__normal;
    padding: 0 15px;
    opacity: 0.6;
    max-width: 260px;
    @media (min-width: 850px) {
      display: none;
    }
    &-padding {
      padding: 40px 15px 40px 15px;
    }
  }
  &__line {
    position: relative;
    display: grid;
    grid-template-columns: 286px 1fr 1fr 1fr;
    column-gap: 20px;
    align-items: center;
    min-height: 64px;
    border-radius: 6px;
    transition: 0.3s;
    @media (max-width: 1000px) {
      grid-template-columns: 4fr 48px 48px 48px;
      padding-right: 9px;
      column-gap: 10px;
    }

    &-desktop {
      @media (max-width: 1000px) {
        display: none;
      }
    }
    &--bottom {
      @media (max-width: 1000px) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.19);
      }
    }
    &:hover {
      background: #fff;
    }
  }
  &--text1 {
    font-size: 14px;
    line-height: 14px;
    font-weight: $weight__normal;
    opacity: 0.6;
    max-width: 240px;
    padding-left: 20px;

    display: flex;
    align-items: center;
    span,
    svg {
      margin-right: 20px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    &-bold {
      opacity: 1;
      font-weight: $weight__max;
    }
  }

  &__scalable-chevron {
    display: flex;
    gap: 8px;
    padding-left: 20px;
    .svg {
      width: 12px;
      height: 12px;
      transition: 0.3s;
    }
    &--up {
      .svg {
        transform: rotate(180deg);
      }
    }
  }

  &__scalable-click {
    @media (min-width: 1000px) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.19);
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 1000px) {
      margin-top: 40px;
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &--description {
    font-size: 14px;
    line-height: 14px;
    font-weight: $weight__normal;
    max-width: 286px;
    padding-left: 20px;
    color: #262626;
    display: flex;
    align-items: center;
    @media (max-width: 1000px) {
      padding-left: 16px;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px; /* 150% */
      align-items: flex-start;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &--left {
      margin-left: 36px;
      @media (max-width: 1000px) {
      margin-left: 24px;
      }
    }
    span {
      @media (max-width: 1000px) {
        margin-top: 4px;
      }
    }
    span,
    svg {
      margin-right: 20px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      @media (max-width: 1000px) {
        margin-right: 8px;
      }
      .b {
        stroke: #808080;
      }
    }
    &-bold {
      opacity: 1;
      font-weight: $weight__max;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.045px;
      padding-left: 0;
      max-width: unset;
    }
  }
  &__price {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 308px;
    height: 100%;

    &--month {
      font-size: 14px;
      font-weight: $weight__bold;
      margin-left: -30px;
      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }
    &--price {
      font-size: 34px;
      font-weight: $weight__bold;
      position: relative;
      margin-left: 5px;
      @media (max-width: 1024px) {
        font-size: 28px;
      }
    }
    &--currency {
      position: absolute;
      font-size: 18px;
      top: 5px;
      right: -40px;
      @media (max-width: 1024px) {
        font-size: 12px;
        right: -22px;
      }
    }
    &--tax {
      position: absolute;
      font-size: 10px;
      top: 25px;
      right: -29px;
      color: rgba(0, 0, 0, 0.41);
      @media (max-width: 1024px) {
        font-size: 8px;
        right: -22px;
        top: 22px;
      }
    }
    &--priceOld {
      font-weight: $weight__bold;
      color: rgba(144, 144, 144, 1);
      position: relative;
      margin-right: 20px;
      text-decoration: line-through;
      @media (max-width: 1024px) {
        margin-left: -22px;
      }
    }
    &--currencyOld {
      position: absolute;
      text-decoration: line-through;
      top: 0px;
      right: -20px;
      font-size: 10px;
    }
    &--bottom-gray {
      border-bottom: 16px solid rgba(241, 241, 241, 0.8);
      margin-bottom: -10px;
      padding-bottom: 10px;
    }
    &--bottom-blue {
      border-bottom: 16px solid rgba(53, 47, 238, 1);
      margin-bottom: -10px;
      padding-bottom: 10px;
    }
    &--bottom-black {
      border-bottom: 16px solid rgba(33, 33, 33, 1);
      margin-bottom: -10px;
      padding-bottom: 10px;
    }
  }
  &--icon {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    max-width: 308px;

    @media (max-width: 1000px) {
      background-color: rgba(255, 255, 255, 0.6);
      max-width: 76px;
    }
    &-vtext {
      position: relative;
      font-weight: $weight__normal;
      color: var(--Szary-600, #666);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      &-desktop {
        @media (max-width: 850px) {
          display: none;
        }
      }
      &-mobile {
        font-size: 12px;
        margin-bottom: -9px;
        &:after {
          content: '';
          left: 6px !important;
          position: absolute;
          top: -20px;
          background-image: url('../../Image/Icon/icon_v.svg');
          background-position: center;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
        }
        @media (min-width: 850px) {
          display: none;
        }
      }
      // &:after {
      //   content: '';
      //   left: -28px;
      //   position: absolute;
      //   // top: -9px;
      //   background-image: url('../../Image/Icon/icon_v.svg');
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   width: 20px;
      //   height: 20px;
      // }
    }
    &-v {
      position: relative;
      &:after {
        content: '';
        left: -10px;
        position: absolute;
        top: -9px;
        background-image: url('../../Image/Icon/icon_v.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
    }
    &-x {
      position: relative;
      &:after {
        content: '';
        left: -5px;
        position: absolute;
        top: -4px;
        background-image: url('../../Image/Icon/icon_close.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
      }
    }
  }

  &__scalable {
    transition: 0.5s;
    height: auto;
    overflow: hidden;
    &--show {
      max-height: 1000px;
    }
    &--hide {
      max-height: 0;
    }
  }
}
.packageMobilePacket {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  padding-left: 15px;
  cursor: pointer;
  margin-top: 10px;
  @media (min-width: 850px) {
    display: none;
  }
  &--image {
    height: 80px;
    margin-right: 15px;
  }
  &--packetName {
    font-size: 18px;
    font-weight: $weight__max;
  }
  &--packetType {
    font-size: 14px;
    font-weight: $weight__normal;
    display: flex;
    span {
      opacity: 0.4;
      &:nth-child(n + 2) {
        margin-left: 8px;
      }
    }
  }
  &--check {
    margin-left: auto;
    padding: 25px;
  }
}
.packagesPricingMobile {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin-top: 50px;
  gap: 56px;
  padding: 0 16px;
  @media (min-width: 1000px) {
    display: none;
  }
  .packagesTop__wrap {
    width: 100%;
  }
  .packagesTop__content {
    max-width: unset;
    height: 155px;
  }
  .packagesTop__price {
    margin-top: 10px;
  }
  .packagesTop__button {
    height: 72px;
    .btn {
      border-radius: 30px;
      color: #fff;
      font-family: Poppins;
      font-size: 13px;
      font-weight: 700;
      height: 40px;
    }
  }
}

.package-basic {
  .packagesTable {
    &__price:nth-child(2) {
      background-color: rgba(255, 255, 255, 1) !important;
    }
    &--icon:nth-child(2) {
      background-color: rgba(255, 255, 255, 1) !important;
    }
  }
}
.package-premium {
  .packagesTable {
    &__price:nth-child(3) {
      background-color: rgba(255, 255, 255, 1) !important;
    }
    &--icon:nth-child(3) {
      background-color: rgba(255, 255, 255, 1) !important;
    }
  }
}
.package-pro {
  .packagesTable {
    &__price:nth-child(4) {
      background-color: rgba(255, 255, 255, 1) !important;
    }
    &--icon:nth-child(4) {
      background-color: rgba(255, 255, 255, 1) !important;
    }
  }
}
