.sidebar {
  height: 100vh;
  position: fixed;
  z-index: 101;
  width: 246px;
  background-color: #2d2d2d;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  transition: width 0.2s ease-in-out, transform 0.1s ease-in-out;
  overflow: hidden;
  right: 0;
  transform: translateX(246px);
  @include lg {
    width: 80px;
    transform: unset;
    right: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  @media (max-width: 1024px) {
    width: 80px;
    transform: unset;
    right: unset;
    transform: none;
    top: unset;
    bottom: 0;
    height: 64px;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    padding: 0;
  }
  &__hamburger-wrapper {
    padding-top: 26px;
    display: flex;
    padding-left: 33px;
    height: 60px;
    .svg {
      cursor: pointer;
    }
  }
  &__expanded-header {
    padding: 26px 31px 0 31px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .svg {
      cursor: pointer;
    }
    .logo {
      width: 72px;
      height: 33px;
      fill: #fff;
      margin-top: 6px;
    }
    span {
      display: flex;
      align-items: center;
    }
    .close {
      width: 8px;
      height: 8px;
      fill: #fff;
    }
  }
  &__menu {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      flex-direction: row;
      padding: 0;
      gap: 32px;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      max-width: 100%;
    }
    &--admin {
      @media (max-width: 700px) {
        padding-left: 10px;
        padding-right: 10px;
        justify-content: flex-start;
      }
    }
  }
  &__icon {
    width: 100%;
    max-width: 86px;
    float: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    transition: 0.3s;
    margin-left: 15px;

    @media (max-width: 1024px) {
      margin: 0;
    }
  }
  &__svg {
    width: 100%;
    max-width: 50px;

    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 0 13px;
    svg {
      min-width: 24px;
      height: 24px;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      font-size: 10px;
      font-weight: 400;
      height: 42px;
      justify-content: space-between;
      align-items: center;
      color: #f2f2f2;
      span {
        margin-left: 0;
      }
      path {
        stroke: #f2f2f2;
      }
    }
  }
  &__item {
    margin: 5px 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
    font-family: $fontPoppins;
    font-weight: 500;
    @media (max-width: 1024px) {
      width: unset;
    }
    &:hover,
    &--active {
      @media (min-width: 1024px) {
        .sidebar__svg {
          background-color: #484545;
        }
      }
    }

    &--active {
      @media (max-width: 1024px) {
        .sidebar__svg {
          background-color: unset;
          color: #f29225;
        }
        svg {
          path {
            stroke: #f29225;
          }
          a {
            stroke: #f29225;
          }
        }
      }
    }

    &--label {
      margin-left: 10px;
    }
  }
  &__logout {
    color: #ff602e;
    font-size: 14px;
    font-weight: 400;
    font-family: $fontPoppins;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
  }
  transition: width 0.2s ease-in-out, transform 0.2s ease-in-out;
  
  &--expanded {
    transform: translateX(0px);
    @include lg {
      width: 246px;
    }
    .sidebar__icon {
      max-width: 210px;
      margin-left: 15px;
    }
    .sidebar__svg {
      max-width: 210px;
      padding: 0 13px;
      justify-content: flex-start;
      .svg {
        // @include lg {
        //   margin-right: 15px;
        // }
        width: 20px;

        &--settings {
          .a {
            stroke: #fff;
          }
        }
      }
    }
  }
}
