.userMenu__form-half .form__field--select .react-select__control {
  margin-bottom: 0 !important;
}
.userMenu__form-lang .form__field {
  margin-bottom: 7px;
  .react-select__control {
    margin-bottom: 0px !important;
  }
}
.userMenu {
  height: calc(100vh - 100px);
  transition-duration: 0.5s;
  right: -600px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  &--no-shield:after {
    display: none !important;
  }

  &:after {
    content: '';
    width: 980px;
    height: 100%;
    position: absolute;
    left: -790px;
    background-image: url('../../Image/background_vs_shield.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 300px;
    background-size: 60%;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__content {
    @media (max-width: 1024px) {
      padding-top: 90px;
      padding-bottom: 160px;
      position: relative;
      .userMenu__form-button {
        position: absolute;
        width: 100%;
        bottom: 80px;
        // &-relative {
        //   position: relative;
        // }
      }
    }
  }

  &--active {
    visibility: visible;
    height: calc(100vh + 50px);
    right: -24px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    z-index: 1;
    width: 40%;
    // padding-left: 20px;
    margin-top: -50px;
    @media (min-width: 756px) {
      padding-left: 20px;
    }
    @media (max-width: 1024px) {
      margin-top: 0;
      min-height: 100vh;
      width: 100vw;
      right: -77px;
      height: 100vh;
    }
    @media (max-width: 755px) {
      right: -24px;
    }
  }
  &--active-desktop {
    @media (min-width: 1024px) {
      visibility: visible;
      height: calc(100vh + 50px);
      right: -24px;
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      z-index: 1;
      width: 40%;
      // padding-left: 20px;
      margin-top: -50px;
      padding-left: 20px;
    }
  }
  ul {
    padding-top: 100px;

    z-index: 999;
    @media (max-width: 1024px) {
      padding-top: 30px;
    }
    @media (min-width: 1024px) {
      max-width: 310px;
    }
  }

  &__wrapper {
    position: relative;
  }

  &--inActive {
    color: #000000;
    opacity: 0.23;
    pointer-events: none;
    &-soon {
      font-size: 10px;
      margin-left: 10px;
      background-color: #e8e8e8;
      border-radius: 8px;
      padding: 3px 7px;
    }
  }

  .active-menu {
    font-weight: $weight__bold;
  }
  ul {
    height: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    @include md {
      margin-top: 75px;
    }
    @include lg {
      margin-top: 40px;
    }
    width: 100%;
    .menuErrors {
      font-size: 10px;
      color: rgba(255, 0, 0, 0.5);
      margin-left: 10px;
      display: flex;
      align-items: center;
      background-color: rgba(249, 249, 249, 1);
      padding: 1px 12px;
      border-radius: 12px;
    }
    li {
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid rgba(112, 112, 112, 0.15);
      // &:first-child {
      //   border-top: 1px solid rgba(112, 112, 112, 0.15);
      // }
      p {
        display: flex;
      }
    }
    a,
    p {
      color: #000;
      font-size: 14px;
      cursor: pointer;
      padding-left: 25.5px;
      @include md {
        padding-left: 0;
      }
    }
  }

  &__form-lang {
    width: 100%;
    position: relative;
    top: 10px;
    .react-select__control {
      background-color: rgb(249, 249, 249) !important;
    }
  }

  &---logout {
    padding: 20px;
    color: rgba(243, 39, 39, 1) !important;
    border-bottom: 1px solid rgba(112, 112, 112, 0.15);
    width: 100%;
  }
  &--logout {
    width: 100%;
    bottom: 20px;
    border-bottom: none;
    width: calc(100% - 20px);
    margin-top: auto;
    z-index: 9;
    p {
      cursor: pointer;
      color: rgba(243, 39, 39, 1);
      padding: 20px 0;
      width: 100%;
      display: block;
      border-top: 1px solid rgba(112, 112, 112, 0.15);
      padding-left: 25.5px;

      @include md {
        padding-left: 0;
      }
      &:hover {
        font-weight: $weight__normal;
      }
    }
  }
  &__chose {
    position: absolute;
    right: 0;
    z-index: 9;
    background-color: white;
    overflow-x: hidden;
    margin-left: -20px;
    @media (max-width: 1024px) {
      width: 100vw;
      padding-left: 20px;
    }
    @include md {
      margin-top: 0;
    }
    @include lg {
      margin-top: 0;
      padding: 0 0 0 20px;
      max-width: calc(40% - 22px);
      width: 100%;
    }
    div {
      max-width: 320px;
    }
    &-wrapper {
      @include lg {
        margin-top: -48px;
        padding-top: 20px;
      }
    }
  }
  &__form {
    max-width: 320px;
    .userMenu__form-half .react-select__clear-indicator,
    .userMenu__form-half .react-select__indicator-separator {
      display: none !important;
    }
    .form__field {
      margin-bottom: 20px;
      margin-inline: 0;
      label {
        font-size: 13px;
        font-weight: 600;
      }
      input {
        border: 0;
        background-color: rgba(249, 249, 249, 1);
        max-width: 320px;
      }
      textarea {
        font-size: 5px !important;
      }
      .field-label {
        margin: unset !important;
      }
    }
    .form__field--text {
      input {
        height: 38px;
      }
    }
    &--tel {
      .form__field--label-error {
        position: absolute;
        width: 140px;
      }
      .form__field {
        &:first-child .form__field--label-error {
          left: 25px;
        }
        &:nth-child(2) {
          width: 100% !important;
          .form__field--label-error {
            right: 0;
            text-align: right;
            width: 100%;
          }
        }
      }
      &-updated {
        &.userMenu__form-half {
          .react-select__control {
            text-align: center;
            span {
              font-size: 10px !important;
            }
            background-color: rgb(249, 249, 249);
          }
          .react-select__option{
            padding-left: 4px;
          }
          .react-select__menu {
            font-size: 10px !important;
            font-weight: normal;
            color: #000;
            .react-select__menu-list{
              max-height:80px;
              -ms-overflow-style: none;
              padding-bottom: 0; 
              scrollbar-width: none;
              overflow-x:hidden;
              &:webkit-scrollbar{
                display: none;
              }
            }
          }
          .form__field {
            &:first-of-type {
              width: 35%;
              padding-right: 0;
              align-self: flex-start;
              input {
                background-color: rgb(249, 249, 249);
              }
            }
            &:nth-child(2) {
              width: 65%;
              margin-bottom: 20px;
              margin-top: 5px;
              input {
                max-height: 38px;
              }
              &.form__field--error{
                margin-bottom: -20px !important;
              }
            }
            input {
              text-align: center;
              font-size: 10px !important;
            }
            // .react-select__control {
            //   margin-bottom: 1px;
            //   margin-top: 0px;
            //   min-height: 42px;
            //   background-color: #f9f9f9;
            //   // @media (max-width: 1441px) {
            //   //   min-height: 0px;
            //   // }
            // }
            // .react-select__single-value {
            //   font-size: 14px;
            // }
            // @media (max-width: 757px) {
            //   .react-select__control {
            //     height: 30px;
            //     max-height: 30px;
            //     min-height: 30px;
            //   }
            //   .react-select__indicator {
            //     padding: 4px;
            //   }
            // }
          }
        }
      }
    }
    &-half {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .form__field {
        margin-bottom: 0px !important;
      }
      .form__field:nth-child(1) {
        width: 68px;
        margin: 0 10px 0 0;
      }
      .form__field:nth-child(2) {
        width: 100px;
        input {
          height: 42px;
          @media (max-width: 757px) {
            height: 30px;
          }
        }
      }
    }
    .btn--wrapper {
      width: 110px;
    }
    .btn--mobile {
      @include lg {
        display: none;
      }
    }
    .userMenu__half {
      justify-content: space-between;
    }
    &-button {
      display: flex;
      margin: 20px 0 40px;
      justify-content: space-between;
      max-width: 300px;
      .btn {
        justify-content: center;
      }
      .btn--wrapper {
        width: auto;
      }
      .btn--icon img {
        width: 8px;
        height: 10px;
      }
      .btn__icon {
        margin-right: 8px;
      }
      .btn--black {
        width: 140px;
        font-size: 13px;
        border-radius: 5px;
        padding: 6px 20px;
      }
    }
    h2 {
      font-size: 18px;
      font-weight: $weight__bold;
    }
    &--wrong-password {
      display: flex;
      width: calc(100% - 10px);
      justify-content: flex-end;
      font-size: 13px;
      font-weight: 700;
      color: #e20d0d;
      margin-left: 3px;
      position: relative;
      animation: show 0.25s ease-in;
      p {
        position: absolute;
        bottom: -38px;
      }
    }
  }
  &__half {
    display: flex;
    justify-content: flex-start;

    .form__field {
      max-width: 133px;
      margin: 0;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  &__payment-back {
    margin-top: 50px;
    position: absolute;
    bottom: 40px;
    @media (min-width: 1024px) {
      display: none;
    }
  }
}
.form-my-data {
  .form__field--label-error-down {
    bottom: -65px;
  }
  .choicer__myData {
    margin: 15px 0;
  }
  .popUp & {
    .form__field {
      padding-right: 0 !important;
      .react-select__control {
        margin-bottom: 0px;
        margin-top: 0px;
        min-height: 40px;
        height: 40px;
        max-height: 40px;
        background-color: #f9f9f9;
      }
    }
    .form__field:nth-child(6) {
      // max-height:
      height: auto;
    }
  }
}
.MyData__invoice {
  max-width: 287px;
  margin-bottom: 24px;
}
.wrap .MyData,
.form-my-data .MyData {
  height: 100%;
  // overflow: auto;
  .form__field--countryList {
    margin-left: 0;
  }
  &__regulations {
    font-size: 10px;
    li {
      margin: 4px 0;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 40px;
    @media (max-width: 1024px) {
      padding: 20px;
      .userMenu__content {
        padding-top: 0;
        height: 100%;
        margin-bottom: 50px;
      }
    }
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }
  &__livePreview {
    margin-left: 100px;
    @media (max-width: 700px) {
      margin-left: 0;
      margin-bottom: 70px;
    }
    &--no-partner {
      margin-left: 0;
      margin-bottom: 20px;
    }
    &--heading {
      padding-bottom: 5px;
      font-weight: 700;
      font-size: 13px;
    }
  }
  &__theme {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    &--image {
      width: 287px;
      height: 189px;
      p {
        padding-bottom: 5px;
        font-weight: 700;
        font-size: 13px;
      }
      .imageInputHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 31px;
      }
    }
    &--theme {
      margin-top: 30px;
      margin-bottom: 50px;
      width: 287px;
      height: 63px;
      p {
        padding-bottom: px;
        font-weight: 700;
        font-size: 13px;
      }
    }
    @media (max-width: 900px) {
      margin-top: -80px;
    }
    &__buttonWrapper {
      .btn {
        background-color: transparent;
        padding: 7px 15px;
      }
      p {
        margin-left: 39px;
        margin-top: 10px;
        font-size: 10px;
        color: rgba(185, 185, 185, 0.9);
      }
    }
    ul {
      margin-top: 50px;
    }

    svg {
      max-width: 85px;
      max-height: 60px;
      margin: 15px 0 15px 25px;
    }
    img {
      margin-left: 25px;
      width: 88px;
    }
  }
  .hide {
    visibility: hidden !important;
    height: 0 !important;
    overflow: hidden !important;
  }
  &--none {
    visibility: hidden !important;
    height: 0 !important;
    overflow: hidden !important;
  }
  &__content {
    max-width: 100%;
    padding-left: 0;
    @media (max-width: 1024px) {
      padding-right: 0 !important;
    }
  }
  &--static {
    p {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    span {
      font-size: 12px;
      margin-left: 10px;
      font-weight: $weight__normal;
    }
  }
  .my-data {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 0 3px;
  }
}
.passwordChanged {
  button {
    // width: 143px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    padding: 11px 60px;
    font-size: 9px;
  }
  h3 {
    font-size: 40px;
    color: #38d58b;
    font-weight: $weight__bold;
    max-width: 220px;
  }
}

.add-image-btn {
  width: 117px;
  text-align: center;
  padding: 9px 30px;
  height: 32px;
  background-color: #352fee !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  font-size: 8px;
}

.preview {
  width: 215px;
  height: 250px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.06);
  padding: 15px;
  &--button {
    margin-top: 3px;
    text-align: center;
    font-size: 4px;
    color: #fff;
    border-radius: 20px;
    padding: 2px 4px;
    background-color: #000;
    svg {
      width: 4px !important;
      height: 4px !important;
    }
  }
  &__logo {
    height: calc(100% - 15px);
    display: flex;
    justify-content: center;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin-left: 0 !important;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;

      &[alt]:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        content: attr(alt);
      }
    }
  }
  &__heading {
    font-weight: 600;
    font-size: 7px;
    margin-bottom: 8px;
    width: 100%;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .uptime {
    font-size: 18px;
    font-weight: 600;
    color: green;
    text-align: center;
  }
  .tile {
    box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.06);
    height: 56px;
    padding: 5px;
    &--success svg {
      path {
        stroke: green;
      }
    }
    &__icon {
      display: flex;
      justify-content: center;
      margin-bottom: 6px;
    }
    &__down {
      font-size: 5px;
      font-weight: 600;
      text-align: center;
    }
    &--site {
      color: green;
    }
    &__data {
      display: flex;
      width: 100%;
      justify-content: center;
      p {
        font-size: 5px;
        font-weight: 600;
        color: #d9d9d9;
        margin-right: 3px;
      }
      span {
        font-size: 5px;
        font-weight: 600;
        color: green;
      }
    }
    &--gray {
      color: #d9d9d9;
    }
    &--paragraph {
      font-size: 5px;
      text-align: center;
      font-weight: 600;
    }
    &--small {
      width: 56px;
    }
    &--big {
      width: 120px;
    }
    &__heading {
      font-weight: 600;
      font-size: 5px;
      text-align: center;
      &--space {
        margin-bottom: 8px;
      }
    }
  }
  .table {
    margin-top: 2px;
    font-size: 4px;
    td {
      height: 12px;
    }
    .bold {
      font-weight: 600;
    }
    .gray {
      color: #757575;
    }
    .primary {
      color: green;
    }
  }
  svg {
    margin: 0 !important;
    width: 18px;
    height: 18px;
  }
  .customFont {
    color: #f4a53f;
    font-weight: 600;
    font-size: 6px;
    text-align: center;
    margin-top: 2px;
  }
  .small {
    font-weight: 300;
    font-size: 5px;
  }
  .small-data {
    color: green;
    font-weight: 300;
    font-size: 4px;
  }
  .primary {
    color: green;
  }
}
