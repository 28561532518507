.language {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  @include md {
    right: 60px;
    top: 50px;
  }
  .language-select {
    cursor: pointer;
  }
  .navi__header--language {
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      right: -10px;
      top: 8px;
      background-image: url(/static/media/icon_chevron_black.e1595605.svg);
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(90deg);
      z-index: 9;
    }
  }
  &-dashboard {
    position: absolute;
    top: 25px;
    right: 40px;
    font-weight: 700;
    font-size: 18px;
    @media (max-width: 1080px) {
      top: 65px;
    }
    @media (max-width: 1024px) {
      display: none;
    }
    select {
      font-size: 18px;
      font-weight: $weight__bold;
    }
    &:before {
      content: '';
      width: 15px;
      height: 15px;
      position: absolute;
      background-image: url('../../Image/Icon/icon_chevron_black.png');
      right: -17px;
      background-color: transparent;
      top: 8px;
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(90deg);
      z-index: 29;
    }
    &-mobile {
      display: none;
      padding-left: 20px;
      border-bottom: none !important;
      @media (max-width: 1024px) {
        display: block;
      }
      div {
        position: relative;
        width: 30px;
        margin-left: 20px;
        select {
          font-size: 18px;
          font-weight: $weight__bold;
        }
        &:before {
          content: '';
          width: 15px;
          height: 15px;
          position: absolute;
          right: -19px;
          top: 8px;
          background-image: url(/static/media/icon_chevron_black.e1595605.svg);
          background-size: contain;
          background-repeat: no-repeat;
          transform: rotate(90deg);
          z-index: 9;
        }
      }
    }
  }
}

.login__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  @media (max-width: 1400px) {
    padding-top: 30px;
  }
  @media (min-width: 1024px) {
    min-height: calc(100vh - 80px);
  }
  @media (max-width: 700px) {
    padding-top: 90px;
    padding-bottom: 40px;
  }
  .section--background {
    height: calc(100vh - 130px);
    overflow: hidden;
    @media (max-width: 1024px) {
      display: none;
    }
    @include xl {
      margin-top: 110px;
    }
  }
}
.recovery {
  margin-top: -90px;
}
.login {
  width: 287px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  min-height: 733px;

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    .btn--wrapper {
      margin-top: 40px !important;
    }
  }
  .errors {
    margin-top: 10px;
  }
  &--logo {
    width: 150px;
    margin-bottom: 20px;
    height: auto;
  }
  h1 {
    font-size: 35px;
    font-weight: $weight__max;
  }
  .form__field {
    margin: 0;
    width: 100%;
    .eye {
      height: 32px;
    }
    input {
      border-radius: 5px;
      padding: 8px 10px;
    }
    label {
      font-weight: $weight__bold;
    }
  }
  span {
    margin: 40px 0;
    font-size: 14px;
    opacity: 0.3;
    text-align: center;
  }
  .btn--wrapper,
  .btn {
    display: flex;
    margin: 0 20px;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .btn--wrapper {
    margin: 0;
  }
  &--problem {
    font-size: 14px;
    margin: 20px 0;
    color: #242424;
    text-align: center;
    cursor: pointer;
  }
  .form__field--label-error {
    margin: 0;
  }
  p {
    margin: 5px 0;
  }
  &--privacy {
    font-weight: $weight__normal;
    margin-left: 5px;
    opacity: 1;
    color: #000;
  }
}
