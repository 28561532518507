

.checkSite {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    z-index: 9;
    height: 650px;
    margin-bottom: 350px;
    @media(max-width: 769px) {
        margin-top: 100px;
        margin-bottom: 200px;
    }
    &__background {
        position: absolute;
        top: 0;
        left: 0;
    }
    &--form {
        max-width: 652px;
        position: relative;
        input {
            padding-left: 40px !important;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 151%;
            border: none;
            box-shadow:0px 3px 30px rgba(0, 0, 0, 0.05);
            &::placeholder { 
                color: #A6A6A6;
                font-family: "Poppins", sans-serif;
            }
        }
        .form__field {
            &--label-error {
                position: absolute;
            }
        }
    }
    &__l {
        margin-right: 121px;
        @media(max-width: 1100px) {
            margin-right: 0;
            display: flex;
            justify-content: center;
        }
        @media(max-width: 769px) {
            margin-right: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            // margin-bottom: 40px;
            svg {
                max-height: 139px;
            }
        }
    }
    &__r {
        max-width: 770px;
        @media(max-width: 1100px) {
            margin-top: 40px;
        }
        @media(max-width: 769px) {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            max-width: 100%;
        }
    }
    &--heading {
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.02em;
        @media(max-width: 769px) {
            text-align: left;
            font-weight: 900;
            font-size: 24px;
            line-height: 36px;
        }
    }
    &--paragraph {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #808080;
        margin-top: 24px;
        margin-bottom: 76px;
        @media(max-width: 769px) {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 48px;
        }
    }
    &__content{
        max-width: 1600px;
        width: 100%;
        margin: 0 75px 0 87px;
        display: flex;
        justify-content: center;
        z-index: 9;
        @media(max-width: 1100px) {
            flex-direction: column;
            margin-top: 40px;
        }
        @media(max-width: 769px) {
            margin-left: 20px;
            margin-right: 20px;
            padding: 0 16px;
        }
    }
    &__form {
        display: flex;
        position: relative;
        @media(max-width: 769px) {
            flex-direction: column;
        }
        .form__field {
            margin: 0;
            width: 100%;
            height: 100%;
            @media(max-width: 769px) {
                display: flex;
                flex-direction: column;
            }
            input {
                border-radius: 30px;
                height: 56px;
                border: unset;
                padding-right: 300px;
                @media(max-width: 769px) {
                    padding-right: 30px;
                }
            }
        }
        .btn--wrapper {
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 56px;
            font-family: 'Poppins';
            @media(max-width: 769px) {
                position: relative;
                margin-top: 16px;
                .btn {
                    width: 100%;
                    justify-content: center;
                }
            }
            .btn {
                height: 56px;
                display: flex;
                align-items: center;
                padding: 10px 50px;
            }
            .btn__icon {
                margin-bottom: 6;
            }
        }
        .btn {
            padding: 10px 30px;
        }
    }
    .progressBar {
        background-color: #D9D9D9;
        border-radius: 20px;
        margin-top: 120px;
    }
    input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
}

