.btn--wrapper {
  position: relative;
  outline: none;
  .hidden {
    display: none;
  }
}

.btn {
  &.without-radius {
    border-radius: 0px;
  }
  &.new-blue {
    color: #ffffff;
    background-color: rgba(53, 47, 238, 1);
  }
  &.new-blue-stroke {
    color: rgba(53, 47, 238, 1);
    border: 2px solid #352fee;
    background-color: transparent;
    justify-content: center;
  }
  &.new-blue,
  &.new-blue-stroke {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 44px;
  }

  &.blue-with-white-border {
    border-radius: 4px;
    border: 1.5px solid var(--Szary-100, #f2f2f2);
    background: #352fee;
    height: 32px;
    color: var(--Szary-100, #f2f2f2);
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 24px;
    display: flex;
    align-items: center;
    z-index: 1;

    .btn__icon {
      display: flex;
      align-items: center;
      margin: 0px 0px 0px 12px;
      i {
        display: flex;
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  &.transparent-with-black-border {
    background-color: transparent;
    border-radius: 4px;
    border: 1.5px solid var(--Czarny-400, #262626);
    height: 32px;
    color: var(--Czarny-400, #262626);
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    z-index: 1;
    padding: 0 24px;
  }
  &.black-with-white-border {
    border-radius: 4px;
    border: 1.5px solid var(--Szary-100, #f2f2f2);
    background: var(--Czarny-400, #262626);
    height: 32px;
    color: var(--Szary-100, #f2f2f2);
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 24px;
    display: flex;
    align-items: center;
    z-index: 1;

    .btn__icon {
      display: flex;
      align-items: center;
      margin: 0px 0px 0px 12px;
      i {
        display: flex;
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.gray {
    border-radius: 6px;
    background: var(--Szary-100, #f2f2f2);
    color: var(--Czarny-300, #333);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
    padding: 0 16px;
    height: 40px;
    display: flex;
    align-items: center;
    .btn__icon {
      margin-left: 8px;
    }
    &.btn--disabled {
      opacity: 1;
    }
  }

  &:focus {
    outline: none !important;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
    // background-color: transparent !important;
    outline-offset: -4px;
  }
  font-family: $fontPoppins;
  font-weight: $weight__bold;
  font-size: 16px;
  padding: 12px 30px 12px 30px;
  border-radius: 36px;
  border: none;
  background-color: #ffffff;
  color: #1a1a1a;
  cursor: pointer;
  display: flex;
  outline: none;
  position: relative;
  overflow: hidden;
  &:focus {
    outline: none !important;
  }
  &:active {
    outline: none !important;
  }
  &:before {
    content: '';
    position: absolute;
    top: -40%;
    right: 110%;
    width: 30px;
    height: 200%;
    background: rgba(white, 0.3);
    transform: rotate(20deg);
  }

  &:hover {
    &:before {
      right: -50%;
      transition: 0.8s ease all;
    }
  }
  &--loader {
    // position: absolute;
    // right: 15px;
    // padding: 7px 25px;
    &__inner {
      width: 17px;
      height: 17px;
      border: 2px solid #fff;
      border-radius: 50%;
      border-bottom-color: transparent;
      animation: spinx 1s infinite linear both;
    }
  }
  &--12s {
    font-size: 12px !important;
  }
  &--9s {
    font-size: 9px !important;
  }
  &--11s {
    font-size: 11px !important;
  }
  &--5r {
    border-radius: 5px;
  }
  &--12r {
    border-radius: 12px;
  }
  &--7p {
    padding: 7px 25px;
  }
  &--3p {
    padding: 3px 15px;
  }
  &.is-loading {
    display: flex;
    align-items: center;
  }
  &--box-shadow {
    box-shadow: 0px 3px 40px #00000029;
  }
  &--blue-new {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #ffffff;
    background: #352fee;
    border-radius: 30px;
    padding: 10px 37px;
  }
  &--blue-new-stroke {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #352fee;
    border-radius: 30px;
    padding: 10px 37px;
    border: 2px solid #352fee;
    background-color: transparent;
  }
  &--blue {
    color: #fff;
    background-color: #352fee;
  }
  &--blue--lagon {
    color: rgba(53, 47, 238, 1);
    background-color: rgba(231, 230, 255, 1);
  }
  &--black {
    color: #fff;
    background-color: #212121;
    &:hover {
      background: lighten(#000, 2%);
    }
  }
  &--disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &--paginationDisabled {
    opacity: 0.2;
    cursor: not-allowed;
    color: #fff;
    background-color: #352fee;
  }

  &--runningDisabled {
    width: 219px;
    height: 40px;
    font-size: 13px;
    font-weight: 700;
    line-height: 19.5px;
    opacity: 0.2;
    cursor: not-allowed;
    color: #fff;
    background-color: #352fee;
  }

  &--gray {
    background-color: #ebebeb;
    border-radius: 4px;
    font-size: 12px;
    padding: 7px 25px;
    img {
      transform: rotate(180deg);
      height: 15px;
      width: 10px;
      margin-top: 2px;
    }
  }
  &--black-border {
    border: 2px solid black;
  }
  &--transparent {
    background-color: transparent;
  }
  &--white-border {
    color: #fff;
    border: 2px solid #fff;
    background-color: transparent;
  }
  &--transparent-white-border {
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
  }
  &--gray-border {
    color: rgba(49, 49, 49, 1);
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: transparent;
    border-radius: 4px;
    font-size: 12px;
    padding: 7px 25px;
  }
  &--black-5r {
    color: #fff;
    background-color: transparent;
    border-radius: 4px;
    font-size: 12px;
    padding: 7px 25px;
    background-color: #212121;
  }
  &--gray-border-rounded {
    color: rgba(49, 49, 49, 1);
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: transparent;
    border-radius: 25px;
    padding: 10px 25px;
  }
  &--black-rounded {
    color: rgba(49, 49, 49, 1);
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 25px !important;
    padding: 10px 25px !important;
    color: #fff;
  }
  &--blue-rounded {
    color: rgba(49, 49, 49, 1);
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 25px !important;
    padding: 10px 25px;
    color: #fff;
  }

  &--48-height {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
  }

  &--266-width {
    width: 266px;
  }

  &__icon-placement--right .btn__icon {
    margin: 0 -10px -10px 15px;
  }
  &__icon-placement--left .btn__icon {
    margin: 0 15px -10px -10px;
  }
  &--back {
    width: 66px;
    font-size: 9px;
    padding-right: 10px;
    padding-top: 7px;
    height: 28px;
    z-index: 9;
    margin-left: -15px;
    margin-top: 30px;
    .btn__icon {
      margin-right: 8px;
    }
    .btn--icon {
      margin-left: -15px;
      img {
        height: 10px;
      }
    }
  }
  &--mobile {
    button {
      height: 32px;
      padding-top: 8px;
    }
  }
  &__back {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(237, 237, 237, 0.08);
    color: #000000;
    &:hover {
      background-color: rgba(237, 237, 237, 0.2);
    }
  }
  &__black-stroke {
    border-radius: 30px;
    border: 2px solid #262626;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 30px;
    &--transparent {
      background-color: transparent;
    }
  }
  &__black {
    border-radius: 30px;
    background: var(--czarny-400, #262626);
    width: 100%;
    height: 48px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.without_border_radius {
    border-radius: 4px;
  }
}

.partner-button {
  .btn__icon {
    margin-top: 5px;
  }
}

.disableButton {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes spinx {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chooseFile {
  width: 162px;
  height: 40px;
  background: #352fee;
  border-radius: 30px;
  color: #fff;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myAccountRedirect {
  width: 162px;
  height: 40px;
  background: #352fee;
  border-radius: 30px;
  border: none;
  color: #fff;
  font-size: 13px;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
}

.undo {
  width: 162px;
  height: 40px;
  background: transparent;
  border: 2px solid #352fee;
  border-radius: 30px;
  color: #352fee;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.diagnostics {
  width: 219px;
  height: 40px;
  font-size: 13px;
  font-weight: 700;
  line-height: 19.5px;
}

.previous {
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
}
