.switcher {
  transition: all 0.3s ease;
  .wrapper {
    display: flex;
    justify-content: space-between;
    height: 25px;
    .data-tip {
      position: relative;
      top: 3px;
    }
  }
  .svg {
    margin: 0 !important;
  }
  p {
    font-size: 13px;
    font-weight: 700;
  }
  .checkbox-wrapper {
    justify-content: flex-start;
  }

  &--disabled {
    opacity: 0.45;
    pointer-events: none;
  }
}
