.input-range {
  position: relative;
  width: 100%;
  padding: 20px 0;

  &__input {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 4px;
      // background: #352fee;
      transform: translateY(-50%);
      z-index: -1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 4px;
      width: calc(100% - 8px);
      height: 4px;
      background: #352fee;
      transform: translateY(-50%);
      z-index: -1;
      transition: 0.3s;
      background: linear-gradient(to right, #4a5bf2, #4a5bf2 var(--value), #ddd var(--value));
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: #352fee;
      border-radius: 50%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: #352fee;
      border-radius: 50%;
      cursor: pointer;
    }

    &::-ms-thumb {
      width: 20px;
      height: 20px;
      background: #352fee;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 0px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 0;
    &__dot {
      width: 14px;
      height: 14px;
      background: #000;
      border-radius: 50%;
      position: absolute;
      top: -16px;
      left: -5px;
      border-radius: 50px;
      border: 2px solid #fff;
      background: var(--Szary-200, #d9d9d9);
      border: 2px solid #fff;
      transition: 0.3s;
      cursor: pointer;
    }

    &__label {
      font-size: 12px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-40%);
      transition: 0.3s;
      color: var(--Szary-400, #a6a6a6);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    &--active {
      .input-range__step__dot {
        background: #352fee;
      }
    }
    &--selected {
      .input-range__step__dot {
        opacity: 0;
        background: var(--Szary-200, #d9d9d9);
        pointer-events: none;
        transition: unset;
      }
      .input-range__step__label {
        color: #352fee;
      }
    }
  }
}
/*Chrome*/

input[type='range']::-webkit-slider-thumb {
  cursor: ew-resize;
  background: #352fee;
  height: 18px;
  width: 18px;
  border: 2px solid #fff;
}
