.formSignUp {
  background-color: #2d2d2d;
  min-height: 100vh;
  width: 100vw;
  padding: 40px 80px;
  @media (max-width: 800px) {
    padding: 20px 40px;
  }
  &__header {
    position: relative;
  }
  &__logo {
    width: 101px;
    height: 50px;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  .language {
    top: 13px;
    right: 0;
    &-select {
      color: #fff;
    }
  }
  .navi__header--language {
    &:before {
      background-image: url('../../Image/Icon/icon_chevron_white.svg');
      margin-top: 4px;
    }
  }
  &__content--wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  &__content {
    width: 400px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 80px;
    align-content: flex-start;
    &--triangle {
      width: 100%;
      height: 183px;
      margin-top: -40px;
      img {
        margin-left: -60px;
        height: auto;
        width: 300px;
        @media (max-width: 959px) {
          margin-left: 40px;
        }
        @media (max-width: 450px) {
          margin-left: 10px;
        }
      }
    }
    &--left {
      justify-content: flex-start;
      width: 500px;
      @media (max-width: 1059px) {
        width: 400px;
      }
      @media (max-width: 959px) {
        justify-content: center;
      }
    }
  }
  &__heading {
    color: #10c169;
    font-size: 51px;
    font-weight: $weight__max;
    line-height: 56px;
    text-align: center;
    @media (max-width: 959px) {
      font-size: 41px;
    }
  }
  &__email {
    font-weight: $weight__bold;
    color: #f2f2f2;
    margin: 24px 0;
    width: 100%;
    text-align: center;
  }
  &__bold {
    font-weight: $weight__bold;
    color: #f2f2f2;
    margin: 24px 0;
    text-align: left;
    font-size: 21px;
    &--top {
      margin-top: 50px;
      max-width: 400px;
      @media (max-width: 959px) {
        text-align: center;
      }
      @media (max-width: 450px) {
        width: 100%;
      }
    }
  }
  &__descript {
    color: #f1f1f1;
    opacity: 0.6;
    text-align: center;
    &--left {
      text-align: left;
      width: 520px;
      @media (max-width: 959px) {
        text-align: center;
      }
      @media (max-width: 450px) {
        width: 100%;
      }
    }
    &--small {
      max-width: 310px;
      margin-top: 25px;
    }
  }
  button {
    margin: 35px 0;
    width: 220px;
    display: flex;
    justify-content: center;
  }
}
