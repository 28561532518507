.dashboard {
  // Nowe zmiany Mateusza
  display: flex;
  min-height: 100vh;
  &__inner {
    // margin-left: 86px;
    width: 100%;
    @media (max-width: 1024px) {
      padding-bottom: 64px;
    }
  }

  &__page-wrapper {
    padding: 60px 0 0;
    position: relative;
    .dashboard-loader {
      position: absolute;
      left: 50%;
      top: 50%;
    }
    &--white {
      background-color: #fff;
    }
    @include lg {
      padding: 60px 0 0 80px;
    }
    &--without-padding {
      height: 100%;
      @include lg {
        padding: 60px 0 0 80px;
      }
    }
    &--website {
      padding: 60px 60px 60px 150px;
      @media (max-width: 1024px) {
        padding: 60px 0;
      }
    }
  }
  &__wrapper {
    .systemUsers__r {
      width: 100%;
      &__wrapper {
        max-width: 290px;
        width: 100%;
        margin-right: -340px;
        position: absolute;
        right: 40%;
        @media (max-width: 1024px) {
          max-width: 100%;
          margin-right: 0;
          right: 0;
        }
      }
    }
  }
}
.mailing {
  &__headers {
    display: flex;
    color: #000000;
    opacity: 0.3;
    font-size: 12px;
    border-bottom: 2px solid #00000033;
    margin-top: 20px;
    padding: 5px 0;
    @media (max-width: 500px) {
      display: none;
    }
    &--id {
      padding: 0 20px;
    }
    &--date {
      margin-left: auto;
      margin-right: 126px;
      @media (max-width: 600px) {
        margin-right: 30px;
      }
    }
  }
  &__singleItem {
    align-items: center;
    display: flex;
    font-size: 12px;
    margin: 5px 0;
    padding: 10px 0 7px;
    background-color: rgba(255, 255, 255, 0.295);
    border-radius: 5px;
    &--count {
      padding: 0 20px;
      font-size: 14px;
      color: #000000;
      opacity: 0.33;
    }
    &--email {
      color: #000000;
      opacity: 0.72;
      font-weight: $weight__normal;
      word-wrap: anywhere;
      @media (max-width: 600px) {
        margin-right: 10px;
      }
    }
    &--date {
      margin-left: auto;
      color: #000000;
      opacity: 0.72;
      font-weight: $weight__normal;
      margin-right: 100px;
      min-width: 130px;
      @media (max-width: 600px) {
        margin-right: 10px;
      }
    }
  }
}

.preOrder {
  background-color: #f1f1f17e;
  &__headers {
    display: flex;
    border-bottom: 1px solid #00000033;
    margin: 5px 0;
    padding: 5px 0;
    font-size: 12px;
    align-items: center;
    @media (max-width: 800px) {
      display: none;
    }
  }
  &__singleItem {
    padding: 0 5px;
    &--wrapper {
      display: flex;
      font-size: 12px;
      background-color: rgba(255, 255, 255, 0.595);
      border-radius: 5px;
      margin: 6px 0;
      padding: 12px 0;
      align-items: center;
      @media (max-width: 570px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }
    }
    &--id {
      text-align: center;
      width: 50px;
      text-align: center;
      font-size: 14px;
      color: #000000;
      opacity: 0.33;
      font-weight: $weight__normal;
      @media (max-width: 570px) {
        padding-left: 10px;
        text-align: left;
      }
    }
    &--package {
      min-width: 90px;
      display: flex;
      align-items: center;
      @media (max-width: 570px) {
        grid-column: 1/2;
        padding-left: 8px;
      }
    }
    &--name {
      min-width: 140px;
      @media (max-width: 570px) {
        grid-column: 2/3;
        grid-row: 1/2;
      }
    }
    &--companyName {
      width: 300px;
    }
    &--date {
      width: 170px;
      margin-left: auto;
      @media (max-width: 570px) {
        grid-column: 2/3;
        grid-row: 3/4;
        margin-left: 0;
      }
    }
    &--payments {
      margin-right: 20px;
      @media (max-width: 570px) {
        grid-column: 2/3;
        grid-row: 3/4;
        margin-left: 0;
        text-align: center;
        padding-left: 40px;
      }
    }
    &--icon-package {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      display: block;
      margin-right: 4px;
      &--gray {
        background-color: black;
        opacity: 0.3;
      }
      &--black {
        background-color: black;
      }
      &--blue {
        background-color: blue;
      }
    }
    &--flex {
      display: flex;
    }
  }
}
.counters {
  font-size: 12px;
  margin-top: 15px;
  @media (max-width: 800px) {
    padding-left: 10px;
  }
}

.packageSorting {
  display: flex;
  margin-top: 15px;
}

.packageSort {
  &__wrapper {
    align-items: center;
    display: flex;
    @media (max-width: 1000px) {
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
  &__item {
    margin: auto;
    align-items: center;
    box-shadow: 0px 3px 6px #00000017;
    border: 1px solid rgba(112, 112, 112, 0.2);
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: $weight__normal;
    margin: 8px 8px;
    height: 33px;
    padding: 1px 16px 1px 5px;
    &:first-child {
      margin-left: 0;
    }
    @media (max-width: 1000px) {
      margin: 8px 0;
    }
    // @media (max-width: 400px) {
    //   width: 100%;
    // }
    &--active {
      border: none;
      background-color: #fff;
      color: rgba(0, 0, 0, 1);
      font-weight: $weight__bold;
      max-height: 36px;
      padding: 8px 20px 8px 10px;
    }
    img {
      max-height: 50px;
      margin-right: 4px;
    }
    &--counter {
      padding-left: 5px;
    }
  }
}

.userTop {
  height: 60px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  &__content {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .systemUsers__info {
    margin-left: auto;
    margin-right: 120px;
    padding-top: 4px;
    @media (max-width: 1024px) {
      width: auto;
      margin-right: 80px;
    }
  }
  .systemUsers--userLogo {
    height: 35px;
    width: 35px;
    svg {
      height: 100%;
    }
  }
}
.wrap {
  height: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  &__websites-details {
    width: 100%;
    padding: 50px 0px 0 20px;
    max-width: 1440px;
    @media (max-width: 1024px) {
      padding: 50px 20px 50px 20px;
    }
  }
  &__content {
    padding: 54px 20px 54px 20px;
    max-width: 1440px;
    width: 100%;
    @media (max-width: 900px) {
      padding: 24px 30px 54px 30px;
    }
    &--clients {
      padding-left: 0;
      max-width: 100%;
      @media (max-width: 1024px) {
        padding: 54px 15px;
      }
    }
    &--admin {
      @media (max-width: 1024px) {
        padding: 34px 20px 54px;
      }
    }
    &--backups,
    &--notifications,
    &--paymentsLists {
      @media (max-width: 1024px) {
        padding: 34px 0 54px;
        .belt {
          border-radius: 0;
        }
      }
      @media (max-width: 550px) {
        .belt__button {
          display: none;
        }
      }
    }
  }
}
.partnerWrap {
  @media (max-width: 1024px) {
    background-color: rgba(249, 249, 249, 1);
    height: auto;
  }
}
.wrap .partnerBelt {
  .btn {
    padding: 15px 40px;
  }
  .btn--wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &__paragraph {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    background-color: transparent;
    padding: 20px 15px;
    background-color: #fff;
    margin-top: 10px;
    width: calc(100vw - 20px);
    border-radius: 0;
    .partnerBelt__paragraph {
      border: 1px solid rgba(112, 112, 112, 0.41);
      padding: 16px 18px;
      border-radius: 12px;
      cursor: pointer;
      position: relative;
      &:after {
        content: '';
        background-image: url('../../Image/Icon/copy.svg');
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        position: absolute;
        right: 15px;
        top: 25px;
      }
    }
    .belt__price {
      background-color: rgba(249, 249, 249, 1);
      padding: 16px 18px;
      border-radius: 12px;
      font-size: 14px;
      width: 100%;
      margin-right: 10px;

      .form__field--select {
        width: 80px;
        height: 32px;
      }

      &:nth-child(2) {
        margin-right: 0;
      }
      &--head {
        font-size: 14px;
      }
    }

    .belt__button {
      margin-left: 0;
      margin-top: 20px;
      flex-direction: column;
    }
    .btn {
      margin-top: 40px;
      border-radius: 5px;
      background-color: #000000;
      font-size: 9px;
      padding: 11px 53px;
    }
    &--noMobile {
      display: none;
    }
  }
  .belt__price__wrapper {
    display: flex;
  }
}
.belt {
  background-color: #fff;
  border-radius: 12px;
  padding: 35px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  width: 100%;
  @media (max-width: 550px) {
    flex-wrap: wrap;
  }
  &__price {
    margin-right: 50px;
    .react-select__control .react-select__indicators .react-select__indicator-separator {
      display: none !important;
    }
    .form__field--select {
      width: 80px;
      height: 32px;

      .react-select__control {
        background: #f2f2f2;
        border-radius: 5px;
      }

      .react-select__menu {
        font-size: 12px;
      }
    }

    @media (max-width: 1024px) {
      margin-right: 20px;
    }
    &--head {
      font-size: 24px;
      font-weight: $weight__max;
      display: flex;
    }
    &--paragraph {
      font-size: 14px;
    }
  }
  &__button {
    display: flex;
    margin-left: auto;
    @media (max-width: 550px) {
      margin-left: 0;
      margin-top: 35px;
    }
  }
  &__head {
    @media (max-width: 550px) {
      width: 100%;
    }
    &__header {
      font-size: 24px;
      font-weight: $weight__max;
      @media (max-width: 1024px) {
        margin-top: 24px;
        font-size: 18px;
      }
    }
    &__paragraph {
      font-size: 14px;
      font-weight: $weight__tiny;
      display: flex;
      p {
        margin-right: 5px;
      }
      @media (max-width: 1024px) {
        flex-direction: column;
        p {
          margin-bottom: 5px;
        }
      }
    }
    &__userName {
      display: none;

      @media (max-width: 1024px) {
        display: block;
        margin: 10px 0;
        font-size: 14px;
      }
    }
  }
}
.changePassword {
  @media (max-width: 1024px) {
    padding: 0 20px;
  }
}
.belt2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    background-color: transparent;
    padding: 20px 20px 0 20px;
    padding-bottom: 0;
    grid-template-columns: 1fr;
    div:nth-child(2) {
      background-color: rgba(249, 249, 249, 1);
      padding: 20px;
      border-radius: 12px;
      margin-top: 20px;
      .belt__head__header {
        font-size: 14px;
      }
      .belt__head__paragraph {
        font-size: 14px;
        font-weight: $weight__tiny;
      }
    }
  }
}
.addWebsite__content {
  @media (max-width: 1024px) {
    padding: 36px 0px;
  }
}
.belt__buttonMobile {
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    margin-top: 20px;
    button {
      border-radius: 4px;
      font-size: 9px;
      padding: 10px 30px;
    }
    .btn--icon img {
      height: 8px;
      width: 8px;
      margin-right: -8px;
      margin-top: 3px;
    }
    .btn__icon {
      margin-bottom: 0;
    }
    .btn--wrapper {
      width: calc(100% - 40px);
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 30px;
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}
.addWebsiteBelt {
  @media (max-width: 1024px) {
    padding: 0;
    background-color: transparent;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 20px;
    .belt__head__paragraph--noMobile {
      display: none;
    }
    .belt__head__paragraph--cloud {
      background-color: #f9f9f9;
      padding: 23px 19px;
      border-radius: 12px;
      margin-right: 22px;
      margin-top: 20px;
      font-weight: $weight__normal;
      font-size: 14px;
    }
    .belt__button {
      display: none;
    }
  }
}
.tableMySite {
  display: grid;
  grid-template-columns: 40px minmax(300px, 3fr) minmax(130px, 1fr) minmax(130px, 1fr) minmax(130px, 1fr) minmax(60px, 1fr) 0;
  font-size: 14px;
  align-items: center;
  position: relative;
  border-bottom: 2px solid rgba(39, 45, 59, 0.15);
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 60px;
    border-bottom: 0;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 13px 20px;
    grid-template-areas:
      'package package package'
      'site site dots'
      'data card .'
      'settings settings settings';
    overflow: hidden;
  }

  &__wrapper-admin {
    @media (max-width: 1024px) {
      padding-inline: 0 !important;
    }
    .tableWebsites__wrapper {
      @media (max-width: 1024px) {
        padding-inline: 0;
      }
    }
  }

  &--active {
    height: 100%;
    overflow: visible;
    transition-duration: 0.3s;
  }
  &__settings {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 40px #66666629;
    position: absolute;
    padding: 16px 20px;
    right: 0;
    top: 0;
    width: 250px;
    z-index: 9;
    height: 0;
    display: none;
    .dots {
      color: #999999;
      font-size: 44px;
      padding: 0;
      cursor: pointer;
      z-index: 8;
      position: absolute;
      right: 20px;
      top: -20px;
      // pointer-events: none;
      user-select: none;
    }
    &--active {
      transition-duration: 0.3s;
      height: auto;
      visibility: visible;
      display: block;
      top: 15px;
      right: 6px;
      @media (max-width: 1024px) {
        right: unset;
      }
    }
    @media (max-width: 1024px) {
      &--wrap {
        grid-area: settings;
      }
      position: relative;
      width: 100%;
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      margin-top: 15px;
      height: 0;
      overflow: hidden;
      border-top: 0;
      display: block;
      p {
        padding: 15px 0 !important;
      }
      &--active {
        transition-duration: 0.3s;
        border-top: 1px solid rgba(128, 128, 128, 0.09);
        height: 100%;
        visibility: visible;
      }
    }
    p {
      padding: 10px 30px;
      margin-right: 30px;
      cursor: pointer;
    }
    &--dots {
      position: absolute;
      right: 20px;
      top: -16px;
      font-size: 44px;
      color: #999999;
      cursor: pointer;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    &--sub {
      color: #f32727;
    }
  }
  div {
    padding: 16px 0;
    @media (max-width: 1024px) {
      padding: 3px 0;
    }
  }

  &--bold {
    font-weight: $weight__bold;
    padding-bottom: 15px;
    border-bottom: 0;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__package {
    display: flex;
    &--basic,
    &--premium,
    &--pro {
      display: block;
      width: 18px;
      height: 18px;
      background-color: green;
      border-radius: 10px;
      margin-right: 5px;
    }
    &--basic {
      background-color: #c4c4c4;
    }
    &--premium {
      background-color: #352fee;
    }
    &--pro {
      background-color: #000000;
    }
    &--mobile {
      display: none;
      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
  &__id {
    color: #999999;
    font-weight: $weight__normal;
    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__data {
    @media (max-width: 1024px) {
      grid-area: data;
    }
  }
  &__url {
    @media (max-width: 1024px) {
      grid-area: site;
    }
    display: flex;
    width: 100%;
    align-items: center;
    &--site {
      flex-shrink: 0;
      margin-right: 10px;
      @media (max-width: 1024px) {
        font-weight: $weight__bold;
      }
    }
    &--alerts {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 20px;
      padding: 0 !important;
      @media (max-width: 1024px) {
        margin-right: 0;
      }
    }
    &--orange,
    &--red {
      font-size: 10px;
      padding: 4px 12px;
      background-color: #ff96180a;
      border-radius: 12px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: -1px;
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 4px 0;
      @media (max-width: 399px) {
        font-size: 9px;
      }
    }
    &--orange {
      color: #ff9618;
    }
    &--red {
      color: #eb001b;
    }
  }

  &__dots {
    align-content: center;
    color: #999999;
    display: flex;
    font-size: 44px;
    padding-right: 20px !important;
    justify-content: flex-end;
    line-height: 40px;
    cursor: pointer;
    margin-top: -18px;
    // z-index: 99;
    z-index: 8;
    user-select: none;
    &--active {
      color: #000 !important;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    @media (max-width: 1024px) {
      grid-area: dots;
      justify-content: flex-end;
      padding-right: 0 !important;
    }
  }
  &__payments {
    display: flex;
    justify-content: center;
    align-content: center;
    @media (max-width: 1024px) {
      grid-area: card;
      justify-content: flex-end;
    }
    .mastercard {
      max-height: 20px;
      margin-bottom: -3px;
      margin-left: 5px;
    }
    .visa {
      max-height: 30px;
      margin: -5px -9px -8px -20px;
    }
  }
}
.wrap__content .tablePartnerClients {
  grid-template-columns: 40px minmax(300px, 3fr) minmax(130px, 1fr) minmax(130px, 1fr) minmax(60px, 80px) 0;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 60px;
    border-bottom: 0;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 13px 20px;
    grid-template-areas:
      'package package package'
      'site site dots'
      'data card .'
      'settings settings settings';
    overflow: hidden;
  }
  .tableMySite__settings--active {
    top: 7px;
    right: 0px;
  }
  .tableMySite__url--alerts {
    padding: 0;
  }
}

.tableClients {
  display: grid;
  grid-template-columns: 40px 4fr repeat(3, 1fr);
  // grid-template-columns: 40px 2fr repeat(3, 1fr) 0.5fr;
  font-size: 14px;
  color: #272d3b;
  font-weight: $weight__normal;

  &__wrapper {
    border-bottom: 2px solid rgba(39, 45, 59, 0.15);
    @media (max-width: 1024px) {
      border: none;
    }
  }

  &__user {
    padding-top: 18px;
    height: 55px;
    cursor: pointer;
    position: relative;
    @media (max-width: 1024px) {
      height: 53px;
    }
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 60px;
    border-bottom: 0;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 13px 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'name name name'
      'sites sites status'
      'data data provision';
    overflow: hidden;
    &__name {
      grid-area: name;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.58);
      font-weight: $weight__bold;
    }
    &__sites {
      grid-area: sites;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: $weight__bold;
      display: flex;
      p {
        display: block !important;
        margin-right: 5px;
      }
    }
    &__data {
      grid-area: data;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
    }
    &__status {
      grid-area: status;
    }
    &__provision {
      grid-area: provision;
      color: rgba(0, 0, 0, 0.8);
      font-size: 12px;
    }
  }
  &__status {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }
  .badge {
    padding: 0 12px;
  }
  &--head {
    display: none;
    @media (max-width: 1024px) {
      font-size: 18px;
      font-weight: $weight__bold;
      display: block;
      margin-bottom: 30px;
      margin-top: -20px;
    }
  }
  &__sites p {
    display: none;
  }
  &--borderNone div {
    border: 0 !important;
  }
  &--bold {
    font-weight: $weight__bold;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__user:hover {
    background-color: rgba(223, 223, 223, 0.55);
    @media (max-width: 1024px) {
      background-color: #fff;
    }
  }
  &__id {
    text-align: center;
    color: rgba(153, 153, 153, 1);
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.partnerRules {
  &__regulamin__paragraph {
    a {
      margin-left: 5px;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-wrap: wrap-reverse;
      .btn {
        border-radius: 4px;
        background-color: #000000;
        font-size: 14px;
      }
    }
  }

  &__head {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.69);
    font-weight: $weight__max;
    margin-bottom: 6px;
  }
  &__paragraph {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }
  &__content {
    padding: 50px 0 0 50px;
    max-width: 580px;
    @media (max-width: 1024px) {
      padding: 30px 10px;
    }
  }
  &__image {
    min-width: 300px;
    @media (max-width: 1024px) {
      width: 100%;
      max-width: 400px;
      min-width: 260px;
    }
  }
  &__point {
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
    &:before {
      content: '';
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../Image/Icon/icon_v.svg') !important;
      position: absolute;
      left: -15px;
    }
  }
  &__regulamin {
    display: flex;
    flex-direction: column;
    &__paragraph {
      font-size: 10px;
      color: rgba(36, 36, 36, 0.93);
      padding-left: 55px;
    }
    button {
      margin-top: 30px;
      margin-left: 28px;
      padding: 10px 50px;
    }
  }
  // &__image {
  // }
}

.checkboxNew {
  position: relative;
  display: flex;
  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: -1px;
    border: 1px solid #707070;
    border-radius: 6px;
    left: 26px;
    height: 20px;
    width: 20px;
  }

  .container:hover input ~ .checkmark {
    background-color: #eee;
  }

  .container input:checked ~ .checkmark {
    background-color: #352feee1;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 7px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.paymentWebsite {
  &__item {
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 13px 15px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
  }
  &__card {
    display: flex;
    font-size: 13px;
    font-weight: $weight__bold;
    svg {
      width: 30px;
      height: 20px;
      margin-right: 10px;
    }
  }
  &__item--active {
    background-color: #000000;
    color: rgba(255, 255, 255, 1);
    cursor: unset;
    .packagesItem__price--img {
      display: block;
      right: 30px;
      top: 15px;
    }
  }
}

.emptyView {
  display: flex;
  padding-left: 50px;
  margin-top: 70px;
  @media (max-width: 1024px) {
    background-color: #fff;
    width: 100vw;
    padding: 40px 0 40px 20px;
    flex-wrap: wrap-reverse;
    margin: 30px 0 0 -10px;
  }
  &__head {
    color: rgba(0, 0, 0, 0.79);
    font-size: 18px;
    max-width: 182px;
    font-weight: $weight__bold;
    margin-bottom: 32px;
  }
  &__paragraph {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.69);
    font-weight: $weight__normal;
    max-width: 500px;
    margin-bottom: 50px;
    max-width: 318px;
  }
  &__img {
    margin-left: auto;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__img--mobile {
    display: none;
    @media (max-width: 1024px) {
      margin-left: auto;
      margin-bottom: 40px;
      display: block;
      padding-right: 10px;
    }
    @media (max-width: 620px) {
      margin-left: 0;
    }
  }
  .btn {
    @media (max-width: 1024px) {
      border-radius: 5px;
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 0.08);
      color: rgba(0, 0, 0, 1);
      box-shadow: none;
      font-size: 12px;
    }
  }
}

.back {
  display: block;
  margin-bottom: 30px;
  margin-top: -20px;
  // position: absolute;
  // padding: 10px 0;
  // left: 218px;
  &--desktop {
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &--mobile {
    @media (min-width: 1024px) {
      display: none;
    }
    margin-top: 20px;
    margin-left: 20px;
  }
}

.tableWebsites {
  display: grid;
  grid-template-columns: 0.25fr 1fr 2.5fr minmax(190px, 1.5fr) minmax(100px, 1.5fr) 2fr 2.5fr 0.25fr;
  column-gap: 10px;
  padding: 15px;
  align-items: center;
  &--allWebsite {
    grid-template-columns: 0.25fr 1fr 2.5fr 1.5fr minmax(175px, 1.5fr) minmax(100px, 2fr) 2fr 2.5fr 1.5fr 0.5fr;
  }

  &__wrapper {
    @media (max-width: 1024px) {
      padding-inline: 20px;
    }
  }

  &__clientWebsites {
    grid-template-columns: 0.25fr 1fr 2.5fr minmax(175px, 1.5fr) minmax(100px, 1.5fr) 1.5fr 2fr 1fr 0.25fr;
    &--heading {
      grid-template-columns: 0.25fr 1fr 2.5fr minmax(175px, 1.5fr) minmax(100px, 1.5fr) 1.5fr 2fr 1fr 0.25fr;
    }
  }

  &__comission {
    color: var(--czarny-500, #111);
    font-size: 12px;
    @media (max-width: 1024px) {
      display: none;
    }
    &--zero {
      @media (max-width: 1024px) {
        display: none;
      }
      color: var(--czarny-500, #111);
      font-size: 12px;
      padding-left: 20px;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: 10px 1fr 1fr 1fr;
    row-gap: 25px;
    grid-template-areas:
      'status url url payment'
      'package package package dots'
      'data  data websitesStatus websitesStatus';
  }
  &__wrapper {
    position: relative;
    &--allWebsite {
      cursor: pointer;
    }
    @media (max-width: 1024px) {
      &--websitesPartners {
        margin-left: 0 !important;
      }
    }
  }

  &--inactive-row {
    filter: grayscale(1) opacity(0.4);
  }

  &__owner {
    font-size: 14px;
  }
  &--click {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    // cursor: pointer;
    height: 100%;
    z-index: 9;
  }
  &--center {
    text-align: left;
  }
  &--heading {
    font-size: 12px;
    color: rgba(26, 26, 26, 1);
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__last:nth-child(n + 3) {
    display: none;
  }
  &__last {
    font-size: 12px;
    text-align: center;
    margin-top: 30px;
  }
  &__item {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    margin: 4px 0;
    &__wrapper {
      position: relative;
      @media (max-width: 1024px) {
        background-color: #fff;
      }
    }
    @media (max-width: 1024px) {
      border-radius: 0;
    }
  }
  &__id {
    font-size: 14px;
    font-weight: $weight__bold;
    color: rgba(0, 0, 0, 0.82);
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__status {
    font-size: 12px;
    display: flex;
    justify-items: center;
    align-items: center;
    @media (max-width: 1024px) {
      grid-area: status;
      position: relative;
      display: none;
    }
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      min-width: 6px;
      min-height: 6px;
      border-radius: 10px;
      display: block;
      margin-right: 6px;
      @media (max-width: 1024px) {
        position: absolute;
        left: 30%;
        top: 30%;
      }
    }
    &--green {
      color: rgba(56, 213, 139, 0.82);
      @media (max-width: 1024px) {
        color: transparent;
      }
      &:before {
        width: 6px;
        min-width: 6px;
        min-height: 6px;
        height: 6px;
        background-color: rgba(56, 213, 139, 0.82);
      }
    }
    &--gray {
      color: var(--szary-400, #a6a6a6);
      font-weight: 500;
      @media (max-width: 1024px) {
        color: transparent;
      }
      &:before {
        width: 6px;
        height: 6px;
        background-color: var(--szary-400, #a6a6a6);
      }
    }
    &--red {
      color: rgba(244, 63, 63, 0.82);
      @media (max-width: 1024px) {
        color: transparent;
      }
      &:before {
        width: 6px;
        height: 6px;
        background-color: rgba(244, 63, 63, 0.82);
      }
    }
    &--yellow {
      color: #f29225;
      @media (max-width: 1024px) {
        color: transparent;
      }
      &:before {
        width: 6px;
        height: 6px;
        background-color: #f29225;
      }
    }

    &--text {
      &:before {
        content: none;
      }
    }
  }
  &__url {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.82);
    font-weight: $weight__bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: 1024px) {
      grid-area: url;
    }
  }
  &__package {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.82);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
      grid-area: package;
      justify-content: flex-start;
    }
    &__price {
      margin-right: 20px;
      margin-left: 10px;
    }
    &__name {
      display: flex;
      justify-items: center;
      align-items: center;
      text-transform: capitalize;
      &:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 15px;
        display: block;
        margin-right: 6px;
      }
      &--premium:before {
        border: 3px solid rgba(53, 47, 238, 1);
      }
      &--basic:before {
        border: 3px solid rgba(241, 241, 241, 1);
      }
      &--pro:before {
        border: 3px solid rgba(0, 0, 0, 1);
      }
    }
  }
  &__owner {
    @media (max-width: 1024px) {
      grid-area: owner;
    }
  }

  &__activation {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.82);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    @media (max-width: 1024px) {
      grid-area: data;
      text-align: right;
      font-size: 12px;
    }
  }
  &__payment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 1024px) {
      grid-area: payment;
      justify-content: flex-end;
    }

    &--pointer {
      cursor: pointer;
    }
  }
  &__diagnostics {
    @media (max-width: 1024px) {
      grid-area: diagnostics;
    }
  }
  &__websitesStatus {
    display: flex;
    flex-wrap: wrap;
    &--dots {
      width: 31.5px;
      margin-right: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 7px;
        height: 7px;
        border-radius: 10px;
        display: block;
        margin-right: 6px;
        background-color: #d4d4d4;
      }
    }
    .badge {
      margin-left: -15px;
    }

    // .websitesStatus__wrapper:first-child {
    //   margin-left: 0;
    // }

    @media (max-width: 1024px) {
      grid-area: websitesStatus;
      justify-content: flex-start;
    }
    &--ssl {
      @media (max-width: 1024px) {
        grid-area: websitesStatus1;
      }
    }
  }

  &__diagnosticsFailed {
    color: #f29325;
    font-weight: 700;
  }

  &__diagnosticsPassed {
    color: #38d58b;
    font-weight: 700;
  }

  &__diagnosticsPending {
    position: relative;
    right: 30px;
  }

  &__diagnoticsWrapper {
    cursor: pointer;
    @media (max-width: 1024px) {
      grid-area: diagnostics;
      float: right;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__dots {
    color: rgba(153, 153, 153, 1);
    font-size: 34px;
    line-height: 10px;
    margin-top: -15px;
    cursor: pointer;
    position: relative;
    padding-right: 5px;
    &--active {
      color: transparent;
    }
    @media (max-width: 1024px) {
      grid-area: dots;
      display: flex;
      justify-content: flex-end;
      line-height: 30px;
    }

    &--not-allowed {
      cursor: not-allowed;
    }
  }

  &__settings {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 40px #66666629;
    position: absolute;
    padding: 16px 20px;
    right: 0;
    top: 0;
    width: 270px;
    z-index: 9;
    height: 0;
    display: none;
    .dots {
      color: #999999;
      font-size: 34px;
      padding: 0;
      cursor: pointer;
      z-index: 8;
      position: absolute;
      right: 12px;
      top: -8px;
      user-select: none;
    }
    &--active {
      transition-duration: 0.3s;
      height: auto;
      visibility: visible;
      display: block;
      top: 4px;
      right: 6px;
      @media (max-width: 1024px) {
        right: unset;
      }
    }
    @media (max-width: 1024px) {
      &--wrap {
        grid-area: settings;
        height: auto;
      }
      position: relative;
      width: 100%;
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      height: 0;
      overflow: hidden;
      border-top: 0;
      display: block;
      padding-left: 20px;
      p {
        padding: 15px 0 !important;
      }
      &--active {
        transition-duration: 0.3s;
        border-top: 1px solid rgba(128, 128, 128, 0.09);
        height: 100%;
        visibility: visible;
        padding-bottom: 20px;
      }
    }
    p {
      padding: 10px 20px;
      margin-right: 30px;
      cursor: pointer;
      font-size: 14px;
    }
    &--dots {
      position: absolute;
      right: 20px;
      top: -16px;
      font-size: 44px;
      color: #999999;
      cursor: pointer;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    &--sub {
      color: #f32727;
    }
    &--disabled {
      opacity: 0.3 !important;
      pointer-events: none !important;
      cursor: not-allowed !important;
    }
    &--allowed {
      opacity: 0.3 !important;
      cursor: not-allowed !important;
      span {
        pointer-events: none !important;
      }
    }
  }
}
