.choicer {
  &__header {
    font-size: 13px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }
  &__toolTip {
    cursor: help;
  }
  &__items {
    display: flex;
    margin-top: 4px;
  }
  &__item {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 9px;
    padding: 10px 11px;
    min-width: 82px;
    font-weight: 700;
    svg {
      width: 18px;
      height: 18px;
      margin-left: 20px;
    }
    &:first-child {
      margin-right: 12px;
    }
    &--active {
      background-color: #000000;
      color: #ffffff;
      transition: 0.2s;
    }
    &--disable {
      color: rgba(0, 0, 0, 0.13);
      cursor: help;
    }
  }
  &__myData {
    margin: 24px 0 0;
  }
}
