.remindPassword {
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  &__text {
    font-weight: 700;
    color: #000;
    max-width: 440px;
    text-align: center;
    padding-bottom: 20px;
  }
  &__mail {
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: 40px 0;
    button {
      height: 57px;
      padding-top: 16px;
      max-width: 245px;
      width: 100%;
      display: flex;
      justify-content: center;
      @media (min-width: 700px) {
        position: absolute;
        top: -57px;
        right: -3px;
      }
    }
    .btn--wrapper {
      @media (max-width: 700px) {
        margin-top: 60px;
        justify-content: center;
        display: flex;
      }
    }
  }
  &--alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
  .form__field {
    input {
      height: 57px;
      border-radius: 25px;
      //   background-color: #f1f1f1;
      border: 0px;
      padding-left: 22px;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
    }
    &--label-error span {
      position: absolute;
      font-size: 12px;
    }
  }
}
