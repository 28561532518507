.page-corgi {
  padding-top: 0px;
  margin-top: -80px;
  background-color: #2d2d2d;
  display: flex;
  justify-content: center;
  .container {
    width: 100%;
    margin: 125px 125px 50px 125px;
    max-width: 1600px;
    @media (max-width: 1300px) {
      margin: 125px 90px 125px 90px;
    }
    @media (max-width: 1000px) {
      margin-top: 150px;
    }
    @media (max-width: 800px) {
      margin-left: 0;
      margin-right: 0;
      padding: 0 20px;
      margin: 125px 90px 20px 90px;
    }
    h1 {
      font-size: 154px;
      font-weight: $weight__max;
      color: #ffffff;
      opacity: 0.14;
      @media (max-width: 1600px) {
        font-size: 140px;
      }
      @media (max-width: 1500px) {
        font-size: 120px;
      }
      @media (max-width: 1300px) {
        font-size: 100px;
      }
      @media (max-width: 1100px) {
        font-size: 90px;
      }
      @media (max-width: 1000px) {
        font-size: 80px;
      }
      @media (max-width: 900px) {
        font-size: 70px;
      }
      @media (max-width: 800px) {
        font-size: 60px;
        margin-top: 30px;
      }
    }
  }
  .proBonoWrapper{
    @media (max-width: 800px) {
      margin-left: 0;
      margin-right: 0;
      padding: 0 20px;
      margin: 125px 0 20px 0;

      h1{
        line-height: 63px;
      }
    }
  }
}
.corgi_advance {
  display: flex;
  max-width: 1300px;
  justify-content: center;
  margin-top: 100px;
  @media (max-width: 1300px) {
    margin-top: 70px;
  }
  @media (max-width: 1200px) {
    margin-top: 60px;
  }
  @media (max-width: 900px) {
    flex-wrap: wrap-reverse;
    img {
      width: 200px;
      margin-bottom: 30px;
    }
  }
  p {
    color: #ffffff;
    opacity: 0.93;
    font-size: 35px;
    font-weight: $weight__bold;
    line-height: 49px;
    max-width: 757px;
    @media (max-width: 1000px) {
      font-size: 30px;
    }
    @media (max-width: 600px) {
      font-size: 25px;
    }
  }
  span {
    margin-top: 40px;
    color: #f1f1f1;
    opacity: 0.6;
    max-width: 823px;
    display: block;
    @media (max-width: 600px) {
      font-size: 17px;
    }
  }
}
.corgi_complex {
  margin-left: 75px;
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    margin-left: 20px;
  }
  @media (max-width: 600px) {
    margin-left: 0;
  }
  h2 {
    color: #ffffff;
    opacity: 0.69;
    font-size: 21px;
    font-weight: $weight__bold;
    width: 413px;
    margin-bottom: 50px;
    @media (max-width: 600px) {
      font-size: 17px;
    }
  }
  &-list {
    width: 100%;
    display: flex;
    @media (max-width: 1100px) {
      flex-wrap: wrap;
    }
    @media (max-width: 800px) {
      justify-content: left;
      flex-direction: column;
    }
    ul {
      margin: 25px;
      @media (max-width: 800px) {
        margin: 0 25px;
      }
      &:first-child {
        margin-left: 0;
        @media (max-width: 1100px) {
          margin-left: 25px;
        }
      }
    }
    li {
      font-size: 14px;
      max-width: 252px;
      background-image: url('../../Image/Icon/icon_v.svg');
      background-repeat: no-repeat;
      background-position-x: 3px;
      padding-left: 28px;
      color: #ffffff;
      margin: 6px 0;
    }
  }
}
.corgi_hacker {
  display: flex;
  margin-top: 150px;
  margin-bottom: 60px;
  @media (max-width: 1300px) {
    margin-top: 130px;
  }
  @media (max-width: 1100px) {
    margin-top: 100px;
  }
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 800px) {
    margin-top: 0;
  }
  svg {
    max-width: 500px;
    height: 350px;
    width: 100%;
    margin-right: 100px;
    @media (max-width: 1500px) {
      max-width: 400px;
    }
    @media (max-width: 1300px) {
      max-width: 360px;
      margin-right: 60px;
    }
    @media (max-width: 1000px) {
      margin-right: 0;
    }
  }
  p {
    color: #ffffff;
    opacity: 0.93;
    max-width: 823px;
    font-size: 35px;
    font-weight: $weight__max;
    line-height: 40px;
    margin-bottom: 30px;
    @media (max-width: 600px) {
      font-size: 25px;
    }
  }
  span {
    color: #f1f1f1;
    opacity: 0.6;
    max-width: 823px;
    display: block;
    font-size: 17px;
    margin: 20px 0;
  }
}
