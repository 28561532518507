.updatesTable {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  font-size: 12px;
  padding: 18px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'icon icon url url '
      'updates updates version version '
      'status status status status '
      'data data data data ';
    row-gap: 20px;
    &__item {
      &--data {
        grid-area: data;
      }
      &--icon {
        grid-area: icon;
      }
      &--version {
        grid-area: version;
      }
      &--status {
        grid-area: status;
      }
      &--updates {
        grid-area: updates;
      }
      &--data {
        grid-area: data;
        color: rgba(0, 0, 0, 0.24);
      }
    }
  }
  .badge {
    width: auto;
    display: flex;
    align-items: center;
  }
  &__item {
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px 0;
    position: relative;
    @media (max-width: 1024px) {
      border-radius: 0;
    }
    &--status {
      display: flex;
      align-items: center;
    }
    &--url {
      font-size: 14px;
      font-weight: 700;
    }
    &--icon {
      display: flex;
      svg {
        margin-right: 10px;
        margin-top: 5px;
      }
    }
    &--settings {
      color: #9999999a;
      font-size: 34px;
      line-height: 20px;
      padding: 0;
      cursor: pointer;
      user-select: none;
      margin-top: -5px;
      // line-height: 30px;
      line-height: 9px;
      cursor: pointer;
      position: relative;
    }
  }
  &--firstLine {
    text-transform: lowercase;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__suspended {
    padding: 4px 18px;
    color: var(--Szary-700, #404040);
    margin-bottom: 16px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 1000px) {
      display: block;
      svg {
        margin-right: 10px;
        margin-bottom: -6px;
      }
    }
    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      path {
        stroke: #f29225;
      }
    }
  }
}

.backup__restore {
  display: flex;
}
